// @flow
import * as Immutable from 'immutable'
import {
  FIELD_DISPLAY_MODE_DIRTY,
  FIELD_DISPLAY_MODE_CORRECTED,
  FIELD_DISPLAY_MODE_REJECTED,
  FIELD_RENDER_TYPE_CORRECT,
  FIELD_RENDER_TYPE_CORRECT_FIX,
  FIELD_RENDER_TYPE_EDIT,
  FIELD_RENDER_TYPE_FIX,
  FIELD_RENDER_TYPE_INSPECT,
  FIELD_RENDER_TYPE_INSPECT_FIX,
  FIELD_RENDER_TYPE_INSPECT_REJECT,
  FIELD_RENDER_TYPE_NEW,
  FIELD_RENDER_TYPE_READ,
  FORM_VIEW_TYPE_CORRECT,
  FORM_VIEW_TYPE_EDIT,
  FORM_VIEW_TYPE_INSPECT,
  FORM_VIEW_TYPE_NEW,
  FORM_VIEW_TYPE_READ,
  FIELD_TYPE_COMPLEX_FIELD,
  FIELD_TYPE_FIELD,
} from './constants'
import {
  getIn,
  storePath,
  storeFlatMapRemove,
} from './utils'
import {
  hasImmutableValueChangedWithCoercedNils,
  fieldPathToImmutablePath,
  parseBoolean
} from '../utils'
import memoizee from 'memoizee'

import type {
  FieldDependencyGraph,
  FormState,
  FieldRenderType,
  FieldDisplayMode,
  FormViewType
} from './types'


type StateFieldResolverParams = [FormState, string, string]

const memoizeeConfig = {
   // max 1000 item to cache per function
  max: process.env.REACT_APP_ECR_FORM_MEMOIZE_CACHE_MAX_ITEMS || 1000,
  // force dynamic length behavior (take into account all arguments)
  length: false
}

/**
 * Memoize wrapper function to allow memoization to be turned on/off
 * through build environment configuration
 * 
 * @param {*} Function to possibly memoize
 * @param {*} Memoizee config
 * @returns The function or it's memoized version depending on env variable
 */
function memoize(fn, config) {
  if (parseBoolean(process.env.REACT_APP_ECR_FORM_MEMOIZE_ENABLED)) {
    return memoizee(fn, config)
  }
  return fn
}

/******************************************************************************
* FOUNDATIONAL HELPER METHODS
* *****************************************************************************/

export function getEffectiveFieldId(fieldPath: string): string {
  if (typeof fieldPath !== 'string' || fieldPath.length === 0) {
    throw Error(`fieldPath must be a non-empty string built from field ids separated by dots. Got fieldPath: ${fieldPath}`)
  }
  const splitted = fieldPath.split('.')
  return splitted[splitted.length - 1]
}

/**
 * Return dynamically calculated `fieldDependencyGraph` from form state.
 * @param  {FormState}            state Redux form state
 * @param  {string}               form  Form name
 * @return {FieldDependencyGraph}       Field dependency graph
 */
const getFieldDependencyGraph = (
  state: FormState,
  form: string
): FieldDependencyGraph =>
  state.getIn(
    storePath(['fieldDependencyGraph'], form), Immutable.Map()
  )


/**
 * Return parents of a field (either through dependent or complex fields)
 * @param  {FormState}              FormState  state Redux form state
 * @param  {string}                 field      Name of the field
 * @param  {string}                 form       Form name
 * @return {Immutable.List<string>}            List of parent field names
 */
export const getParents = memoize<
  StateFieldResolverParams,
  Immutable.List<string>
>(
  (
    state: FormState,
    field: string,
    form: string
  ): Immutable.List<string> =>
    getFieldDependencyGraph(state, form)
      .filter(
        (v, k) => v.includes(field)
      )
      .keySeq()
      .toList(),
  memoizeeConfig
)

/**
 * Return children of a field (either through dependent or complex fields)
 * @param  {FormState}              state Redux form state
 * @param  {string}                 field Name of the field
 * @param  {string}                 form  Form name
 * @return {Immutable.List<string>}       List of children field names
 */
export const getChildren = (
  state: FormState,
  field: string,
  form: string
): Immutable.List<string> => 
  getFieldDependencyGraph(state, form).get(field, Immutable.List())


const getAncestorsHelper = 
  (
    fieldDependencyGraph: FieldDependencyGraph,
    field: string,
    form: string
  ): Immutable.List<string> => {
    const parents = fieldDependencyGraph.filter(
      (v, k) => v.includes(field)
    ).keySeq().toList()
    return parents.concat(
      parents.map(
        p => getAncestorsHelper(fieldDependencyGraph, p, form)
      ).flatten(false)
    )
  }

/**
 * Return ancestors of a field (either through dependent or complex fields)
 * @param  {FormState}              state Redux form state
 * @param  {string}                 field Name of the field
 * @param  {string}                 form  Form name
 * @return {Immutable.List<string>}       List of ancestor field names
 */
export const getAncestors = memoize<StateFieldResolverParams, Immutable.List<string>>(
  (
    state: FormState,
    field: string,
    form: string
  ): Immutable.List<string> => 
    getAncestorsHelper(getFieldDependencyGraph(state, form), field, form),
  memoizeeConfig
)


function getDescendantsHelper(fieldDependencyGraph: FieldDependencyGraph, field: string, form: string): Immutable.List<string> {
  const children = fieldDependencyGraph.get(field, Immutable.List())
  return children.concat(
    children
      .map(c => getDescendantsHelper(fieldDependencyGraph, c, form))
      .flatten(false)
  )
}

/**
 * Return descendants of a field (either through dependent or complex fields)
 * @param  {FormState}  state Redux form state
 * @param  {string}         field Name of the field
 * @param  {string}         form  Form name
 * @return {Immutable.List<string>}       List of descendant field names
 */
export const getDescendants = memoize<StateFieldResolverParams, Immutable.List<string>>(
  (
    state: FormState,
    field: string,
    form: string
  ): Immutable.List<string> =>
    getDescendantsHelper(getFieldDependencyGraph(state, form), field, form),
  memoizeeConfig
)
  

function getDependencyTreeRootsHelper(fieldDependencyGraph: FieldDependencyGraph, field: string, form: string): Immutable.List<string> {
  const parents = fieldDependencyGraph.filter((v, k) => v.includes(field)).keySeq().toList()
  return (
    parents.size === 0
      ? Immutable.List([field])
      : Immutable.List()
  ).concat(
    parents.map(
      p => getDependencyTreeRootsHelper(fieldDependencyGraph, p, form)
    )
  )
  .flatten(false)
}

/**
 * Return root fields of a dependency tree the field is part of
 * (either through dependent or complex fields)
 * @param  {FormState}  state Redux form state
 * @param  {string}         field Name of the field
 * @param  {string}         form  Form name
 * @return {Immutable.List}       List of root field names
 */
export const getDependencyTreeRoots = memoize<StateFieldResolverParams, Immutable.List<string>>(
  (
    state: FormState,
    field: string,
    form: string
  ): Immutable.List<string> =>
    getDependencyTreeRootsHelper(getFieldDependencyGraph(state, form), field, form),
  memoizeeConfig
)
  

/**
 * Expand a field path based on complex value size.
 * E.g. when input field path is `c1.f1` and the size of `c1` is 2 then
 * `c1.f1` is expanded to [`c1[0].f1`, `c1[1].f1`].
 * If a field is not under a complex field then it is not expanded.
 * E.g. `f2` becomes `f2`
 * sizeGetter must be a function which returns the size of a complex field
 * from `values` when given a complex field name as argument. It must return 0
 * if its argument is not a complex field.
 * @param  {string}           fieldPath  Field path to expand
 * @param  {string => number} sizeGetter Function to return complex field sizes
 * @return {string[]}                    List of expanded field names
 */
export function expandFieldPath(fieldPath: string, sizeGetter: string => number = () => 0): string[] {
  const re = /^([^\[\]\.]+\[\d+\]\.)*[^\[\]\.]+(?=\.)/ // eslint-disable-line no-useless-escape
  const m = fieldPath.match(re)
  if (!m) {
    // if no match then we cannot expand any further
    return [fieldPath]
  }
  // m[0] is the full match of regex:
  // it's either `c1` from `c1.f3`
  // or `c1[1].c2` from `c1[1].c2.f3`
  const size = sizeGetter(m[0])
  // [...Array(size).keys()] generates a sequence from 0 to size-1
  const expanded = [...Array(size).keys()].map(idx => fieldPath.replace(m[0], `${m[0]}[${idx}]`))
  return expanded.reduce((acc, v) => [...acc, ...expandFieldPath(v, sizeGetter)], [])
}

/**
 * Updates `fieldDependencyGraph` from `values` and `staticFieldDependencyGraph`.
 * `staticFieldDependencyGraph` is the static version of field dependency graph
 * which is calculated from form descriptor. This function expand static field
 * dependency graph by replacing complex field related template paths
 * (e.g. `c1.c2.f1`) to actual field paths (`c1[0].c2[1].f1`).
 * @param  {FormState}  state Redux form state
 * @param  {string}     form  Form name
 * @return {FormState}  New redux form state
 */
export function updateFieldDependencyGraph(state: FormState, form: string): FormState {
  const sizeGetter = complexFieldId => getComplexFieldSize(state, complexFieldId, form)
  const staticFdg = state.getIn(storePath(['staticFieldDependencyGraph'], form), Immutable.Map())
  // first we need to expand keys, values belongig to keys are simply copied over
  // so {
  //   ...
  //   "c1.f2": [ ... ]
  //   ...
  // }
  // becomes {
  //   ...
  //   "c1[0].f2": [ ... ],
  //   "c1[1].f2": [ ... ],
  // }
  //
  const newFdg = staticFdg.keySeq().reduce((acc, oldKey) => {
    const newKeys = expandFieldPath(oldKey, sizeGetter)
    return acc.merge(
      newKeys.reduce(
        (m, newKey) => m.merge(Immutable.Map({ [newKey]: staticFdg.get(oldKey) })), Immutable.Map()
      )
    )
  }, Immutable.Map())
  // next we need to expand edge lists (values) so
  // {
  //   "c1": [ "c1.f2" ]
  // }
  // becomes
  // {
  //   "c1": [ "c1[0].f2", "c1[1].f2" ]
  // }
  // we also neeed to filter out nodes under a complex field which doesn't belong to
  // that particular instance
  .map((edgeList, key) => {
    return edgeList.flatMap(fieldPath =>
      Immutable.List(expandFieldPath(fieldPath, sizeGetter).filter(x => {
        const complexKey = /^[^\[\]\.]+\[\d+\]\./.test(key) // eslint-disable-line no-useless-escape
        return !complexKey || (complexKey && x.startsWith(key))
      }))
    )
  })
  return state.setIn(storePath(['fieldDependencyGraph'], form), newFdg)
}

/**
 * Return size of a complex field (number of items). Returns 0 if the given
 * field id is not a complex field.
 * @param  {FormState}     state          Redux form state
 * @param  {string}        complexFieldId Complex field path
 * @param  {string}        form           Form name
 * @return {number}                       Size of the complex field or 0 if not complex
 */
export function getComplexFieldSize(state: FormState, complexFieldId: string, form: string): number {
  const value = state.getIn([form, 'values', ...fieldPathToImmutablePath(complexFieldId)])
  return value && Immutable.List.isList(value) ? value.size : 0
}

/******************************************************************************
* HELPER METHODS
* *****************************************************************************/



/**
 * Returns true when the given field has a rejected ancestor.
 * BOTH DEPENDENT FIELDS AND COMPLEX FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state
 * @param  {string} field Name of the field
 * @param  {string} form  Name of the form
 * @return {boolean}      True if field has rejected ancestor, false otherwise
 */
export const fieldHasRejectedAncestor = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean => 
  getAncestors(state, field, form)
    .some(
      fieldName => state.getIn(
        storePath(['rejectedFields', fieldName, 'rejectReason'], form)
      )
  ),
  memoizeeConfig
)


/**
 * Returns true when the given field has a rejected descendant.
 * BOTH DEPENDENT FIELDS AND COMPLEX FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {boolean}         True if field has rejected descendant, false otherwise
 */
export const fieldHasRejectedDescendant = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean => 
  getDescendants(state, field, form)
    .some(
      fieldName => state.getIn(
        storePath(['rejectedFields', fieldName, 'rejectReason'], form)
      )
    ),
  memoizeeConfig
)


/**
 * Returns true when the given field has a dirty dependee ancestor.
 * ONLY DEPENDENT FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {boolean}         True if field has dirty dependee ancestor, false otherwise
 */
export const fieldHasDirtyDependeeAncestor = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean => 
  getAncestors(state, field, form)
    .filter(f => getFieldType(state, f, form) !== FIELD_TYPE_COMPLEX_FIELD)
    .some(
      fieldName => state.getIn(storePath(['fieldStates', fieldName, 'dirty'], form))
    ),
  memoizeeConfig
)


/**
 * Returns true when the given field has a dirty descendant.
 * BOTH DEPENDENT FIELDS AND COMPLEX FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {boolean}         True if field has dirty descendant, false otherwise
 */
export const fieldHasDirtyDescendant = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean =>
  getDescendants(state, field, form)
    .some(
      fieldName => state.getIn(
        storePath(['fieldStates', fieldName, 'dirty'], form)
      )
    ),
  memoizeeConfig
)

/**
 * Examines whether the given field has a rejected complex field ancestor.
 * ONLY COMPLEX FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {boolean}         True if field has rejected complex field ancestor, false otherwise
 */
export const fieldHasRejectedComplexFieldAncestor = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean => 
  getAncestors(state, field, form)
    .filter(f => getFieldType(state, f, form) === FIELD_TYPE_COMPLEX_FIELD)
    .some(
      fieldName => state.getIn(
        storePath(['rejectedFields', fieldName, 'rejectReason'], form)
      )
    ),
  memoizeeConfig
)

/**
 * Returns true when the field is rejected
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {Boolean}         true if field is rejected
 */
export const isFieldRejected = memoize<StateFieldResolverParams, boolean>((
  state: FormState,
  field: string,
  form: string
): boolean => 
  Boolean(state.getIn(storePath(['rejectedFields', field], form))),
  memoizeeConfig
)

/**
 * Returns field type of `field`. Field type could be
 * FIELD_TYPE_FIELD or FIELD_TYPE_COMPLEX_FIELD (constants).
 * @param  {FormState} state Redux state
 * @param  {string}    field Name of the field
 * @param  {string}    form  Name of the form
 * @return {string}          Field type of field
 */
export const getFieldType = memoize<StateFieldResolverParams, FormState>(
  (
    state: FormState,
    field: string,
    form: string
  ): string => {
    if (/_header/.test(field)) {
      return FIELD_TYPE_FIELD
    }
    const fieldType = state.getIn(
      storePath(['fieldTypes', getEffectiveFieldId(field)], form)
    )
    if (!fieldType) {
      throw Error(`getFieldType: Cannot determine fieldType of field ${field}`)
    }
    return fieldType
  },
  memoizeeConfig
)

/**
 * Returns view type of the form
 * @param  {FormState}           state Redux state
 * @param  {string}              form  Name of the form
 * @return {FormViewType | void}       View type of the form
 */
export function getViewType(state: FormState, form: string): FormViewType | void {
  return state.getIn(storePath(['viewType'], form))
}

/**
 * Returns field name under fixing
 * @param  {FormState} state Redux state
 * @param  {string}    form  Name of the form
 * @return {string | void}   Field name that is under fix or undefined
 */
export function getFieldUnderFix(state: FormState, form: string): string | void {
  return state.getIn(storePath(['fieldUnderFix'], form))
}

/**
 * Determines whether the provided field has any rejected
 * dependee (DependentFields) ancestor field
 * whose value has changed from rejectedValue.
 * ONLY DEPENDENT FIELDS ARE TAKEN INTO ACCOUNT!
 * @param  {FormState} state Redux state slice
 * @param  {String}    field Name of the field
 * @param  {String}    form  Name of the form
 * @return {Boolean}         True if a value changed and rejected dependee ancestor exists
 */
export const fieldHasRejectedAndValueChangedDependeeAncestor = memoize<StateFieldResolverParams, boolean>(
  (
    state: FormState,
    field: string,
    form: string
  ): boolean =>
    getAncestors(state, field, form)
      .filter(f => getFieldType(state, f, form) !== FIELD_TYPE_COMPLEX_FIELD)
      .some(
        fieldName => {
          const value = getIn(state, `values.${fieldName}`, form)
          const rejected = state.getIn(storePath(['rejectedFields', fieldName], form))
          const draftCorrected = hasImmutableValueChangedWithCoercedNils(rejected && rejected.get('rejectedValue'), value)
          return rejected && draftCorrected
        }
      ),
  memoizeeConfig
)

/**
 * Compute initial renderType for a field.
 * @param  {FormState} state Redux state slice
 * @param  {String}    field Name of the field
 * @param  {String}    form  Name of the form
 * @return {String}          field's renderType
 */
export const calcFieldInitialRenderType = memoize<StateFieldResolverParams, FieldRenderType>(
  (
    state: FormState,
    field: string,
    form: string
  ): FieldRenderType => {
    const viewType = state.getIn(storePath(['viewType'], form))
    const rejected = state.getIn(storePath(['rejectedFields', field], form))
    const dirty = state.getIn(storePath(['fieldStates', field, 'dirty'], form))
    const fieldUnderFix = state.getIn(storePath(['fieldUnderFix'], form))
    const fieldType = getFieldType(state, field, form) // state.getIn(storePath(['registeredFields', field, 'fieldType'], form))
    switch(viewType) {
      case FORM_VIEW_TYPE_READ:
        return FIELD_RENDER_TYPE_READ
      case FORM_VIEW_TYPE_NEW:
        return FIELD_RENDER_TYPE_NEW
      case FORM_VIEW_TYPE_EDIT:
        return FIELD_RENDER_TYPE_EDIT
      case FORM_VIEW_TYPE_INSPECT:
        if (field === fieldUnderFix) {
          return FIELD_RENDER_TYPE_FIX
        }
        if (rejected) {
          return FIELD_RENDER_TYPE_INSPECT_REJECT
        }
        if (dirty) {
          return FIELD_RENDER_TYPE_INSPECT_FIX
        }
        if (fieldType === FIELD_TYPE_COMPLEX_FIELD) {
          return FIELD_RENDER_TYPE_INSPECT_REJECT
        }
        return FIELD_RENDER_TYPE_INSPECT
      case FORM_VIEW_TYPE_CORRECT:
        if (field === fieldUnderFix) {
          return FIELD_RENDER_TYPE_FIX
        }
        if (rejected) {
          return FIELD_RENDER_TYPE_CORRECT
        }
        return FIELD_RENDER_TYPE_READ
      default:
        return FIELD_RENDER_TYPE_READ
    }
  },
  memoizeeConfig
)

// TODO: unit test!
export const calcDependencyFieldRenderType = memoize<StateFieldResolverParams, FieldRenderType>(
  (state: FormState, field: string, form: string): FieldRenderType => {
    const pushed = state.getIn(storePath(['fieldStates', field, 'pushed'], form), false)
    const fieldType = getFieldType(state, field, form) // state.getIn(storePath(['registeredFields', field, 'fieldType'], form), 'field')
    const viewType = state.getIn(storePath(['viewType'], form))
    const fieldUnderFix = state.getIn(storePath(['fieldUnderFix'], form))
    if (viewType === FORM_VIEW_TYPE_INSPECT) {
      if (field === fieldUnderFix) {
        return FIELD_RENDER_TYPE_FIX
      }
      if (pushed) {
        return FIELD_RENDER_TYPE_EDIT
      }
      if (fieldHasRejectedAncestor(state, field, form)) {
        return FIELD_RENDER_TYPE_READ
      }
      if (fieldHasRejectedDescendant(state, field, form)) {
        if (fieldType === FIELD_TYPE_COMPLEX_FIELD) {
          return FIELD_RENDER_TYPE_INSPECT_FIX
        }
        return FIELD_RENDER_TYPE_READ
      }
      if (fieldHasDirtyDependeeAncestor(state, field, form)) {
        return FIELD_RENDER_TYPE_EDIT
      }
      if (fieldHasDirtyDescendant(state, field, form)) {
        return FIELD_RENDER_TYPE_INSPECT_FIX
      }
    }
    if (viewType === FORM_VIEW_TYPE_CORRECT) {
      if (field === fieldUnderFix) {
        return FIELD_RENDER_TYPE_FIX
      }
      if (pushed) {
        return FIELD_RENDER_TYPE_EDIT
      }
      if (fieldHasRejectedAndValueChangedDependeeAncestor(state, field, form)) {
        return FIELD_RENDER_TYPE_EDIT
      }
      if (fieldHasRejectedComplexFieldAncestor(state, field, form)) {
        return FIELD_RENDER_TYPE_CORRECT_FIX
      }
    }
    return calcFieldInitialRenderType(state, field, form)
  },
  memoizeeConfig
)

function calcDependencyTreeRenderTypeHelper(
  state: FormState,
  field: string,
  form: string,
  renderTypes: Immutable.Map<string, FieldRenderType>
): Immutable.Map<string, FieldRenderType> {
  const childRenderTypes = getChildren(state, field, form)
        .reduce(
          (acc, fieldName) => calcDependencyTreeRenderTypeHelper(state, fieldName, form, acc),
          Immutable.Map()
        )
  return Immutable.Map()
    .set(field, calcDependencyFieldRenderType(state, field, form))
    .merge(childRenderTypes)
    .merge(renderTypes)
}

// TODO: unit test!
export const calcDependencyTreeRenderType = memoize<StateFieldResolverParams, Immutable.Map<string, FieldRenderType>>(
  (
    state: FormState,
    field: string,
    form: string
  ): Immutable.Map<string, FieldRenderType> =>
    calcDependencyTreeRenderTypeHelper(state, field, form, Immutable.Map()),
  memoizeeConfig
)

/**
 * Updates the renderType of ancestor and descendant fields of
 * updated (value change or field rejection) field.
 * @param  {FormState} state Redux state slice
 * @param  {String}        field Name of the field
 * @param  {String}        form  Name of the form
 * @return {Immutable.Map}       new redux state
 */
export function updateDependencyTreeRenderType(state: FormState, _fields: string[], form: string): FormState {
  const fields = Immutable.Set(Array.prototype.concat(_fields))
  const rootFields = fields.map(f => getDependencyTreeRoots(state, f, form)).flatten(false)
  const renderTypes = rootFields.reduce(
    (acc, rf) => acc.merge(
      calcDependencyTreeRenderType(state, rf, form)
    ),
    Immutable.Map()
  )
  return state.updateIn(
    storePath(['renderTypes'], form),
    rt => (rt || Immutable.Map()).merge(renderTypes)
  )
}

/**
 * Determines display mode for field
 * @param  {FormState} state Redux state slice
 * @param  {String}    field Name of the field
 * @param  {String}    form  Name of the form
 * @return {String}          Field display mode
 */
export const calcFieldDisplayMode = memoize<StateFieldResolverParams, FieldDisplayMode>( 
  (
    state: FormState,
    field: string,
    form: string
  ): FieldDisplayMode => {
    const value = getIn(state, `values.${field}`, form)
    const viewType = state.getIn(storePath(['viewType'], form))
    const dirty = state.getIn(storePath(['fieldStates', field, 'dirty'], form))
    const rejected = state.getIn(storePath(['rejectedFields', field], form))
    const draftCorrected = hasImmutableValueChangedWithCoercedNils(rejected && rejected.get('rejectedValue'), value)
    const corrected = state.getIn(storePath(['correctedFields', field], form))
    const hasCorrectNote = state.getIn(storePath(['correctNotes', field], form))
    switch(viewType) {
      case FORM_VIEW_TYPE_NEW:
        return dirty ? FIELD_DISPLAY_MODE_DIRTY : null
      case FORM_VIEW_TYPE_EDIT:
        return dirty ? FIELD_DISPLAY_MODE_DIRTY : null
      case FORM_VIEW_TYPE_READ:
        if (corrected || (rejected && (draftCorrected || hasCorrectNote))) {
          return FIELD_DISPLAY_MODE_CORRECTED
        }
        if (rejected) {
          return FIELD_DISPLAY_MODE_REJECTED
        }
        return null
      case FORM_VIEW_TYPE_INSPECT:
        if (rejected) {
          return FIELD_DISPLAY_MODE_REJECTED
        }
        if (dirty) {
          return FIELD_DISPLAY_MODE_DIRTY
        }
        if (corrected) {
          return FIELD_DISPLAY_MODE_CORRECTED
        }
        return null
      case FORM_VIEW_TYPE_CORRECT:
        if (rejected && (draftCorrected || hasCorrectNote)) {
          return FIELD_DISPLAY_MODE_CORRECTED
        }
        if (rejected) {
          return FIELD_DISPLAY_MODE_REJECTED
        }
        if (dirty) {
          return FIELD_DISPLAY_MODE_DIRTY
        }
        return null
      default:
        return null
    }
  },
  memoizeeConfig
)

// TODO: unit test!
/**
 * Higher-order reducer to recalculate some global flags in redux store
 * (hasRejectedFields, hasRejectedNotCorrectedFields, hasErrors).
 * @param  {Function} reducer Original reducer function
 * @return {Function}         New reducer function
 */
export function recalcGlobalFlags(reducer: (FormState, Object) => FormState) {
  return function (state: FormState, action: Object): FormState {
    const newState = reducer(state, action)
    return newState.setIn(
      storePath(['hasRejectedFields'], action.meta.form),
      newState.getIn(storePath(['rejectedFields'], action.meta.form), Immutable.Map())
        .some(rf => Boolean(rf && rf.get('rejectReason')))
    )
    .setIn(
      storePath(['hasRejectedNotCorrectedFields'], action.meta.form),
      newState.getIn(storePath(['rejectedFields'], action.meta.form), Immutable.Map())
        .some((v, k) => Boolean(v && v.get('rejectReason'))
            && !hasImmutableValueChangedWithCoercedNils(
              v && v.get('rejectedValue'),
              getIn(newState, `values.${k}`, action.meta.form)
            ) && !newState.getIn(storePath(['correctNotes', k], action.meta.form))
        )
    )
    .setIn(
      storePath(['hasErrors'], action.meta.form),
      newState.getIn(storePath(['syncErrors'], action.meta.form), Immutable.Map())
        .some(x => Boolean(x))
    )
  }
}

// TODO: unit test!
export const updateComplexFieldAncestorsState = memoize<StateFieldResolverParams, FormState>(
  (
    state: FormState,
    field: string,
    form: string
  ): FormState => {
    const fieldStatesUpdate = getAncestors(state, field, form)
      .filter(f => getFieldType(state, f, form) === FIELD_TYPE_COMPLEX_FIELD)
      .reduce((acc, f) => {
        const value = getIn(state, `values.${f}`, form)
        const initialValue = getIn(state, `initialValues.${f}`, form)
        return acc.set(f, Immutable.fromJS({ dirty: hasImmutableValueChangedWithCoercedNils(value, initialValue) }))
      }, Immutable.Map())
    return state.mergeDeepIn(storePath(['fieldStates'], form), fieldStatesUpdate)
  },
  memoizeeConfig
)

// TODO: unit test!
export const updateComplexFieldAncestorsDisplayMode = memoize<StateFieldResolverParams, FormState>(
  (
    state: FormState,
    field: string,
    form: string
  ): FormState => {
    let fieldDisplayModesUpdate = Immutable.Map()
    const viewType = state.getIn(storePath(['viewType'], form))
    const complexFieldAncestors = getAncestors(state, field, form)
      .filter(f =>
        getFieldType(state, f, form) === FIELD_TYPE_COMPLEX_FIELD
      )
    // FORM_VIEW_TYPE_READ: read-only, display mode can't change
    // FORM_VIEW_TYPE_NEW: ComplexFields set their own display mode,
    //                     fields deeper in the hierarchy has no effect
    //                     on ancestor ComplexFields
    switch(viewType) {
      case FORM_VIEW_TYPE_EDIT:
      case FORM_VIEW_TYPE_INSPECT:
        fieldDisplayModesUpdate = complexFieldAncestors
          .reduce((acc, f) => {
            return acc.set(f, calcFieldDisplayMode(state, f, form))
          }, Immutable.Map())
        break
      case FORM_VIEW_TYPE_CORRECT:
        fieldDisplayModesUpdate = complexFieldAncestors
          .reduce((acc, f) => {
            if (isFieldRejected(state, f, form)) {
              const value = getIn(state, `values.${f}`, form)
              const rejected = state.getIn(storePath(['rejectedFields', f], form))
              const draftCorrected = hasImmutableValueChangedWithCoercedNils(
                rejected && rejected.get('rejectedValue'),
                value
              )
              const hasCorrectNote = state.getIn(storePath(['correctNotes', f], form))
              const displayMode = draftCorrected || hasCorrectNote
                ? FIELD_DISPLAY_MODE_CORRECTED
                : FIELD_DISPLAY_MODE_REJECTED
              return acc.set(f, displayMode)
            }
            const dirty = state.getIn(storePath(['fieldStates', f, 'dirty'], form))
            // if any ancestor ComplexField was dirty before we need to
            // check whether it's state is still dirty. If not then reset
            // its display mode.
            if (!dirty) {
              return acc.set(f, null)
            }
            return acc
          }, Immutable.Map())
        break
      default:
        fieldDisplayModesUpdate = Immutable.Map()
    }
    if (fieldDisplayModesUpdate.isEmpty()) {
      return state
    }
    return state.mergeDeepIn(storePath(['displayModes'], form), fieldDisplayModesUpdate)
  },
  memoizeeConfig
)

// TODO: unit test!
export function removeMultiFieldItem(state: FormState, flatMapKeys: string[], field: string, index: number, form: string) {
  return flatMapKeys.reduce((acc: FormState, key: string) => acc.updateIn(
    storePath([key], form),
    rf => storeFlatMapRemove(rf, field, index)
  ), state)
}

// TODO: unit test!
export function updateFieldAndAncestorsDisplayModes(state: FormState, field: string, form: string): FormState {
  let newState = updateFieldDisplayMode(state, field, form)
  return updateComplexFieldAncestorsDisplayMode(newState, field, form)
}

// TODO: unit test!
export function updateFieldDisplayMode(state: FormState, field: string, form: string): FormState {
  return state.setIn(
    storePath(['displayModes', field], form),
    calcFieldDisplayMode(state, field, form)
  )
}

// TODO: unit test!
function backupComplexField(state: FormState, field: string, form: string): FormState {
  let newState = backupSimpleField(state, field, form)
  newState.getIn(storePath(['registeredFields'], form), Immutable.Map())
    .keySeq()
    .toList()
    .filter(f => f.startsWith(field) && f.length > field.length)
    .forEach(f => {
      newState = backupField(newState, f, form)
    })
  return newState
}

// TODO: unit test!
function backupSimpleField(state: FormState, field: string, form: string): FormState {
  const backupField = state.getIn(storePath(['backupFields', field], form))
  if (!backupField) {
    const value = getIn(state, `values.${field}`, form)
    const rejectedField = state.getIn(storePath(['rejectedFields', field], form))
    const correctedField = state.getIn(storePath(['correctedFields', field], form))
    return state
      .setIn(
        storePath(['backupFields', field], form),
        Immutable.fromJS({
          value,
          rejectedField,
          correctedField,
        })
      )
  }
  return state
}

// TODO: unit test!
export function backupField(state: FormState, field: string, form: string): FormState {
  if (getFieldType(state, field, form) === FIELD_TYPE_COMPLEX_FIELD) {
    return backupComplexField(state, field, form)
  }
  return backupSimpleField(state, field, form)
}
