// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'
import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

type FormPayload = {
  formTypeId: string,
  formTypeVersion: number,
  instituteId?: string,
  physicianId: string,
  patientId?: string,
  formData: Object,
  formState: 'draft' | 'final'
}

export async function listForms() {
  const resp = await axiosInstance.get()
    .get('/api/v1/forms')
  return resp.data.results
}

export async function getForm(id: string): Promise<{ form: Object, xFormVersion: string }> {
  const resp = await axiosInstance.get().get(`/api/v1/forms/${id}`)
  const { 'x-form-version': xFormVersion } = resp.headers
  return { form: resp.data.results[0], xFormVersion }
}

export async function createNewAttendanceForm(payload: FormPayload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/attendances`, payload)
  return resp.data.results[0]
}

export async function createAttendancePeriodForm(attendancePeriodId: string, payload: FormPayload): Promise<Object> {
  const resp = await axiosInstance.get()
    .post(`/api/v1/attendance-periods/${attendancePeriodId}/forms`, payload)
  return resp.data.results[0]
}

export async function updateForm(id: string, payload: Object, xFormVersion: string): Object {
  const resp = await axiosInstance.get()
    .put(`/api/v1/forms/${id}`, payload, {
      headers: {
        'x-form-version': xFormVersion,
      }
    })
  return resp.data.results[0]
}

export async function deleteForm(id: string) {
  await axiosInstance.get()
    .delete(`/api/v1/forms/${id}`)
}

export async function approveForm(id: string, payload: Object, xFormVersion: string): Object {
  const resp = await axiosInstance.get()
    .put(`/api/v1/forms/${id}/approve`, payload, {
      headers: {
        'x-form-version': xFormVersion,
      }
    })
  return resp.data.results[0]
}

export async function rejectForm(id: string, payload: Object, xFormVersion: string): Object {
  const resp = await axiosInstance.get()
    .put(`/api/v1/forms/${id}/reject`, payload, {
      headers: {
        'x-form-version': xFormVersion,
      }
    })
  return resp.data.results[0]
}

export async function getFormHistory(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/${id}/history`)
  return resp.data.results
}

export async function getFormFieldHistory(formId: string, fieldName: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/${formId}/field-history/${fieldName}`)
  return resp.data.results
}

export async function correctDraftForm(id: string, payload: Object, xFormVersion: string) {
  const resp = await axiosInstance.get()
    .put(`/api/v1/forms/${id}/correct-draft`, payload, {
      headers: {
        'x-form-version': xFormVersion,
      }
    })
  return resp.data.results[0]
}

export async function correctFinalForm(id: string, payload: Object, xFormVersion: string) {
  const resp = await axiosInstance.get()
    .put(`/api/v1/forms/${id}/correct-finalized`, payload, {
      headers: {
        'x-form-version': xFormVersion,
      }
    })
  return resp.data.results[0]
}

export async function getAttendancePeriodForms(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/attendance_periods/${id}/forms`)
  return resp.data.results
}

export async function getApprovableForms(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/approvable`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getCorrectableForms(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/correctable`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getApprovableFormsCount() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/approvable-count`)
  return resp.data.results[0].count
}

export async function getCorrectableFormsCount() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/correctable-count`)
  return resp.data.results[0].count
}

export async function getAttachedFileFromForm(formId: string, uuid: string, fileName: string, fieldName: string) {
  const resp = await axiosInstance
    .get()
    .get(`/api/v1/forms/${formId}/${fieldName}/files/${uuid}/${fileName}`, { responseType: 'blob' })
  return resp
}