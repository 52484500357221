//@flow
import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Hint from '../../components/Hint'

const levelFontSize = {
  "0": "1.5",
  "1": "1.25",
  "2": "1.0",
}

const Title = styled.div`
  font-size: ${props => (levelFontSize[props.level]) || "1.0"}rem;
  font-weight: bold;
  margin: .5rem 0;
`

type Props = {
  children: React.Node,
  serial: number,
  title: string,
  hint?: string,
  level?: number,
}

class Section extends React.Component<Props> {

  static childContextTypes = {
    _parentSection: PropTypes.number,
  }

  getChildContext() {
    return {
      _parentSection: this.props.serial + 1,
    }
  }

  render () {
    const { children, hint, title = '' } = this.props
    return (
      <div>
        <Title level={this.props.level}>{title}</Title>
        {hint && <Hint label={hint} fontSize={16} margin="0 0 10px 0"/>}
        {children}
      </div>
    )
  }
}

export default Section;
