import 'normalize.css'
import './css/index.css'
import './css/reactabular.css'
import './css/rodal-customization.css'
import 'rodal/lib/rodal.css'
import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'date-time-format-timezone/build/src/date-time-format-timezone-golden-zones-golden-locales' // for timezone polyfill

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Immutable from 'immutable'
import localforage from 'localforage'
import * as Sentry from '@sentry/react'

import store from './services/store'
import AppIntlWrapper from './scenes/App/AppIntlWrapper'
import { loadTokenAction, logoutAction } from './services/auth'
import * as api from './services/api'
import accessToken from './services/access_token'
import { displayPackageVersions, parseBoolean } from './lib/utils'
import UserPreferences from './services/user_preferences/UserPreferences'
import { version as webClientVersion } from './package.json'

async function clearIndexedDB() {
  try {
    const ecrFormSession = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
      name: 'ecrFormSession',
    })

    const ecrFormBackup = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
      name: 'ecrFormBackup',
    })
    await ecrFormSession.ready() 
    await ecrFormBackup.ready()
    await ecrFormSession.clear() 
    await ecrFormBackup.clear()
  } catch (error) {
    console.error(error)
  }
}

UserPreferences.init().then(() => {
  displayPackageVersions()
  if (!parseBoolean(process.env.REACT_APP_ENABLE_FORM_BACKUP)) {
    clearIndexedDB()
  }
  // provide REACT_APP_SENTRY_DSN during build
  // see create-react-app doc for more info on environment variables
  // see sentry doc for more info on Sentry configuration
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ 
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `web-client@${webClientVersion}`
    })
    console.log('Sentry has been installed')
  } else {
    console.log('Sentry is not installed')
  }

  // must subscribe before loadTokenAction fired
  // otherwise token will not set properly
  store.subscribe(() => {
    const { token, expiresAt } = (
      store.getState().getIn(['auth', 'accessToken']) || Immutable.Map()
    ).toJS()
    accessToken.setToken(token, expiresAt)
    api.setToken(token)
    api.setLocale(store.getState().getIn(['app', 'locale']))
  })

  // try to load token from local storage
  store.dispatch(loadTokenAction())

  api.setUnauthorizedCallback(error =>
    store.dispatch(logoutAction('token_expired'))
  )
  api.setLocale(store.getState().getIn(['app', 'locale']))
  accessToken.init(store)

  return ReactDOM.render(
    <Provider store={store}>
      <AppIntlWrapper />
    </Provider>,
    document.getElementById('root')
  )
})
