//@flow

import React from 'react'
import Rodal from 'rodal'
import FontAwesome from 'react-fontawesome'
import ReactMarkdown from 'react-markdown/with-html'
import styled from 'styled-components/macro'
import gfm from 'remark-gfm'

import Button from './Button'
import 'rodal/lib/rodal.css'
type Props = {
  children?: React.Element<*>,
  onChange: (confirmation?: boolean) => void,
  icon?: string,
  iconColor?: string,
  visible: boolean,
  text?: string,
  options: Array<{
    label: string,
    value: any,
    kind?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'danger',
    icon?: string,
  }>,
  kind?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'danger',
  width?: number,
  height?: number,
}

const kindColors = {
  default: ['#adadad', '#e6e6e6', '#ffffff'],
  primary: ['#1571dd', '#2e82e4', '#589CEB'],
  info:    ['#18cbde', '#2dd5e7', '#7ae2ed'],
  success: ['#189f18', '#23a123', '#5cb85c'],
  warning: ['#d68a23', '#d68a23', '#dd9c45'],
  danger:  ['#d61e1a', '#d62c28', '#d9534f'],
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  .fa.icon  {
    color: ${props => props.iconColor || (kindColors[props.kind] && kindColors[props.kind][0])};
    font-size: 48px;
    margin-right: 32px;
    margin-left: 16px;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: auto; /* auto margins fill space in flexbox so push the element down */
  padding: .5em 0;
  button + button {
    margin-left: 16px;
  }
`

/**
 * ConfirmationModal displays a modal dialog with user supplied text
 * and buttons.
 *
 * Props:
 *
 * text
 *   Text to display in the modal. It supports Markdown content.
 * options
 *   Array of button descriptor objects in order. Object props:
 *     label: Label of the button.
 *     icon:  FontAwesome icon name of button's icon
 *     value: Value to return when the button is clicked.
 *     kind:  Kind of button (e.g. 'danger', see Button component)
 * visible
 *   When true the modal is shown when false the modal is hidden.
 * onChange
 *   Callback function which is called when the user click on a
 *   button in the modal dialog. The value defined for the button
 *   will be passed as a parameter to the callback function.
 * kind
 *   Kind of modal. Supports the same kinds as Button. It determines
 *   the default color of the icon.
 * icon
 *   FontAwesome icon name to display
 * iconColor
 *   HTML color of the icon
 * width
 *   Modal width in pixels (defaults to Rodal modal default width)
 * height
 *   Modal height in pixels (defaults to Rodal modal default height)
 */
const ConfirmationModal = (props: Props) => {
  const {
    children,
    icon,
    iconColor,
    width,
    height,
    kind = 'default',
    customStyles = {},
    customContent = false,
  } = props
  return (
    <Rodal
      visible={props.visible}
      onClose={ () => props.onChange() }
      width={width}
      height={height}
      customStyles={{ overflow: 'auto', ...(customStyles || {}) }}
      closeMaskOnClick={false}
      showCloseButton={false}
    >
      <Wrapper data-testid="confirmation-modal">
        {!customContent &&
          <Content
            iconColor={iconColor}
            kind={kind}
            data-testid="confirmation-modal-content"
            >
            {icon && <FontAwesome name={icon} size='2x' className={'icon'}/>}
            <div>
              { !children && <ReactMarkdown escapeHtml={false} plugins={[gfm]} source={props.text} /> }
              { React.Children.map(children, () => children) }
            </div>
          </Content>}
        {customContent && children}
        <Buttons>
          { props.options &&
              props.options.map( ({kind, icon, label, value, ...rest}, idx) =>
                <Button
                  key={idx}
                  onClick={() => props.onChange(value)}
                  kind={kind || 'default'}
                  icon={icon || null}
                  label={label || null}
                  {...rest}
                  />
              )
          }
        </Buttons>
      </Wrapper>
    </Rodal>
  )
}

export default ConfirmationModal
