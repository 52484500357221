// @flow

import React from 'react'
import PropTypes from 'prop-types'
import ConnectedDependentFields from './ConnectedDependentFields'

/**
 * name:     It is used to pass through ancestor ComplexField paths to its children.
 *           Only gets set when it has at least one ComplexField ancestor.
 * refField: Dependee field id (field name)
 * refValue: Dependee field value when DependentFields should render its children.
 */
type Props = {
  name?: string,
  refField?: string,
  refValue?: string,
  predicate?: string,
  children: Node,
}

class DependentFields extends React.Component<Props> {

  static contextTypes = {
    _myForm: PropTypes.object,
  }

  render () {
    return React.createElement(ConnectedDependentFields, {
      ...this.props,
      _myForm: this.context._myForm,
    },
    this.props.children)
  }

}

export default DependentFields
