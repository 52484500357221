import React, { Component } from 'react'
import hoistStatics from 'hoist-non-react-statics'
import Spy from './Spy'

const reduxSpy = (mapStateToKeys = () => ({})) => WrappedComponent => {
  class ReduxSpy extends Component {
    constructor(props) {
      super(props)
      this.spy = React.createRef() 
    }
    render() {
      return (
        <div>
          <Spy ref={this.spy} mapStateToKeys={mapStateToKeys}/>
          <WrappedComponent
            {...this.props}
            spy={key => this.spy.current.getProp(key)}/>
        </div>
      )
    }
  }
  return hoistStatics(ReduxSpy, WrappedComponent)
}

export default reduxSpy