import React from 'react'
import PropTypes from 'prop-types'
import ConnectedField from './ConnectedField'

class Field extends React.Component {

  static contextTypes = {
    _myForm: PropTypes.object,
    _parentSection: PropTypes.number,
  }

  render () {
    return React.createElement(ConnectedField, {
      ...this.props,
      _myForm: this.context._myForm,
      _parentSection: this.context._parentSection,
    },
    this.props.children)
  }
}

export default Field;
