//@flow
import * as React from 'react';
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Icon = styled(FontAwesome)`
  margin-right: 5px;
  min-width: 13px;
`

type Props = {
  children: React.Node,
  icon: string,
}

const ContentWithIcon = ({ children, icon }: Props) => (
  <Container>
    <Icon name={icon} />
    <div>{children}</div>
  </Container>
)

export default ContentWithIcon
