import locale2 from 'locale2'
import _ from 'lodash'
import UserPreferences from '../services/user_preferences/UserPreferences'

import huLocale from './hu'
import enLocale from './en'

const supportedLocales = ['en', 'hu']

// load react-intl locale data for supported languages
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/hu'); // Add locale data for de
  require('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/hu'); // Add locale data for de
  require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for de
}

export function getAppLocale() {
  const preferredLocale = UserPreferences.getPreference('locale')
  if (preferredLocale && _.includes(supportedLocales, preferredLocale)) {
    return preferredLocale
  }
  if (/^hu/i.test(locale2)) {
    return 'hu'
  }
  return 'en'
}

export function getLocaleResources(locale) {
  switch(locale) {
    case 'hu':
      return huLocale
    case 'en':
      return enLocale
    default:
      return enLocale
  }
}
