// @flow

import React from 'react'
import PropTypes from 'prop-types'
import ConnectedComplexField from './ConnectedComplexField'

type Props = {
  component: React$Element<*>,
  label: string,
  name: string,
}

class ComplexField extends React.Component<Props> {

  static contextTypes = {
    _myForm: PropTypes.object,
    _parentSection: PropTypes.number,
  }

  render () {
    return React.createElement(ConnectedComplexField, {
      ...this.props,
      _myForm: this.context._myForm,
      _parentSection: this.context._parentSection,
    })
  }

}

export default ComplexField
