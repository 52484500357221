//@flow
import React from 'react'
import * as Immutable from 'immutable'
import _ from 'lodash'
import SimpleTextFormat from './SimpleTextFormat'

import { ValueDisplay } from './DecoratedInputField'

type Props = {
  value: any,
  arraySeparator?: string,
  fieldOrders?: Object,
  nodataValue?: string,
  getLabel?: (fieldId: string, defaultValue?: any) => string,
  valueToLabel?: (field: string, value: any) => string,
  nilValue?: string,
}

const getType = (val) => {
  if (Immutable.Map.isMap(val) || Immutable.OrderedMap.isOrderedMap(val)) return 'immutable-map'
  if (Immutable.List.isList(val)) return 'immutable-list'
  if (_.isNumber(val)) return 'number'
  if (_.isString(val)) return 'string'
  if (_.isArray(val)) return 'array'
  if (_.isPlainObject(val)) return 'object'
  if (_.isNil(val)) return 'nil'
  return ''
}

const hasCompositeValue = (val) =>
  _.some(val, v => _.includes([
    'immutable-map',
    'immutable-list',
    'array',
    'object'
  ], getType(v)))

const compareOrder = (order?: Object) => {
  const fn = (a: string, b: string) => {
    if(order){
      return (order[a] < order[b]) ? -1 : 1
    }
    return 0
  }
  return fn
}

class FieldValueDisplay extends React.Component<Props> {

  translateLabel(fieldId: string){
    const { getLabel } = this.props
    if(getLabel){
      return getLabel(fieldId, fieldId)
    }
    return fieldId
  }

  renderLabel(fieldId?: string){
    if(fieldId){
      return <span style={{ fontWeight: 'bold' }} title={fieldId}>{this.translateLabel(fieldId)}</span>
    }
  }

  translateValue(fieldId?: string, value: any){
    const {
      valueToLabel,
      nodataValue
    } = this.props
    if(value === '#nodata#' && nodataValue){
      return nodataValue
    }
    if(
      fieldId && valueToLabel
    ){
      return valueToLabel(fieldId, value)
    }
    return value
  }

  renderNilField(fieldId: string) {
    const {
      nilValue = '-'
    } = this.props
    if(fieldId){
      return (
        <div key={fieldId}>
          {this.renderLabel(fieldId)}:&nbsp;
          <ValueDisplay>{nilValue}</ValueDisplay>
        </div>
      )
    }
    return <ValueDisplay>{nilValue}</ValueDisplay>

  }

  renderNumberField(fieldId: string, value: number) {
    if(fieldId){
      return (
        <div key={fieldId}>
          {this.renderLabel(fieldId)}:&nbsp;
          <ValueDisplay>{this.translateValue(fieldId, value)}</ValueDisplay>
        </div>
      )
    }
    return <ValueDisplay>{this.translateValue(fieldId, value)}</ValueDisplay>

  }

  renderStringField(fieldId: string, value: string) {
    if (value) {
      const renderedValue =
        /\n.+/.test(value) ?
          <SimpleTextFormat text={this.translateValue(fieldId, value)}/>
          : <ValueDisplay>{this.translateValue(fieldId, value)}</ValueDisplay>
      if(fieldId){
        return (
          <div key={fieldId}>
            <div></div>{this.renderLabel(fieldId)}:
            {renderedValue}
          </div>
        )
      }
      return renderedValue
    }
    return this.renderNilField(fieldId)
  }

  renderArrayField(fieldId: string , value: any[]) {
    const { arraySeparator = ",&nbsp;" } = this.props
    const hasComposite = hasCompositeValue(value)
    if (value.length > 0) {
      return (
        <div key={fieldId} style={{ display: hasComposite ? 'flex' : 'inline-flex', flexDirection: 'row' }}>
          <div
            style={{
              display: hasComposite ? 'flex' : 'inline-flex',
              flexDirection: hasComposite ? 'column' : 'row',
            }}>
            <div>
            {fieldId && <div>{this.renderLabel(fieldId)}:&nbsp;</div>}
            {value.map((v, i) => {
              const field = hasComposite ?
                this.renderField('', v)
                : (/\n.+/.test(v) ?
                  <SimpleTextFormat text={this.translateValue(fieldId, v)}/>
                  : <ValueDisplay>{this.translateValue(fieldId, v)}</ValueDisplay>)
              if(field) {
                return (
                  <div
                    key={i}
                    style={{
                      display: hasComposite ? 'flex' : 'inline-flex',
                      flexDirection: 'row',
                    }}
                    >
                    {hasComposite && <div style={{ marginRight: 5 }}><span>{'▸'}</span></div>}
                    <div style={{ marginBottom: hasComposite ? 5 : 0 }}>
                      {field}
                      {!hasComposite && arraySeparator && i !== value.length - 1 ?
                        <span dangerouslySetInnerHTML={{ __html: arraySeparator }} />
                        : null}
                    </div>
                  </div>
                )
              }
              return null
            })
            }
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  renderObjectField(fieldId: string ,value: Object) {
    if (!_.isEmpty(value)) {
      const compare = compareOrder(this.props.fieldOrders)
      return (
        <div key={fieldId}>
          {
            fieldId &&
            <div>
                {this.renderLabel(fieldId)}:&nbsp;
            </div>
          }
          <div style={{display: 'flex', flexDirection: 'row'}}>
          {fieldId && <div style={{ marginRight: 5 }}><span>{'▸'}</span></div>}
          <div key={fieldId}>
            {
              Object.keys(value).sort(compare).map(key => {
                return this.renderField(key,value[key])
              })
            }
          </div>
          </div>
        </div>
      )
    }
    return null
  }

  renderField(fieldId: string, value: any) {

    switch(getType(value)) {
      case 'number':
        return this.renderNumberField(fieldId, value)
      case 'string':
        return this.renderStringField(fieldId, value)
      case 'nil':
        return this.renderNilField(fieldId)
      case 'array':
        return this.renderArrayField(fieldId, value)
      case 'immutable-list':
        return this.renderArrayField(fieldId, value.toJS())
      case 'object':
        return this.renderObjectField(fieldId,value)
      case 'immutable-map':
        return this.renderObjectField(fieldId,value.toJS())
      default:
        return <div>value</div>
          }
  }

  render() {
    const { value } = this.props
    return this.renderField('', value)
  }

}

export default FieldValueDisplay
