import Immutable from 'immutable'
import thunk from 'redux-thunk'
import { combineReducers } from 'redux-immutablejs'
import { compose, createStore, applyMiddleware } from 'redux'
import { reducer as form } from 'redux-form/immutable'
import * as reducers from '../reducers'
import { authReducer } from '../auth'
import { mfaSetupReducer } from '../mfa_setup'
import { formReducer } from '../../lib/ecr_form'

const initialState = Immutable.fromJS({})

export function createAppStore(allReducers, initialState) {
  const finalCreateStore = compose(applyMiddleware(...[thunk]))(createStore)
  return finalCreateStore(
    allReducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

// the famous redux store, which stores the application's state
const store = createAppStore(
  combineReducers({
    ...reducers,
    auth: authReducer,
    mfaSetup: mfaSetupReducer,
    ecrForm: formReducer,
    form: form,
  }),
  initialState
)

export default store
