//@flow
import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import semver from 'semver'

import { apiVersion } from '../../package.json'
import { getClientConfiguration } from '../../services/api'
import {
  setClientConfiguration,
  setCurrentInstitute,
  setLocaleAction,
} from '../../services/actions'
import confirmationDialog from '../../components/confirmation_dialog'
import UserPreferences from '../../services/user_preferences/UserPreferences.js'
import FullPageSpinner from '../../components/FullPageSpinner'

type Props = {
  isLoggedIn: boolean,
  loginInProgress: boolean,
  setClientConfiguration: Function,
  setCurrentInstitute: Function,
  setLocaleAction: (locale: string) => void,
  locale: string,
}

type State = {
  loading: boolean,
}

const LoginScreen = lazy(() =>
  import('../Login/LoginScreen' /* webpackChunkName: "login" */)
)
const RegistrationScreen = lazy(() =>
  import(
    '../Registration/RegistrationScreen' /* webpackChunkName: "registration" */
  )
)
const AdminScreen = lazy(() =>
  import('../Admin/AdminScreen' /* webpackChunkName: "admin" */)
)
const MainScreen = lazy(() =>
  import('../Main/MainScreen' /* webpackChunkName: "main-screen" */)
)
const PageNotFound = lazy(() =>
  import('../shared/PageNotFound' /* webpackChunkName: "page-not-found" */)
)
class App extends Component<Props, State> {
  state = {
    loading: true,
  }

  componentDidMount() {
    this.loadClientConfiguration()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.props.isLoggedIn && nextProps.isLoggedIn) {
      this.loadClientConfiguration()
    }
  }

  async loadClientConfiguration() {
    this.setState({ loading: true })
    try {
      const clientConfig = await getClientConfiguration()
      if (!semver.satisfies(clientConfig.apiVersion, apiVersion)) {
        console.warn(
          `API version is ${clientConfig.apiVersion} but client requires ${apiVersion}`
        )
      }
      this.props.setClientConfiguration(clientConfig)
      UserPreferences.setUser(clientConfig.user.id)
      this.props.setLocaleAction(UserPreferences.getPreference('locale'))
      const { id, name, shortName } = clientConfig.user.institute || {}
      this.props.setCurrentInstitute(id, name, shortName)
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  getConfirmation = (message: string, callback: Function) => {
    const { locale } = this.props
    confirmationDialog(message, [
      { label: locale === 'en' ? 'No' : 'Nem', value: false, icon: 'times' },
      {
        label: locale === 'en' ? 'Yes' : 'Igen',
        value: true,
        kind: 'primary',
        icon: 'check',
      },
    ]).then(async (ok) => {
      if (ok) callback(true)
      callback(false)
    })
  }

  render() {
    const { loading } = this.state
    if (loading) return <FullPageSpinner fullHeight />
    return (
      <Router getUserConfirmation={this.getConfirmation}>
        <Suspense fallback={<FullPageSpinner fullHeight />}>
          <Switch>
            <Route path="/login" component={LoginScreen} />
            <Route path="/registration" component={RegistrationScreen} />
            <Route path="/admin" component={AdminScreen} />
            <Route path="/" component={MainScreen} />
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default connect(
  (state) => ({
    isLoggedIn: Boolean(state.getIn(['auth', 'accessToken'])),
    loginInProgress: state.getIn(['auth', 'loginInProgress']),
  }),
  {
    setClientConfiguration,
    setCurrentInstitute,
    setLocaleAction,
  }
)(App)
