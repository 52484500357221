import React from 'react'

const SimpleTextFormat = (props) => {
  const { text } = props
  return (
    <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</div>
  )
}

export default SimpleTextFormat
