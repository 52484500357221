import UserPreferences from '../user_preferences/UserPreferences'

export const SHOW_FORM_FIELD_HISTORY = 'app/SHOW_FORM_FIELD_HISTORY'
export const HIDE_FORM_FIELD_HISTORY = 'app/HIDE_FORM_FIELD_HISTORY'
export const SET_LOCALE = 'app/SET_LOCALE'
export const SET_CLIENT_CONFIGURATION = 'app/SET_CLIENT_CONFIGURATION'
export const SET_CURRENT_INSTITUTE = 'app/SET_CURRENT_INSTITUTE'


export function showFormFieldHistoryAction(formId, fieldName, fieldLabel) {
  return {
    type: SHOW_FORM_FIELD_HISTORY,
    payload: {
      formId,
      fieldName,
      fieldLabel,
    }
  }
}

export function hideFormFieldHistoryAction() {
  return {
    type: HIDE_FORM_FIELD_HISTORY,
  }
}

export function changeLocaleAction(locale) {
  return function (dispatch, getState) {
    UserPreferences.setPreference('locale', locale)
    dispatch(setLocaleAction(locale))
  }
}

export function setLocaleAction(locale) {
  return {
    type: SET_LOCALE,
    payload: locale,
  }
}

export function setClientConfiguration(clientConfiguration) {
  return {
    type: SET_CLIENT_CONFIGURATION,
    payload: clientConfiguration,
  }
}

export function setCurrentInstitute(id, name, shortName) {
  return {
    type: SET_CURRENT_INSTITUTE,
    payload: {
      id,
      name,
      shortName,
    }
  }
}
