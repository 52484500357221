// @flow
import _ from 'lodash'
import * as Immutable from 'immutable'
import uuidBase62 from 'uuid-base62'
export * from './version'

export function hasValueChanged(left, right) {
  return Boolean((left && !right) || (!left && right) || (left && right && left !== right))
}

export function hasValueChangedDeep(left, right) {
  return Boolean((left && !right) || (!left && right) || (left && right && !_.isEqual(left, right)))
}

// TODO: optimize by checking inputs using Immutable.isImmutable
// if neither input is Immutable then use _.isEqual otherwise
// convert the non-Immutable value with Immutable.fromJS
// and use Immutable.is
export function hasImmutableValueChanged(left, right) {
  return Boolean(
    (left && !right)
    || (!left && right)
    || (left && right && !Immutable.is(Immutable.fromJS(left), Immutable.fromJS(right)))
  )
}

export function isConsideredNilOrEmpty(value) {
  return (value && Immutable.Iterable.isIterable(value) && value.isEmpty())
    || _.isNil(value)
    || ((_.isString(value) || _.isArray(value) || _.isObject(value)) && _.isEmpty(value))
}

export function hasImmutableValueChangedWithCoercedNils(left, right) {
  return hasImmutableValueChanged(
    isConsideredNilOrEmpty(left) ? undefined : left,
    isConsideredNilOrEmpty(right) ? undefined : right
  )
}

/**
 * Convert property path array to string path.
 * Inverse of lodash' toPath function.
 * @param  {Array<string|number>} arr Path array
 * @return {String}                   Object string path
 */
export function fromPath(arr) {
  return arr.reduce((acc, item, idx) => {
    if (Number.isNaN(Number(item))) {
      return `${acc}${idx === 0 ? '' : '.'}${item}`
    }
    return `${acc}[${item}]`
  }, '')
}

/**
 * Converts a field path (e.g. `c1[0].f1`) into Immutable compatible field
 * path (e.g. ['c1', 0, `f1`])
 * @param  {string}                 path Field path
 * @return {Array<string | number>}      Immutable compatible path
 */
export function fieldPathToImmutablePath(path: string): Array<string | number> {
  return path.split(/[\[\]\.]/) // eslint-disable-line no-useless-escape
    .filter(x => x.length > 0)
    .map(x => {
      const c = Number(x)
      return Number.isNaN(c) ? x : c
    })
}

export function encodeUuid(id) {
  return id ? uuidBase62.encode(id) : id
};

export function decodeUuid(id) {
  return id ? uuidBase62.decode(id) : id
};

/**
 * The component will receive an immutable map from the redux store on readOnly
 * we need to convert it.
 * @param  {ImmutableMap} value
 * @return {Object}             return a plain JS object
 */
export function convertMapToJs(value: any) {
  return (value && typeof value.toJS === 'function') ? value.toJS() : value
}

export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

const BOOLEAN_MAP = {
  '1': true,
  'true': true,
  't': true,
  'yes': true,
  'y': true,
  'on': true,
  '0': false,
  'false': false,
  'f': false,
  'no': false,
  'n': false,
  'off': false,
}

export function parseBoolean(str, throwError = false) {
  const result = BOOLEAN_MAP[_.toLower(str)]
  if (throwError && typeof result === 'undefined') {
    throw new Error(`Invalid boolean value: ${str}`)
  }
  return Boolean(result)
}