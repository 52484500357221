// @flow
import React from 'react'
import ReactDOM from 'react-dom'

import ConfirmationModal from './ConfirmationModal'

class Confirmable extends React.Component<*, { visible: boolean }> {
  constructor(props) {
    super(props)
    this.state = {
      visible: true,
    }
  }

  changeHandler = value => {
    this.setState({
      visible: false,
    })
    this.props.dispose(value)
  }

  render() {
    const {
      text = 'Are you sure?',
      options = [
        { label: 'Cancel', value: false, icon: 'times' },
        { label: 'Ok', value: true, icon: 'check', kind: 'primary' },
      ],
      confirmationModalProps,
    } = this.props
    return (
      <ConfirmationModal
        text={text}
        visible={this.state.visible}
        options={options}
        onChange={this.changeHandler}
        kind={'default'}
        icon={'question'}
        height={200}
        width={400}
        {...confirmationModalProps}
      />
    )
  }
}

export default function confirmationDialog(
  text: string,
  buttons: Object[],
  confirmationModalProps: Object
): Promise<any> {
  return new Promise((resolve, reject) => {
    const wrapper: HTMLDivElement =
      document &&
      document.body &&
      document.body.appendChild(document.createElement('div'))
    try {
      ReactDOM.render(
        <Confirmable
          text={text}
          options={buttons}
          dispose={dispose}
          confirmationModalProps={confirmationModalProps}
        />,
        wrapper
      )
    } catch (err) {
      console.error(err)
      throw err
    }

    function dispose(value) {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper)
        setTimeout(() => wrapper.remove())
      }, 1000)
      resolve(value)
    }
  })
}
