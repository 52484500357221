import { version as formValidatorsVersion } from 'form-validators'
import { version as webClientVersion } from '../../package.json'

export function getPackageVersions() {
  return {
    'form_validator': formValidatorsVersion,
    'web-client': webClientVersion,
  }
}

export function displayPackageVersions() {
  const versions = getPackageVersions()
  console.groupCollapsed('Package versions')
  Object.keys(versions).forEach(p => console.log(p, versions[p]))
  console.groupEnd('Package versions')
}
