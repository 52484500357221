const messages = {
  'actions':                                     'Actions',
  'male':                                        'Male',
  'female':                                      'Female',
  'yes':                                         'Yes',
  'no':                                          'No',
  'active':                                      'Active',
  'inactive':                                    'Inactive',
  'search':                                      'Search',
  'nodata':                                      'no data',
  'noDataDisplay':                               '<No data>',
  'virtual':                                     'virtual',
  'today':                                       'Today',
  'now':                                         'Now',
  'noItemFound':                                 'No item found!',
  'loading':                                     'Loading',

  'read':                                        'Read',
  'new':                                         'New',
  'edit':                                        'Edit',
  'inspect':                                     'Inspect',
  'correct':                                     'Correct',

  'footer.pricaryPolicyLinkText':                'Privacy statement',
  'footer.problemReportingLinkText':             'Report problem',

  'sponsor.platinum':                            'Platina sponsors',
  'sponsor.gold':                                'Gold sponsors',
  'sponsor.silver':                              'Silver sponsors',
  'sponsor.bronze':                              'Bronze sponsors',

  'fileUploader.addNewFile':                     'Add new file',
  'fileUploader.dragHere':                       'Drag files here',
  'fileUploader.error':                          'The file could not be uploaded!',
  'fileUploader.defaultHint':                    'Only anonymised files can be uploaded. Please pay attention to the personal data in the headers and footers of documents and delete or cover them!',

  'saveForm.fileName':                           'File name',
  'saveForm.password':                           'Password',
  'saveForm.download':                           'Download',
  'saveForm.dialogText':                         'With this function you can download the form and upload to an other one. The form version and id has to match! For the downloading you have to provide a password for encrypthing purpose!',
  'saveForm.error.password':                     'You have to give a password!',
  'saveForm.error.fileName':                     'You have to give a file name!',

  'uploadForm.dialogText':                       'Are you sure?<br/>**Legacy id:** {displayId}<br/>**Exported by:** {exportedBy}<br/>**Exported at:** {exportedAt}',
  'uploadForm.promptText':                       'Enter your password for the decryption!',
  'uploadForm.upload':                           'Upload',
  'uploadForm.error.missingPassword':            'Password is missing!',
  'uploadForm.error.missingFile':                'File is missing!',
  'uploadForm.error.formTypeId':                 'Form type id not match!',
  'uploadForm.error.formTypeVersion':            'Form type version not match!',
  'uploadForm.error.passwordNotMatch':           'Password not match!',

  'page.title.adminDashboard':                   'Admin Dashboard',
  'page.title.userDashboard':                    'User Dashboard',
  'page.title.usersList':                        'Users List',
  'page.title.userDetails':                      'User Details',
  'page.title.editUser':                         'Edit User',
  'page.title.newUser':                          'New User',
  'page.title.attendanceList':                   'Attendance List',
  'page.title.approvableForms':                  'Approveable Forms',
  'page.title.correctableForms':                 'Correctable Forms',
  'page.title.instituteDetails':                 'Institute Details',
  'page.title.newInstitute':                     'New Institute',
  'page.title.editInstitute':                    'Edit Institute',
  'page.title.instituteList':                    'Institute List',
  'page.title.patientsList':                     'Patients List',
  'page.title.dataExport':                       'Data export',
  'page.title.userGroupList':                    'Groups list',
  'page.title.registries':                       'Registries',
  'page.title.formType':                         'Form Type',
  'page.title.applicationLog':                   'Application log',
  'page.title.newDataImport':                    'New Data Import',
  'page.title.listDataImportJobs':               'List Data Import Jobs',
  'page.title.showDataImportJob':                'Data Import Job',

  'profile.title':                               'User profile | {name}',
  'profile.passwordChange':                      'Change password',
  'profile.oldPassword':                         'Old password',
  'profile.newPassword':                         'New password',
  'profile.newPasswordConfirmation':             'New password again',
  'profile.newPassword.hint':                    'Password must meet at least 3 out of the following 4 complexity rules\n* at least 1 uppercase character (A-Z)\n* at least 1 lowercase character (a-z)\n* at least 1 digit (0-9)\n* at least 1 special character (punctuation)\n* Password must have at least 10 characters',

  'profileSettingsForm.changeYourPassword':             'Change your password',
  'profileSettingsForm.legend.password':                'Password',
  'profileSettingsForm.legend.MFA':                     'Multi-factor authentication',
  'profileSettingsForm.explanation.forbidden':          'Multi-factor authentication is disabled by your administrator. You cannot change this setting.',
  'profileSettingsForm.explanation.enforced':           'Multi-factor authentication is enforced by your administrator. You cannot change this setting.',
  'profileSettingsForm.recoveryKeys.title':             'Recovery keys regenerated!',
  'profileSettingsForm.recoveryKeys.explanation':       `
    <p>
      <b>Before you close this panel, please save or print your recovery keys! You will not be able to view the recovery keys later!</b>
    </p>
    <p>
      When you loose access to your authenticator app you can use your recovery keys as a secondary login factor. Each recovery key can be used only once! After successful login you can generate a new set of recovery keys.
    </p>`,
  'profileSettingsForm.mfaDisabled.explanation':        'MFA is currently <b>disabled</b>. To enable MFA click the button below:',
  'profileSettingsForm.mfaDisabled.button.enableMfa':   'Enable MFA',
  'profileSettingsForm.mfaEnabled.explanation':         'MFA is currently <b>enabled</b>. To disable MFA, click the button below.',
  'profileSettingsForm.mfaEnabled.button.disableMfa':   'Disable MFA',
  'profileSettingsForm.mfaEnabled.notConfigured':       'MFA is currently enabled but not configured. You can setup it now or after the next successful login the MFA setup process will start automatically.',
  'profileSettingsForm.mfaEnabled.button.configureMfa': 'Configure MFA',
  'profileSettingsForm.recovery.explanation':           'You have {keyCount} more valid recovery keys. To regenerate recovery keys enter your password and click the button below:',
  'profileSettingsForm.recovery.placeholder.password':  'Enter your password...',
  'profileSettingsForm.recovery.button.regenerate':     'Regenerate recovery keys',

  'editProfileContainer.confirmDisable.message':        'Are you sure you want to disable MFA? If you select yes, the MFA settings will be permanently cleared. If you later decide to re-enable MFA, you\'ll need to go through the MFA setup process again!',
  'editProfileContainer.confirmEnable.message':         'Are you sure you want to enable MFA? If you select yes, the MFA setup process will start!',
  'editProfileContainer.confirmPanelClose.message':     'Are you sure you want to interrupt MFA setup process? Unless you turn it off MFA remains enabled and you need to setup after the next successful login',
  'editProfileContainer.error.regenerateMfaRecoveryKeys': 'Recovery key generation failed',
  'editProfileContainer.error.disableMfa':              'Disabling MFA failed',
  'editProfileContainer.error.invalidPassword':         'Invalid passsword',
  'editProfileContainer.error.mfaNotConfigured':        'MFA is not configured',

  'confirmation.delete':                         'Are you sure you want to delete?',
  'confirmation.localeChange':                   'Are you sure you want to change language?<br/>**All your unsaved data will be lost!**',
  'confirmation.logout':                         'Are you sure you want to logout?<br/>**All your unsaved data will be lost!**',
  'confirmation.impersonate':                    'Are you sure you want to impersonate this user?',
  'confirmation.impersonationExit':              'Are you sure you want to exit impersonation?',
  'confirmation.updateEmailList':                'Are you sure you want to update email list?',

  'tooltip.correct':                             'Correct',
  'tooltip.correctNote':                         'Correct note',
  'tooltip.delete':                              'Delete',
  'tooltip.edit':                                'Edit',
  'tooltip.inspect':                             'Inspect',
  'tooltip.read':                                'Review',
  'tooltip.reject':                              'Correction request',
  'tooltip.fieldHistory':                        'Field history',
  'tooltip.filter':                              'Filter',
  'tooltip.fix':                                 'Administrative correct',
  'tooltip.reset':                               'Reset',
  'tooltip.acl':                                 'Permissions',
  'tooltip.addBioSample':                        'Link',
  'tooltip.detachBioSample':                     'Detach',
  'tooltip.createDescendantSample':              'Create descendant sample',
  'tooltip.jumpToAttendancePeriod':              'Jump to attendance',
  'tooltip.resetFormApproval':                   'Delete approval on form',
  'tooltip.resetAttendanceApproval':             'Delete approval on attendance',
  'tooltip.impersonate':                         'Impersonate user',

  'action.addNew':                               'Add new',

  'attendanceList.id':                           'Attendance id',
  'attendanceList.inpatient':                    'Inpatient days',
  'attendanceList.completion':                   'Attendance completion',
  'attendanceList.legacyDisplayId':              'Legacy code',
  'attendanceList.randomGroup.label':            'Randomization group',
  'attendanceList.ownerName':                    'Owner',

  'button.ok':                                   'Ok',
  'button.cancel':                               'Cancel',
  'button.close':                                'Close',
  'button.back':                                 'Back',
  'button.send':                                 'Send',
  'button.save':                                 'Save',
  'button.reset':                                'Reset',
  'button.update':                               'Update',
  'button.more':                                 'More information',
  'button.moreInfo':                             'More...',
  'button.impersonate':                          'Impersonate',
  'button.impersonate.exit':                     'Exit impersonation',

  'gen.name':                                    'Gens',
  'gen.tested':                                  'Tests',
  'gen.region':                                  'Region',
  'gen.result':                                  'Results',
  'gen.duplicate':                               'Gen already exists!',
  'gen.emptyField':                              'You have to give the gen name!',
  'gen.addNewGen':                               'Add new gen',

  'unitConverter.title':                         'Please enter the value to convert.',
  'unitConverter.button':                        'Convert',

  'error.pageNotFound':                          'Page not found',
  'error.dataLoadError':                         'Error loading data',
  'error.default':                               "It looks like we're having issues.",

  'formBackup.backupLoad':                       'Load save',
  'formBackup.notSupported':                     "This browser did not support automate saves. Please change to Chrome or Firefox for this functionality!",
  'formBackup.backupAvailable':                  'Automate save available! Please select a date.',
  'formBackup.backupAvailableShort':             'Automate save available!',
  'formBackup.selectHint':                       'The reload of a save will overwrite the actual form state!',

  'form.noValue':                                "<no value>",

  'form.button.form_history':                    'Form History',
  'form.button.approve':                         'Approve',
  'form.button.close':                           'Close',
  'form.button.finalize':                        'Finalize',
  'form.button.finish_correction':               'Finish Correction',
  'form.button.reject':                          'Correction request',
  'form.button.save':                            'Save',
  'form.button.save_draft':                      'Save Draft',
  'form.button.dataImport':                      'Data import',

  'form.dataImport.text':                        'Please select a form from which you would transfer data.',
  'form.dataImport.importableData':              'The following data will be imported:',

  'form.error.loadError':                        'Error loading form',
  'form.error.saveError':                        'Error saving form',
  'form.error.deleteError':                      'Error deleting form',

  'form.success.delete':                         'Form successfully deleted',

  'form.validation.error.mandatory':             'mandatory',
  'form.validation.error.invalidFormat':         'invalid format',
  'form.validation.error.doNotMatch':            'do not match',
  'form.validation.error.atLeastOneRequired':    'at least one required',
  'form.validation.error.atLeastOnRequiredFromTheList': 'at least one required from the list: {list}',
  'form.validation.error.cannotUseBothApprovalsAndFile': 'cannot use both file upload and minimum attendance approval',

  'form.confirmation.dirtyClose':                'Are you sure?<br/>**Your unsaved data will be lost!**',
  'form.confirmation.delete':                    'Are you sure to delete form ({displayId})?<br/>**The deletion is final the form cannot be recovered!**',
  'form.confirmation.delete-a':                  'Are you sure to delete attendance start form ({displayId})?<br/>**The attendance the form belongs to and all the forms that belongs to that attendance will be deleted. Deletion is permanent! The form and the related attendance cannot be recovered!**',

  'form.censoredMessage':                        "<No permission to view>",

  'form.resetAttendanceApproval':                'With this you will delete all approval on this attendance! Are you sure?',
  'form.resetFormApproval':                      'With this you will delete all approval on this form Are you sure?',

  'fieldHistory.title':                          '{fieldName} - Field History',
  'fieldHistory.column.timestamp':               'Timestamp',
  'fieldHistory.column.user':                    'User',
  'fieldHistory.column.operation':               'Operation',
  'fieldHistory.column.formState':               'State',
  'fieldHistory.column.fieldValue':              'Field value',
  'fieldHistory.column.notes':                   'Notes',

  'formHistory.title':                           'Form History',
  'formHistory.column.timestamp':                'Timestamp',
  'formHistory.column.user':                     'User',
  'formHistory.column.operation':                'Operation',
  'formHistory.column.formState':                'State',
  'formHistory.column.approvalState':            'Approvals',

  'formList.deleteConfirmationQuestion':         'Are you sure you want to delete?',
  'formList.noFormAvailable':                    'No form available to display',
  'formList.pageTitle':                          'Forms',

  'formList.column.attendanceNumber':            'Form ID',
  'formList.column.patientName':                 'Name',
  'formList.column.patientGender':               'Gender',
  'formList.column.patientRace':                 'Race',
  'formList.column.patientDateOfBirth':          'Date of birth',
  'formList.column.patientInsuranceNumber':      'Insurance number',
  'formList.column.formState':                   'State',
  'formList.column.approvalState':               'Approvals',
  'formList.column.operations':                  'Operations',

  'formList.error.loadError':                    'Error loading form list',
  'formList.error.deleteError':                  'Error deleting form',

  'formState.draft':                             'draft',
  'formState.await_inspect':                     'await inspection',
  'formState.rejected':                          'await correction',
  'formState.correct_draft':                     'under correction',
  'formState.corrected':                         'corrected',
  'formState.final':                             'final',

  'formOp.create':                               'create',
  'formOp.update':                               'update',
  'formOp.approve':                              'approve (level {inspectLevel})',
  'formOp.reject':                               'correction request (level {inspectLevel})',
  'formOp.correct':                              'correct',
  'formOp.approval_reset':                       'approval delete',
  'formOp.system_update':                        'system update',

  'loginFormContainer.mfaSkipSetupRequest.message': 'Skipping MFA setup...',
  'loginFormContainer.error.cannotSkipMfaSetup': 'Cannot skip MFA setup!',

  'loginForm.button.login':                      'Sign In',
  'loginForm.button.sendPasswordResetLink':      'Send Password Reset Link',
  'loginForm.button.forgotPassword':             'Forgot password?',
  'loginForm.button.resendEmail':                'Send email again',
  'loginForm.button.changePassword':             'Change Password',
  'loginForm.button.backToLogin':                'Back to login',
  'loginForm.placeholder.email':                 'Your email address',
  'loginForm.placeholder.password':              'Password',
  'loginForm.placeholder.confirmPassword':       'Confirm password',
  'loginForm.error.invalidEmail':                'Invalid e-mail address',
  'loginForm.error.loginFailed':                 'Login failed',
  'loginForm.error.tokenExpired':                'Session expired',
  'loginForm.error.loginError':                  'Login failed',
  'loginForm.error.forgetPassword':              'Request failed! Please try again later!',
  'loginForm.error.passwordMismatch':            'Passwords do not match!',
  'loginForm.header.changePassword':             'Please pick your new password!',
  'loginForm.header.passwordChanged':            'Successful password change',
  'loginForm.header.login':                      'Login',
  'loginForm.header.reqPasswordResetLink':       'Request Password Reset Link',
  'loginForm.header.emailSent':                  'Password reset link',
  'loginForm.content.emailSent':                 'We have sent you an email with a link which you can use to set your new password. Please check your mailbox!',
  'loginForm.content.passwordChanged':           'Your password has been changed! You can login now with your new password.',

  'mfaError.skipMfaSetup':                       'Skip MFA setup',
  'mfaError.cancel':                             'Cancel',

  'mfaForm.button.login':                        'Sign In',
  'mfaForm.button.backToLogin':                  'Back to login',
  'mfaForm.link.recoveryTokenRequired':          'Use MFA recovery key',
  'mfaForm.placeholder.mfaToken':                'Enter 6 digit token',
  'mfaForm.error.invalidToken':                  'Invalid token',
  'mfaForm.error.tokenExpired':                  'Session expired',
  'mfaForm.error.loginFailed':                   'Login failed',
  'mfaForm.header.login':                        'Secondary Login Factor Required',
  'mfaForm.hint':                                'Please use your authenticator to generate a 6-digit token.',

  'mfaSetupContainer.mfaSetupRequired.message':  'MFA setup required',
  'mfaSetupContainer.mfaSetupRequest.message':   'Initializing MFA setup',
  'mfaSetupContainer.mfaSetupFailure.title':     'MFA setup error',
  'mfaSetupContainer.mfaVerifyFailure.message':  'Verify failed',
  'mfaSetupContainer.mfaVerifySuccess.title':    'MFA token successfully verified!',
  'mfaSetupContainer.mfaVerifySuccess.message':  `<p>
      MFA token verification were successful. In the future you must use
      your authenticator app to generate 6 digit tokens as required by the
      login process.
      </p>
      <p>
        <b>
          Before you return to the login screen, please save or print your
          recovery keys! You will not be able to view the recovery keys
          later!
        </b>
      </p>
      <p>
      When you loose access to your authenticator app you can use your
      recovery keys as a secondary login factor. Each recovery key can be
      used only once! After successful login you can generate a new set of
      recovery keys.
    </p>`,

  'mfaSetupForm.setupFailureError.title':        'MFA Setup Error',
  'mfaSetupForm.title':                          'Multi-factor Authentication setup',
  'mfaSetupForm.intro':                          'Your account is now protected by MFA. Please follow the steps below to configure MFA with your smartphone. When choosing an authenticator application, a popular option is Google Authenticator (free on Android  and iOS).',
  'mfaSetupForm.step1.title':                    'Download an Authenticator application',
  'mfaSetupForm.step1.explanation':              'Install an authenticator application on your smartphone. Search for \'authenticator\' in your Application Store.',
  'mfaSetupForm.step2.title':                    'Scan the QR Code',
  'mfaSetupForm.step2.explanation1':             'Use the freshly installed authenticator application to scan the barcode below:',
  'mfaSetupForm.step2.explanation2':             'If you cannot scan the QR Code, you can manually enter the below key into your authenticator application:',
  'mfaSetupForm.step3.title':                    'Enter the authentication code',
  'mfaSetupForm.step3.explanation':              'Enter the 6-digit code displayed by your authenticator application',
  'mfaSetupForm.button.verifyAndContinue':       'Verify and continue',
  'mfaSetupForm.button.skipMfaSetup':            'Skip MFA setup',
  'mfaSetupForm.button.cancel':                  'Cancel',
  'mfaSetupForm.verifyingMessage':               'Verifying token...',

  'mfaRecoveryForm.hint':                        'Please use one of your recovery keys to login!',
  'mfaRecoveryForm.header.login':                'Use MFA Recovery Key',
  'mfaRecoveryForm.link.backToRequestToken':     'Use MFA token',
  'mfaRecoveryForm.placeholder.recoveryKey':     'Enter recovery key',
  'mfaRecoveryForm.button.login':                'Sign In',
  'mfaRecoveryForm.error.invalidToken':          'Invalid recovery key',
  'mfaRecoveryForm.error.tokenExpired':          'Session expired',
  'mfaRecoveryForm.error.loginFailed':           'Login failed',

  'mfaShowRecoveryKeys.button.print':            'Print',
  'mfaShowRecoveryKeys.button.copy':             'Copy',
  'mfaShowRecoveryKeys.button.confirm':          'I saved recovery codes',
  'mfaShowRecoveryKeys.keyList.screen.title':    'Recovery keys',
  'mfaShowRecoveryKeys.keyList.print.title':     'Your ECDMS recovery keys:',
  'mfaShowRecoveryKeys.keyList.print.warning':   'Please keep the printed recovery codes in a safe place!',

  'registrationForm.header.registration':        'Registration',

  'registration.confirmYourEmail.header':             'Confirm your email address!',
  'registration.confirmYourEmail.body':               'Please confirm your email address with opening the given link. Did not receive a confirmation email? Ask a new one with the link following below!',
  'registration.confirmYourEmail.backToLogin':        'Back to login',
  'registration.confirmYourEmail.resendConfirmation': 'Resend confirmation',

  'registration.resendEmail.header':             'Resend confirmation email',
  'registration.resendEmail.body':               'If you not received your confirmation email, please fill the form below. Type in your registration email and we will send you a new confirmation email.',
  'registration.resendEmail.success':            'We resent the confirmation email again.',

  'registration.confirmationSuccess.header':     'Email confirmation',
  'registration.confirmationSuccess.body':       'Email confirmation success. You can login after the activation process. We will notify you when you will be active.',
  'registration.confirmationSuccess.fail':       'Email confirmation failed. Please contact our administrator.',

  'institutes.column.name':                      'Institute name',
  'institutes.column.shortName':                 'Short name',
  'institutes.column.country':                   'Country',
  'institutes.column.city':                      'City',
  'institutes.column.contactName':               'Contact name',
  'institutes.column.contactEmail':              'Contact email',
  'institutes.column.contactPhone':              'Contact phone',
  'institutes.column.notes':                     'Notes',
  'institutes.column.timeZone':                  'Time Zone',
  'institutes.column.postCode':                  'Post code',
  'institutes.column.region':                    'Region, county',
  'institutes.column.address':                   'Address',
  'institutes.column.lat':                       'Geo latitude',
  'institutes.column.lon':                       'Geo longitude',

  'institutes.error.listLoadError':              'Can not load institutes, please contact an administrator!',
  'institutes.error.deleteError':                'Can not delete this institutes!',
  'institutes.error.addError':                   'Can not add this institutes check your input!',

  'institutes.timeZone.hint':                    'If you change the time zone, the identifiers of the forms belonging to that institution may change!',
  'institutes.timeZone.change':                  'If you change the time zone of the institution, the identifier of the forms associated with the institution will be regenerated and may change depending on the selected time zone! Generating identifiers will begin immediately and may take longer. Are you sure you want to change the time zone of the institution?',

  'patient.confirmation.edit':                   'Are you sure ?<br/>**These data do not get modified in attached forms**',
  'patient.error.saveError':                     'Data save failed',
  'patient.error.loadError':                     'Error loading patient',
  'patient.error.mandatorySelect':               'A patient must be selected for this procedure',
  'patient.confirmation.merge':                  'Patient with these data **already exists**. Do you want to merge the two patients?',
  'patient.success.delete':                      'Patient successfully deleted',

  'patients.column.internalPatientNumber':       'Internal patient number',
  'patients.column.insuranceNumber':             'Insurance number',
  'patients.column.gender':                      'Gender',
  'patients.column.dob':                         'Date of birth',
  'patients.column.name':                        'Name',
  'patients.column.registryPatientNumber':       'Registry patient number',
  'patients.column.country':                     'Country',
  'patients.column.instituteCount':              'Connected institute count',
  'patients.column.attendanceCount':             'Attendance count',
  'patients.column.bioSampleCount':              'Bio sample count',
  'patients.column.createdAt':                   'Recording date',
  'patients.column.registry':                    'Registry',
  'patients.column.institute':                   'Connected institute',


  'patients.header.patientsList':                'Patients',

  'patients.error.listLoadError':                'Cannot load patients, please contact an administrator!',
  'patients.error.deleteError':                  'Cannot delete this patients!',
  'patients.error.addError':                     'Cannot add this patients check your input!',

  'institutePatient.error.attributeMismatch':      'Patient already exists with different data',
  'institutePatient.error.instituteNotFound':      'Institute not found',
  'institutePatient.error.invalidInsuranceNumber': 'Invalid insurance number',
  'institutePatient.error.createError':            'Error creating patient',

  'users.column.name':                           'Name',
  'users.column.email':                          'Email',
  'users.column.activationState':                'Activation state',
  'users.column.password':                       'Password',
  'users.column.passwordConfirmation':           'Password confirmation',
  'users.column.phone':                          'Contact phone',
  'users.column.doctor':                         'Doctor?',
  'users.column.role':                           'Role',
  'users.column.roles':                          'Roles',
  'users.column.institute':                      'Institute',
  'users.column.notes':                          'Notes',
  'users.column.state':                          'State',
  'users.column.country':                        'Country',
  'users.column.city':                           'City',
  'users.column.legacyDoctorCode':               'Legacy doctor code',
  'users.column.userGroup':                      'User groups',
  'users.column.preventAutomaticInactivation':   'Prevent automatic inactivation',
  'users.column.deactivatedUsersNotification':   'Enable deactivated users notification',
  'users.column.inactiveUsersNotification':      'Enable inactive users notification',
  'users.column.registry':                       'Registry',
  'users.column.mfaPolicy':                      'MFA policy',
  'users.column.mfaEnabled':                     'MFA enabled',
  'users.column.mfaEnforcedAt':                  'MFA login enforced from',
  'users.column.mfaConfirmed':                   'MFA confirmed',
  'users.column.displayId':                      'User ID',

  'users.roles.local_administrator':             'Local administrator',
  'users.roles.local_investigator':              'Local investigator',
  'users.roles.monitor_administrator':           'Monitor administrator',
  'users.roles.monitor_investigator':            'Monitor investigator',
  'users.roles.admin':                           'Admin',
  'users.roles.user':                            'User',
  'users.roles.biobank_admin':                   'Biobank Admin',
  'users.roles.active_substance_admin':          'Active Substance Admin',
  'users.roles.data_exporter':                   'Data exporter',
  'users.roles.data_importer':                   'Data importer',
  'users.roles.impersonator':                    'Impersonator',

  'users.state.unconfirmed':                     'unconfirmed',
  'users.state.confirmed':                       'confirmed',
  'users.state.inactive':                        'inactive',
  'users.state.active':                          'active',

  'users.mfaPolicy.enforced':                    'Enforced',
  'users.mfaPolicy.forbidden':                   'Forbidden',
  'users.mfaPolicy.policy':                      'By user or policy',

  'users.updateEmails.newUsers':                 'New emails',
  'users.updateEmails.updatedUsers':             'Updated emails',

  'users.error.listLoadError':                   'Can not load users, please contact an administrator!',
  'users.error.deleteError':                     'Can not delete this users!',
  'users.error.addError':                        'Can not add this institutes check your input!',
  'users.error.impersonationError':              'Error occured during impersonation initiation!',
  'users.error.impersonationExitError':          'Error occured while exiting impersonation!',
  'users.error.emailListUpdateError':            'Can not update user email list!',

  'userForm.legend.basicData':                   'Basic data',
  'userForm.legend.account':                     'Account',
  'userForm.legend.mfa':                         'Multi-factor authentication',
  'userForm.legend.inactivation':                'Inactivation',

  'permissions.filter':                          'Filter',
  'permissions.add':                             'Add permission',
  'permissions.reload':                          'Reset',
  'permissions.refresh':                         'Refresh',
  'permissions.newPermission':                   'New Permission',
  'permissions.permissionScheme':                'Permission schemes',

  'permission.scheme.uploader':                       'Local administrator',
  'permission.scheme.physician':                      'Local investigator',
  'permission.scheme.leadPhysician':                  'Monitor investigator',
  'permission.scheme.cooperativeLocalAdministrator':  'Cooperative local administrator',
  'permission.scheme.cooperativeLocalInvestigator':   'Cooperative local investigator',
  'permission.scheme.monitorAdministrator':           'Monitor administrator',
  'permission.scheme.cooperativeMonitorInvestigator': 'Cooperative monitor investigator ',
  'permission.scheme.guest':                          'Guest',

  'permissions.column.registry':                 'Registry',
  'permissions.column.formType':                 'Form type',
  'permissions.column.institute':                'Institute',
  'permissions.column.physician':                'Physician',
  'permissions.column.userGroup':                'User Group',
  'permissions.column.permission':               'Permission',
  'permissions.column.adminPermissions':         'Biobank admin permissions',
  'permissions.column.institutePermissions':     'Institute permissions',
  'permissions.column.attachPermission':         'Attach sample permission',
  'permissions.column.trusteeName':              'User name',
  'permissions.column.trustee':                  'User/Group',
  'permissions.column.createdAt':                'Created at',

  'permission.hint.registry':                   'If you do not select a register, the permission applies to all registers (*).',
  'permission.hint.formType':                   'If you do not select a form type, the permission applies to all form types (*).',
  'permission.hint.institute':                  'If you do not select an institution, the permit applies to all institutions (*).',
  'permission.hint.physician':                  'If you do not select an physician, the permit applies to all physicians (*).',

  'read_admin_bio_sample.tooltip':              'Read biobank admin bio sample',
  'create_admin_bio_sample.tooltip':            'Create biobank admin bio sample',
  'update_admin_bio_sample.tooltip':            'Update biobank admin bio sample',
  'delete_admin_bio_sample.tooltip':            'Delete biobank admin bio sample',

  'read_institute_bio_sample.tooltip':          'Read institute bio sample',
  'create_institute_bio_sample.tooltip':        'Create institute bio sample',
  'update_institute_bio_sample.tooltip':        'Update institute bio sample',
  'delete_institute_bio_sample.tooltip':        'Delete admin bio sample',
  'attach_sample_to_form.tooltip':              'Attach sample',

  'permissions.error.saveError':                 'Permission save failed',
  'permissions.error.loadError':                 'Error loading data',

  'rejectFieldModal.title':                      '{fieldName} - Correction request Field',
  'rejectFieldModal.label.rejectReason':         'Correction request reason',
  'rejectFieldModal.button.reject':              'Correction request',
  'rejectFieldModal.button.revoke':              'Revoke',
  'rejectFieldModal.button.modifyReason':        'Modify reason',

  'correctNoteModal.title':                      '{fieldName} - Correct Note',
  'correctNoteModal.label.correctNote':          'Note',
  'correctNoteModal.button.save':                'Save',
  'correctNoteModal.button.delete':              'Delete',
  'correctNoteModal.button.modifyNote':          'Modify note',

  'bioSample.sampleNumber':                      'Code',
  'bioSample.bioSampleType':                     'Type',
  'bioSample.registryShortName':                 'Register',
  'bioSample.institute':                         'Institute',
  'bioSample.sampleCollectedAt':                 'Collection date',
  'bioSample.sampleCollectedAtStartDate':        'Collection date',
  'bioSample.sampleCollectedAtEndDate':          'Collection date',
  'bioSample.legacySampleNumber':                'Legacy code',
  'bioSample.instituteBioSample.title':          'Institute bio samples',
  'bioSample.newBioSample':                      'Add new bio sample',
  'bioSample.notes':                             'Notes',
  'bioSample.sampleParameters':                  'Sample parameters',
  'bioSample.title.show':                        'Inspect bio sample',
  'bioSample.title.edit':                        'Edit bio sample',
  'bioSample.patientOtherSamples':               "Patient's bio samples",
  'bioSample.forms':                             'Bio sample connected forms',
  'bioSample.arrivedToBiobankAt':                'Accepted in biobank',
  'bioSample.add.success':                       'Linking was successful!',
  'bioSample.add.error':                         'There was an error in the linking process!',
  'bioSample.addBioSample':                      'Link bio samples',
  'bioSample.addLocalization':                   'Add storage',
  'bioSample.removeLocalization':                'Remove storage',
  'bioSample.localizationInfo':                  'Localization information',
  'bioSample.title':                             'Bio samples',
  'bioSample.localization':                      'Localization',
  'bioSample.fridge':                            'Fridge',
  'bioSample.compartment':                       'Compartment',
  'bioSample.box':                               'Box',
  'bioSample.anySampleInTheBank':                'Sample in biobank?',
  'bioSample.has':                               'Yes',
  'bioSample.none':                              'No',
  'bioSample.anything':                          'No data',
  'bioSample.amount':                            'Amount',
  'bioSample.ancestorSample':                    'Ancestor Sample',
  'bioSample.addDispatch':                       'Add new dispatch information',
  'bioSample.dispatchInfo':                      'Dispatch information',
  'bioSample.removeDispatch':                    'Remove dispatch',
  'bioSample.dispatchedAt':                      'Dispatched at',
  'bioSample.dispatchedTo':                      'Dispatched to',
  'bioSample.editBioSample':                     'Edit bio sample',
  'bioSample.unlink':                            'Detach sample',
  'bioSamples.samples':                          'Bio samples',
  'bioSample.detach':                            'Do you want to detach the **{sampleNumber}** sample?',
  'bioSample.approval':                          'Approval',
  'bioSample.newVirtualBioSample':               'Add new sample based on a virtual sample',
  'bioSample.virtualSampleAncestorType':         'Virtual sample type',
  'bioSample.virtalSampleCollectedAtStartDate':  'Virtual sample collected at',
  'bioSample.newDescendantBioSample':            'Add new descendant sample',
  'bioSample.virtualBiosample':                  'Virtual bio sample',
  'bioSample.ancestorSampleType':                'Ancestor sample type',
  'bioSample.ancestorCollectedAt':               'Ancestor sample collected at',
  'bioSample.adminLink':                         'Admin screen',

  'bioSample.button.submitAndNext':              'Send and create new sample',

  'bioSample.error.deleteError':                 'Can not delete this bio sample!',
  'bioSample.new.success':                       '{sampleNumber} successfuly created!',

  'bioSampleApprovalState.collected':            'Sample collected',
  'bioSampleApprovalState.attached':             'Sample attached to form',
  'bioSampleApprovalState.arrived':              'Arrived to biobank',
  'bioSampleApprovalState.localized':            'Localized',

  'ancestorSelector.title.selectSample':         'Ancestor sample filter',
  'ancestorSelector.button.selectSample':        'Link ancestor sample',

  'correctFormConfirmationModal.title':          'Correct confirmation',
  'rejectFormConfirmationModal.title':           'Reject confirmation',
  'approveFormConfirmationModal.title':          'Approve confirmation',
  'approveFormConfirmationModal.body':           'Are you sure everything is correct?',

  'attendance.title':                            'Attendance forms list',
  'attendance.inpatient-day':                    'Inpatient day',
  'attendance.forms':                            'forms',
  'attendance.formId':                           'Form id',
  'attendance.state':                            'State',
  'attendance.approvals':                        'Approval state',
  'attendance.physician':                        'Physician',
  'attendance.formDate':                         'Form date',
  'attendance.formCreatedAt':                    'Recording date',
  'attendance.startDateTime':                    'Attendance start',
  'attendance.endDateTime':                      'Attendance end',
  'attendance.newForm':                          'New form',

  'attendancePeriod.title':                      '"{name}" form',

  'attendance.error.loadError':                  'Error loading attendance',
  'attendance.error.notFoundError':              'Attendance not found',

  'attendance.forms.nextInpatientDay':           'next inpatient day',

  'topMenu.mainData':                            'Main data',
  'topMenu.data':                                'Data',
  'topMenu.dashboard':                           'Dashboard',
  'topMenu.forms':                               'Forms',
  'topMenu.admin':                               'Admin',
  'topMenu.signOut':                             'Sign Out',
  'topMenu.institutes':                          'Institutes',
  'topMenu.patients':                            'Patients',
  'topMenu.users':                               'Users',
  'topMenu.backToApp':                           'Back to application',
  'topMenu.adminDashboard':                      'Dashboard',
  'topMenu.registryPermissions':                 'Registry permissions',
  'topMenu.bioBankPermissions':                  'Biobank permissions',
  'topMenu.centralBioBank':                      'Central biobank',
  'topMenu.passwordChange':                      'Change password',
  'topMenu.profileSettings':                     'Profile settings',
  'topMenu.statistic':                           'Statistics',
  'topMenu.permissions':                         'Permissions',
  'topMenu.dataExport':                          'Data export',
  'topMenu.dataImport':                          'Data import',
  'topMenu.userGroups':                          'Groups',
  'topMenu.registries':                          'Registries',
  'topMenu.applicationLog':                      'Application log',
  'topMenu.system':                              'System',

  'sidebar.forms.newForm':                       'New form',
  'sidebar.forms.listForms':                     'List forms',
  'sidebar.forms.sectionList':                   'Sections list',

  'sidebar.admin.institutes':                    'Institutes',
  'sidebar.admin.newInstitute':                  'New institute',
  'sidebar.admin.listInstitutes':                'List institutes',

  'sidebar.admin.users':                         'Users',
  'sidebar.admin.newUser':                       'New user',
  'sidebar.admin.listUsers':                     'List users',

  'sidebar.admin.patients':                      'Patients',
  'sidebar.admin.newPatient':                    'New patient',
  'sidebar.admin.listPatients':                  'List patients',
  'sidebar.admin.editPatient':                   'Edit patient',

  'sidebar.admin.registryPermissions':           'Registry permissions',
  'sidebar.admin.newRegistryPermission':         'New registry permission',
  'sidebar.admin.listRegistryPermissions':       'List registry permissions',
  'sidebar.admin.listBiobankPermissions':        'List biobank permissions',
  'sidebar.admin.editBiobankPermission':         'Edit biobank permission',
  'sidebar.admin.newBiobankPermission':          'Add biobank permission',
  'sidebar.admin.newUserGroup':                  'Add group',
  'sidebar.admin.editUserGroup':                 'Edit group',

  'sidebar.admin.updateEmailList':               'Update email list',
  'sidebar.admin.syncingEmails':                 'Synchronizing email list',

  'sidebar.admin.newDataImportJob':              'New Data Import Job',
  'sidebar.admin.listDataImportJobs':            'List Data Import Jobs',

  'zeroSection.label.institute':                 'Institute',
  'zeroSection.label.patient':                   'Patient',
  'zeroSection.label.physician':                 'Physician',

  'patientSelector.title.selectPatient':         'Select patient',

  'patientSelector.button.selectPatient':        'Select highlighted patient',
  'patientSelector.button.newPatient':           'Add new patient',

  'newPatientPane.title.addNewPatient':          'Add New Patient',

  'dashboard.accessibleRegistries.noRegistry':        'No registries available!',
  'dashboard.attendanceStart.noAttendanceStart':      'No attendances available!',
  'dashboard.instituteBioSample.noSelectedInstitute': 'No institute selected!',

  'dashboard.cardTitle.attendanceList':          'Attendance list',
  'dashboard.cardTitle.attendanceStart':         'Start attendance',
  'dashboard.cardTitle.todoList':                'Todo list',
  'dashboard.cardTitle.instituteBioSamples':     'Institute biobank',

  'dashboard.generalFormList.approvableFormsLink':  'Approvable forms',
  'dashboard.generalFormList.correctableFormsLink': 'Correctable forms',
  'dashboard.generalFormList.nothingToDo':          'Nothing to do',

  'newPermissionForm.description.conditions':    'When these conditions are met',
  'newPermissionForm.description.permission':    'Grant these permissions',
  'newPermissionForm.description.userGroup':     'To this user',
  'newPermissionForm.description.trustee':       'To this user/group',

  'permission.label.reg_access':                 'Registry access',
  'permission.label.frm_create':                 'Create form',
  'permission.label.frm_read':                   'View form',
  'permission.label.frm_edit':                   'Edit form',
  'permission.label.frm_delete':                 'Delete form',
  'permission.label.frm_destroy':                'Unconditional form delete',
  'permission.label.att_disqualify':             'Disqualify attendance',
  'permission.label.frm_approve_1':              '1st level form approval',
  'permission.label.frm_approve_2':              '2nd level form approval',
  'permission.label.frm_approve_3':              '3rd level form approval',
  'permission.label.frm_approve_4':              '4th level form approval',
  'permission.label.personal_data_access':       'Personal data access',
  'permission.label.frm_impl_read':              'View form (impl)',
  'permission.label.frm_impl_edit':              'Edit form (impl)',
  'permission.label.frm_impl_delete':            'Delete form (impl)',
  'permission.label.frm_impl_approve_1':         '1st level form approval (impl)',
  'permission.label.frm_impl_approve_2':         '2nd level form approval (impl)',
  'permission.label.frm_impl_approve_3':         '3rd level form approval (impl)',
  'permission.label.frm_impl_approve_4':         '4th level form approval (impl)',
  'permission.label.implicit_access':            'Implicit access',
  'permission.label.frm_reset_approval':         'Reset approval',
  'permission.label.bio_manage':                 'Manage biological samples',
  'permission.label.read_institute_bio_sample':  'Institute sample view',
  'permission.label.create_institute_bio_sample':  'Institute sample create',
  'permission.label.update_institute_bio_sample':  'Institute sample edit',
  'permission.label.delete_institute_bio_sample':  'Institute sample delete',
  'permission.label.create_admin_bio_sample':    'Biobank admin sample create',
  'permission.label.read_admin_bio_sample':      'Biobank admin sample view',
  'permission.label.update_admin_bio_sample':    'Biobank admin sample edit',
  'permission.label.delete_admin_bio_sample':    'Biobank admin sample delete',
  'permission.label.attach_bio_sample_to_form':  'Biosample attach',
  'permission.label.short.read_institute_bio_sample':  'Sample view',
  'permission.label.short.create_institute_bio_sample':  'Sample create',
  'permission.label.short.update_institute_bio_sample':  'Sample edit',
  'permission.label.short.delete_institute_bio_sample':  'Sample delete',
  'permission.label.short.create_admin_bio_sample':    'Sample create',
  'permission.label.short.read_admin_bio_sample':      'Sample view',
  'permission.label.short.update_admin_bio_sample':    'Sample edit',
  'permission.label.short.delete_admin_bio_sample':    'Sample delete',

  'statistic.title':                             'Statistics',
  'statistic.downloadRegistryUploadsLabel':      'Registry attendance count',
  'statistic.downloadRegistryUploadsButton':     'Download registry attendance count .xlsx',
  'statistic.downloadFollowUpLabel':             'Follow up',
  'statistic.downloadFollowUpButton':            'Download Follow up .xlsx',
  'statistic.downloadRejectionCountLabel':       'Rejection count',
  'statistic.downloadRejectionCountButton':      'Download Rejection count .xlsx',
  'statistic.downloadUserActivityLabel':         'User activity',
  'statistic.downloadUserActivityButton':        'Download User activity .xlsx',


  'VALIDATION_ERROR':                            'Validation Error',

  'api.invalidTimeZone':                         'invalid time zone',
  'api.unique':                                  'already exists',
  'api.match':                                   'does not match',
  'api.recaptcha':                               'captcha not set',

  'string.regex.base':                           'invalid format',
  'string.notComplexEnough':                     'password not complex enough',
  'string.dateRange':                            '"{first}" date have to be earlier than "{second}" date',
  'string.base64':                               'not a base64 string',
  'string.base':                                 'not a string',
  'string.creditCard':                           'not a credit card number',
  'string.dataUri':                              'not a data URI',
  'string.domain':                               'not a valid domain name',
  'string.email':                                'not a valid email address',
  'string.empty':                                'is empty',
  'string.guid':                                 'not a valid GUID',
  'string.hex':                                  'not a valid hexadecimal string',
  'string.hostname':                             'not a valid hostname',
  'string.ipVersion':                            'not a valid IP address',
  'string.ip':                                   'not a valid IP address',
  'string.isoData':                              'not a valid ISO date',
  'string.isoDuration':                          'not a valid ISO duration',
  'string.length':                               'is not {limit} length',
  'string.lowercase':                            'must be lowercase',
  'string.max':                                  'is longer than {limit}',
  'string.min':                                  'is shorter than {limit}',
  'string.pattern.base':                         'does not match pattern {regex}',
  'string.pattern.name':                         'does not match pattern {name}',
  'string.token':                                'can only contain a-z, A-Z, 0-9 and underscore _',
  'string.trim':                                 'contains whitespace around it',
  'string.uppercase':                            'must be uppercase',
  'string.uri':                                  'must be a valid URL',
  'string.uriCustomScheme':                      'must be a valid URL with scheme {scheme}://',
  'string.uriRelativeOnly':                      'must be a valid relative URL',

  'array.base':                                  'not an array',
  'array.length':                                'must be {limit} length',
  'array.max':                                   'must have at most {limit} element',
  'array.min':                                   'must have at least {limit} element',
  'array.unique':                                'must not have duplicate elements',

  'number':                                      'must be a number',
  'number.base':                                 'not a number',
  'number.greater':                              'must be greater than {limit}',
  'number.infinity':                             'the number is infinite',
  'number.integer':                              'must be an integer',
  'number.less':                                 'must be less than {limit}',
  'number.max':                                  'must be less than {limit}',
  'number.min':                                  'must be greater than {limit}',
  'number.multiple':                             'cannot be divided by {multiple}',
  'number.negative':                             'must be negative',
  'number.port':                                 'must be between 0 and 65535',
  'number.positive':                             'must be positive',
  'number.precision':                            'must have precision of {limit}',
  'number.unsafe':                               'unsafe, non-representable number',

  'any.required':                                'required',
  'any.unknown':                                 'present value not expected',
  'any.only':                                    'invalid value: {valids}',

  'date.base':                                   'must be a date',
  'date.format':                                 'must be in format of {format}',
  'date.greater':                                'must be before {limit}',
  'date.less':                                   'must be after {limit}',
  'date.max':                                    'must be not after {limit}',
  'date.min':                                    'must be not before {limit}',
  'date.strict':                                 'not a date',

  'object.unknown':                              'property {child} not expected',
  'object.base':                                 'not an object',
  'object.length':                               'more than {limit} keys present',
  'object.max':                                  'must be less than {limit} property',
  'object.min':                                  'must be more than {limit} property',
  'object.with':                                 'property "{peer}" must be present when "{main}" is present',
  'object.without':                              'property "{peer}" must not be present when "{main}" is present',

  'defaultError':                                'invalid value',

  'logic.with':                                  'must provide "{peer}" when "{main}" is present',

  'formErrorsPane.title.formErrors':             'Form field errors',
  'formErrorsPane.title.formWarnings':           'Form field warnings',
  'formErrorsPane.column.field':                  'Field',
  'formErrorsPane.column.errorMessage':          'Error',
  'formErrorsPane.legend':                       'Please review form errors and click the Save button to store form changes. To continue editing this form without saving it please click the Cancel button! Fields displayed in bold font are mandatory even when saving draft form! The Save button stays inactive until all fields displayed in bold are not filled in!',
  'formErrorsPane.warningLegend':                'Fields displayed here are warnings you should check these fields again!',
  'formErrorsPane.sectionHeader':                '{section}. section',

  'error.noAccess':                              "You haven't got permission to view this resource",

  'formSectionListPane.title':                   'Form sections',

  'dataGrid.rowSelected':                        '{count} selected',
  'dataGrid.deleteSelection':                    'Delete selection',
  'dataGrid.selectPerPage':                      'Page: ',
  'dataGrid.noData':                             'No data available!',
  'dataGrid.loadError':                          'Error loading data!',
  'dataGrid.search.placeholder':                 'Search...',
  'dataGrid.search.filterTooltip':               'Filter',
  'dataGrid.search.resetTooltip':                'Reset',

  'form.hint':                                   'The operations work on form level. Institute time zone: {timeZone}.',
  'navigation.prompt':                           'Are you sure? If you navigate all unsaved progress will lost!',

  'userDashboard.institute':                     'Institute: ',
  'userDashboard.registryAclMissing':            'You do not have the corresponding permissions!',

  'searchableSelectList.selectValue':            'Select a value',

  'dataExport.fileName':                         'Excel file name',
  'dataExport.locale':                           'Data-export language',
  'dataExport.registries':                       'Registries',
  'dataExport.institutes':                       'Intitutes',
  'dataExport.formTypes':                        'Form types',
  'dataExport.minApprovals':                     'Minimum approval level',
  'dataExport.components':                       'Field type (component)',
  'dataExport.formId':                           'Form id (UUID)?',
  'dataExport.rawFormValues':                    'Raw form data?',
  'dataExport.instituteName':                    'Instiute name',
  'dataExport.instituteCity':                    'Instiute city',
  'dataExport.patientDob':                       'Patient date of birth',
  'dataExport.patientName':                      'Patient name',
  'dataExport.insuranceNumber':                  'Patient insurance number',
  'dataExport.attendanceCount':                  'Attendance count',
  'dataExport.bioSamples':                       'Bio samples bound to form',
  'dataExport.bioSamplesLegacyDisplayId':        'Bio samples legacy identifier',
  'dataExport.fields':                           'Only inlude thse fields',
  'dataExport.fieldsLabel':                      'Show fields with labels instead of identifiers when chosing filter',
  'dataExport.fields.hint':                      'You have to seperate field ids with comma!',
  'dataExport.fieldCaseSensitivity':             'Enable case sensitivity on field filter',
  'dataExport.groupBy':                          'Excel sheets grouped by',
  'dataExport.legacyDisplayId':                  'Legacy display ids',
  'dataExport.randomGroup':                      'Random groups',
  'dataExport.password':                         'Password of Excel file',
  'dataExport.registry':                         'Register',
  'dataExport.institute':                        'Institute',
  'dataExport.formType':                         'Form type',
  'dataExport.success':                          'You started the data query succesfully! We will send the .xls to your email address!',
  'dataExport.approvals':                        'Form approval levels',
  'dataExport.formState':                        'Form state',
  'dataExport.enableFormData':                   'Form data',
  'dataExport.complexFlatten':                   'Complex fields',
  'dataExport.divideArrayFields':                'Use complex flattening logic on array fields',
  'dataExport.sortArrayFieldsInComplex':         'Place array fields next to eachother?',
  'dataExport.renderType':                       'Data-export type',
  'dataExport.form':                             'Render by form type (row-based)',
  'dataExport.attendance':                       'Render by attendance (column-based)',
  'dataExport.dayPerSheet':                      'Maximum number of days on an Excel sheet',
  'dataExport.maxColumns':                       'Maximum number of columns on an Excel sheet',
  'dataExport.maxDays':                          'Maximum number of attendance days',
  'dataExport.attendanceApprovals':              'Minumum attendance level of form',
  'dataExport.groupByFields':                    'Group by field id instead of attendance days',
  'dataExport.approvalLevels':                   'All approval level of forms in an attendance',
  'dataExport.arraySeparator':                   'Array separator of multi-input fields',
  'dataExport.defaultFormOrder':                 'Order id by form order',
  'dataExport.formTypeAttendance':               'Group attendances by form types',
  'dataExport.patientData':                      'Personal data',
  'dataExport.orderingType':                     'Ordering of fileds',
  'dataExport.whichDataToShow':                  'Data to be shown',
  'dataExport.source':                           'Source and goal',
  'dataExport.formOrder':                        'Ordering by form order',
  'dataExport.alpabeticalOrder':                 'Alphabetical order',
  'dataExport.complex.flatten':                  'Complex fields seperated into multiple cells',
  'dataExport.complex.notFlatten':               'Complex fileds in a single cell (JSON)',
  'dataExport.filledFormNumber':                 'Group by filled form count',
  'dataExport.attendanceExport':                 'Parameters of data export by form',
  'dataExport.formExport':                       'Parameters of data export by attendance',
  'dataExport.fileUpload':                       'Generate root keys from file: ',
  'dataExport.fileUploadText':                   'Upload header file',
  'dataExport.fieldSorting':                     'Sort fields by field filter',
  'dataExport.showRegistries':                   'Show register of fields',
  'dataExport.fieldType':                        'Type of field filtering',
  'dataExport.fieldType.text':                   'Text area (comma separated)',
  'dataExport.fieldType.select':                 'Select list',

  'dataImportForm.legend.dataImportJob':         'Data Import Job',
  'dataImportForm.column.name':                  'Name',
  'dataImportForm.column.csvFile':               'File (.csv)',
  'dataImportForm.column.fileUploadText':        'Upload .csv file',

  'userGroups.column.name':                      'Name',
  'userGroups.column.groupType':                 'Group type',
  'userGroups.column.members':                   'Members',
  'userGroups.newButton':                        'New group',
  'userGroups.showUserGroup':                    'Show group',

  'registry.column.nameEn':                      'Name (en)',
  'registry.column.nameHu':                      'Name (hu)',
  'registry.column.shortName':                   'Short Name',
  'registry.column.bioSampleCode':               'Bio Sample Code',
  'registry.column.hasInpatientDay':             'Has Inpatient Day?',
  'registry.column.mandatoryInsuranceNumber':    'Mandatory Insurance Number?',
  'registry.column.registryType':                'Registry Type',
  'registry.column.createdAt':                   'Created at',
  'registry.column.updatedAt':                   'Updated at',
  'registry.column.registryGroupEn':             'Registry group (en)',
  'registry.column.registryGroupHu':             'Registry group (hu)',
  'registry.column.dataCollectionPeriod':        'Data collection period',
  'registry.column.registryManager.name':        'Registry manager name',
  'registry.column.registryManager.institute':   'Registry manager institute name',
  'registry.column.registryManager':             'Registry manager',
  'registry.column.trialManager.institute':      'Trial manager institute name',
  'registry.column.trialManager.name':           'Trial manager name',
  'registry.column.trialManager':                'Trial manager',
  'registry.column.targetPatientCount':          'Target patient count',
  'registry.column.formTypes':                   'Form types',
  'registry.column.formType.displayId':          'Display id',
  'registry.column.formType.nameEn':             'Name (en)',
  'registry.column.formType.nameHu':             'Name (hu)',
  'registry.column.formType.shortName':          'Short name',
  'registry.column.formType.behaviorType':       'Behavior type',
  'registry.column.data.registry':               'registry',
  'registry.column.data.trial':                  'trial',

  'registry.formType.displayId':                 'Display id',
  'registry.formType.locales':                   'Locales',
  'registry.formType.name':                      'Name',
  'registry.formType.shortName':                 'Short name',
  'registry.formType.behaviorType':              'Behavior type',
  'registry.formType.startDatetimeFieldId':      'Attendance start datetime field id',
  'registry.formType.endDatetimeFieldId':        'Attendance end datetime field id',
  'registry.formType.patientNameFieldId':        'Patient name field id',
  'registry.formType.lastVersion':               'Last version',
  'registry.formType.createdAt':                 'Created at',
  'registry.formType.updatedAt':                 'Updated at',
  'registry.formType.externalFields':            'External Fields',
  'registry.formType.externalFields.displayId':  'Form type display id',
  'registry.formType.externalFields.id':         'Form type id',
  'registry.formType.externalFields.fields':     'Importable fields',

  'registry.externalFields.sourceDisplayId':     'Source form display id',
  'registry.externalFields.sourceField':         'Source field id',
  'registry.externalFields.targetField':         'Target field id',

  'registry.box.title':                          'Registry information',
  'registry.box.trialCount':                     'Number of trials',
  'registry.box.bioSampleCount':                 'Bio sample count',
  'registry.box.registryCount':                  'Number of registers',
  'registry.box.patientCount':                   'Number of patients',
  'registry.box.instituteCount':                 'Number of institutes',
  'registry.box.countryCount':                   'Number of countries',

  'registry.randomGroupVisibility.available':          'Available',
  'registry.randomGroupVisibility.reportOnly':         'In report only',
  'registry.randomGroupVisibility.hidden':             'Hidden',

  'registry.behaviorType.attendanceStart':       'Attendance start',
  'registry.behaviorType.any':                   'Any',


  'registry.nameEn':                             'English name',
  'registry.nameHu':                             'Hungarian name',
  'registry.shortName':                          'Short name',
  'registry.attendanceCount':                    'Attendance count',
  'registry.formCount':                          'Form count',
  'registry.instituteCount':                     'Institute count',
  'registry.patientCount':                       'Patient count',
  'registry.bioSampleCode':                      'Bio sample code',
  'registry.hasInpatientDay':                    'Has inpatient day?',
  'registry.mandatoryInsuranceNumber':           'Mandatory insurance number?',
  'registry.registryType':                       'Registry type',
  'registry.createdAt':                          'Created at',
  'registry.updatedAt':                          'Updated at',
  'registry.registryGroupEn':                    'English registry group',
  'registry.registryGroupHu':                    'Hungarian registry group',
  'registry.dataCollectionPeriod':               'Data collection period',
  'registry.registryManager.name':               `Registry manager's name`,
  'registry.registryManager.institute':          `Registry manager's institute`,
  'registry.registryManager':                    `Registry manager`,
  'registry.trialManager.institute':             `Trial manager's institute`,
  'registry.trialManager.name':                  `Trial manager's name`,
  'registry.trialManager':                       'Trial manager',
  'registry.attendancePeriodDispNameEn':         'English attendance display name',
  'registry.attendancePeriodDispNameHu':         'Hungarian attendance display name',
  'registry.randomGroupBlockMultipliers':        'Multipliers of randomized block size',
  'registry.randomGroupVisibility':              'Random group visibility',
  'registry.randomGroups':                       'Random groups',
  'registry.randomizationStrategy':              'Randomization strategy',
  'registry.randomizationStrategy.basic':        'Default',
  'registry.randomizationStrategy.permuted_block': 'Permuted block',
  'registry.targetPatientCount':                 'Target patient count',
  'registry.formTypes':                          'Form types',

  'registry.dashboard.title':                    'Registries',
  'registry.dashboard.countTrial':               'Trials:',
  'registry.dashboard.countRegistries':          'Registries:',

  'registry.error.listLoadError':                'Can not load registries, please contact an administrator!',
  'registry.formTypes.error.loadError':          'Can not load form types, please contact an administrator!',
  'registry.error.loadError':                    'Can not load registry, please contact an administrator!',

  'mailchimp.error.updateError':                 'Error while updating Mailchimp email list',

  'applicationLog.column.id':                    'Id',
  'applicationLog.column.createdAt':             'Created at',
  'applicationLog.column.reqId':                 'Request Id',
  'applicationLog.column.msg':                   'System message',
  'applicationLog.column.httpMethod':            'HTTP method',
  'applicationLog.column.url':                   'URL',
  'applicationLog.column.userAgent':             'User Agent',
  'applicationLog.column.referer':               'Referer',
  'applicationLog.column.remoteAddress':         'Remote address',
  'applicationLog.column.httpResponseCode':      'Status',
  'applicationLog.column.responseTime':          'Time (ms)',
  'applicationLog.column.data':                  'Data',
  'applicationLog.column.event':                 'Event',
  'applicationLog.column.message':               'Message',
  'applicationLog.column.reason':                'Reason',
  'applicationLog.column.severity':              'Severity',
  'applicationLog.column.targetId':              'Target id',
  'applicationLog.column.targetName':            'Target name',
  'applicationLog.column.targetType':            'Target type',
  'applicationLog.column.userId':                'User id',
  'applicationLog.column.userName':              'User name',
  'applicationLog.column.rawLog':                'Original entry',
  'applicationLog.form.from':                    'From',
  'applicationLog.form.search':                  'Search',
  'applicationLog.form.to':                      'To',
  'applicationLog.form.raw':                     'Raw logs',

  'errorReport.title':                           "It looks like we're having issues.",
  'errorReport.subtitle':                        'Our team has been notified.',
  'errorReport.subtitle2':                       "If you'd like to help, tell us what happened below.",
  'errorReport.labelName':                       'Name',
  'errorReport.labelEmail':                      'Email',
  'errorReport.labelComments':                   'What happened?',
  'errorReport.labelClose':                      'Close',
  'errorReport.labelSubmit':                     'Submit Crash Report',
  'errorReport.successMessage':                  'Your feedback has been sent. Thank you!',

  'errorFallback.title':                         "It looks like we're having issues.",
  'errorFallback.description':                   'Our team has been notified.! Please reload the page. If the error is persist you can contact us just click on the email icon in the right rop corner.',

  'dataImport.column.id':                        'Id',
  'dataImport.column.name':                      'Name',
  'dataImport.column.success':                   'Success',
  'dataImport.column.initiatedByName':           'Initiated by',
  'dataImport.column.createdAt':                 'Created At',
  'dataImport.column.results':                   'Results',
  'dataImport.column.errors':                    'Errors',
  'dataImport.column.fileName':                  'File name',

  'dataImport.list.button.removeFailedJobs':     'Remove failed jobs',
  'dataImport.succeededBadge':                   'Succeeded',
  'dataImport.failedBadge':                      'Failed',

}

const en = {
  locale: 'en',
  messages,
}

export default en
