import React from 'react'
import LabelFieldWrapper from '../../components/LabelFieldWrapper'

export const ecrFormField =
  (WrappedComponent, props) =>
    ({label, touched, error, required, ...rest}) => (
      <LabelFieldWrapper
        label={label}
        error={touched && error}
        required={required}
        >
        <WrappedComponent
          {...props}
          {...rest}
          />
      </LabelFieldWrapper>
    )
