import axiosInstance from './axios_instance'
import {
  CommonCollectionQueryParameters,
  sanitizeCommonCollectionQueryParameters,
} from '../../lib/common_query_parameters'

export async function getUserGroups(options: { includeMembers: boolean  } & CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/user-groups`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options),
        ...(options.includeMembers ? { include: 'members' } : {})
      }
    })
  return resp.data
}

export async function getUserGroup(id) {
  const resp = await axiosInstance.get().get(`/api/v1/user-groups/${id}`)
  return resp.data.results[0]
}

export async function postUserGroup(payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/user-groups`, payload)
  return resp.data.results[0]
}

export async function putUserGroup(id, payload) {
  const resp = await axiosInstance.get()
    .put(`/api/v1/user-groups/${id}`, payload)
  return resp.data.results[0]
}

export async function patchUserGroup(id, payload) {
  const resp = await axiosInstance.get()
    .patch(`/api/v1/user-groups/${id}`, payload)
  return resp.data.results[0]
}

export async function deleteUserGroup(id) {
  await axiosInstance.get()
    .delete(`/api/v1/user-groups/${id}`)
}
