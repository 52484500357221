const messages = {
  'actions':                                     'Műveletek',
  'male':                                        'Férfi',
  'female':                                      'Nő',
  'yes':                                         'Igen',
  'no':                                          'Nem',
  'active':                                      'Aktív',
  'inactive':                                    'Inaktív',
  'search':                                      'Keresés',
  'nodata':                                      'Nincs adat',
  'noDataDisplay':                               "<Nincs adat>",
  'virtual':                                     'virtuális',
  'today':                                       'Ma',
  'now':                                         'Most',
  'noItemFound':                                 'Nincs találat!',
  'loading':                                     'Betöltés',

  'read':                                        'Űrlap megtekintése',
  'new':                                         'Új űrlap',
  'edit':                                        'Űrlap szerkesztése',
  'inspect':                                     'Űrlap jóváhagyása',
  'correct':                                     'Űrlap javítása',

  'footer.pricaryPolicyLinkText':                'Adatvédelmi tájékoztató',
  'footer.problemReportingLinkText':             'Hiba bejelentése',

  'sponsor.platinum':                            'Platina támogatóink',
  'sponsor.gold':                                'Arany támogatóink',
  'sponsor.silver':                              'Ezüst támogatóink',
  'sponsor.bronze':                              'Bronz támogatóink',

  'fileUploader.addNewFile':                     'Új fájl hozzáadása',
  'fileUploader.dragHere':                       'Húzza ide a fájlokat',
  'fileUploader.error':                          'Nem sikerült feltölteni a fájlt!',
  'fileUploader.defaultHint':                    'A fájlok csak anonimizálva kerülhetnek feltöltésre. Fokozottan figyeljünk a fejlécben, láblécben, címsorokban lévő személyes adatok törlésére, kitakarására is!',

  'saveForm.fileName':                           'Fájl neve',
  'saveForm.password':                           'Jelszó',
  'saveForm.download':                           'Letöltés',
  'saveForm.dialogText':                         'Ezzel a funkcióval letudja tölteni a form tartalmát és áttudja tölteni egy másikban. A form verziónak és típusnak meg kell egyeznie! A mentéshez minden esetben meg kell adnia egy jelszót amivel titkosítva lesz a fájl!',
  'saveForm.error.password':                     'Kötelező megadni a jelszót!',
  'saveForm.error.fileName':                     'Kötelező megadni a fájl nevét!',

  'uploadForm.dialogText':                       'Biztosan vissza tölti?<br/>**Eredeti azonosító:** {displayId}<br/>**Mentette:** {exportedBy}<br/>**Mentés ideje:** {exportedAt}',
  'uploadForm.promptText':                       'Adja meg a titkosításhoz használt jelszót!',
  'uploadForm.upload':                           'Feltöltés',
  'uploadForm.error.missingPassword':            'Nem adott meg jelszót!',
  'uploadForm.error.missingFile':                'Nem töltött fel fájlt!',
  'uploadForm.error.formTypeId':                 'Űrlap típus azonosítók nem egyeznek!',
  'uploadForm.error.formTypeVersion':            'Űrlap típus verzió nem egyezik!',
  'uploadForm.error.passwordNotMatch':           'Nem megfelelő jelszó!',

  'page.title.adminDashboard':                   'Adminisztrációs irányítópult',
  'page.title.userDashboard':                    'Felhasználói irányítópult',
  'page.title.usersList':                        'Felhasználók listája',
  'page.title.userDetails':                      'Felhasználó adatai',
  'page.title.editUser':                         'Felhasználó szerkesztése',
  'page.title.newUser':                          'Új felhasználó',
  'page.title.attendanceList':                   'Ellátási szakaszok listája',
  'page.title.approvableForms':                  'Jóváhagyandó űrlapok',
  'page.title.correctableForms':                 'Javítandó űrlapok',
  'page.title.instituteDetails':                 'Intézmény adatai',
  'page.title.newInstitute':                     'Új intézmény',
  'page.title.editInstitute':                    'Intézmény szerkesztése',
  'page.title.instituteList':                    'Intézmények listája',
  'page.title.patientsList':                     'Betegek listája',
  'page.title.dataExport':                       'Adat lekérdezés',
  'page.title.userGroupList':                    'Csoportok listája',
  'page.title.registries':                       'Regiszterek',
  'page.title.formType':                         'Űrlap típus',
  'page.title.applicationLog':                   'Alkalmazás napló',
  'page.title.newDataImport':                    'Új adat import',
  'page.title.listDataImportJobs':               'Adat importok',
  'page.title.showDataImportJob':                'Adat import',

  'profile.title':                               'Felhasználói profil | {name}',
  'profile.passwordChange':                      'Jelszó módosítás',
  'profile.oldPassword':                         'Régi jelszó',
  'profile.newPassword':                         'Új jelszó',
  'profile.newPasswordConfirmation':             'Új jelszó ismét',
  'profile.newPassword.hint':                    'A jelszónak teljesíteni kell a következő feltételekből 3-mat legalább:\n* legalább 1 nagy betű (A-Z)\n* legalább 1 kisbetű (a-z)\n* legalább 1 szám (0-9)\n* legalább 1 speciális karakter\n* A jelszó legalább 10 karakter hosszú',

  'profileSettingsForm.changeYourPassword':             'Jelszó megváltoztatása',
  'profileSettingsForm.legend.password':                'Jelszó',
  'profileSettingsForm.legend.MFA':                     'Többtényezős hitelesítés',
  'profileSettingsForm.explanation.forbidden':          'A többfaktoros hitelesítést a rendszergazda letiltotta. Ezt a beállítást nem módosíthatja.',
  'profileSettingsForm.explanation.enforced':           'A többfaktoros hitelesítést a rendszergazda kikényszerítette. Ezt a beállítást nem módosíthatja.',
  'profileSettingsForm.recoveryKeys.title':             'A helyreállítási kulcsok regenerálása sikerült!',
  'profileSettingsForm.recoveryKeys.explanation':      `
    <p>
      <b>Mielőtt bezárná ezt a panelt, kérjük, mentse el vagy nyomtassa ki a helyreállítási kulcsokat, mivel később nem fogja tudni megtekinteni ezeket!</b>
    </p>
    <p>
      Ha a hitelesítő alkalmazáshoz való hozzáférést elveszíti, a helyreállítási kulcsokat másodlagos bejelentkezési tényezőként használhatja.Minden helyreállítási kulcs csak egyszer használható! Sikeres bejelentkezés után új helyreállítási kulcsot generálhat.
    </p>`,
  'profileSettingsForm.mfaDisabled.explanation':        'Az MFA jelenleg <b>ki van kapcsolva</b>. Az MFA engedélyezéséhez kattintson az alábbi gombra',
  'profileSettingsForm.mfaDisabled.button.enableMfa':   'MFA engedélyezése',
  'profileSettingsForm.mfaEnabled.explanation':         'Az MFA jelenleg <b>engedélyezve van</b>. Az MFA letiltásához kattintson az alábbi gombra.',
  'profileSettingsForm.mfaEnabled.button.disableMfa':   'MFA kikapcsolása',
  'profileSettingsForm.mfaEnabled.notConfigured':       'Az MFA jelenleg engedélyezett, de nincs konfigurálva. Most beállíthatja, vagy a következő sikeres bejelentkezés után az MFA beállítási folyamat automatikusan elindul.',
  'profileSettingsForm.mfaEnabled.button.configureMfa': 'MFA konfigurálása',
  'profileSettingsForm.recovery.explanation':           'Még {keyCount} db. érvényes helyreállítási kulccsal rendelkezik. A helyreállítási kulcsok regenerálásához adja meg a jelszavát, és kattintson az alábbi gombra:',
  'profileSettingsForm.recovery.placeholder.password':  'Adja meg a jelszavát...',
  'profileSettingsForm.recovery.button.regenerate':     'Helyreállítási kulcsok újragenerálása',

  'editProfileContainer.confirmDisable.message':        'Biztosan letiltja az MFA-t? Ha az igen lehetőséget választja, az MFA-beállítások véglegesen törlődnek. Ha később úgy dönt, hogy újra engedélyezi az MFA-t, újra el kell végeznie az MFA beállítási folyamatát!',
  'editProfileContainer.confirmEnable.message':         'Biztosan engedélyezi az MFA-t? Ha az igent választja, elindul az MFA beállítási folyamata!',
  'editProfileContainer.confirmPanelClose.message':     'Biztosan meg akarja szakítani az MFA beállítási folyamatát? Hacsak nem kapcsolja ki, az MFA továbbra is engedélyezve marad, és a következő sikeres bejelentkezés után be kell állítania!',
  'editProfileContainer.error.regenerateMfaRecoveryKeys': 'Hiba a helyreállítási kulcsok generálása közben',
  'editProfileContainer.error.disableMfa':              'Az MFA letiltása nem sikerült',
  'editProfileContainer.error.invalidPassword':         'Érvénytelen jelszó',
  'editProfileContainer.error.mfaNotConfigured':        'Az MFA nincs még konfigurálva',

  'confirmation.delete':                         'Biztosan törölni akarja?',
  'confirmation.localeChange':                   'Biztosan nyelvet akar váltani?<br/>**Minden elmentetlen adat el fog veszni!**',
  'confirmation.logout':                         'Biztosan ki akar lépni?<br/>**A nem mentett adatok el fognak veszni!**',
  'confirmation.impersonate':                    'Biztosan meg akarja személyesíteni a felhasználót?',
  'confirmation.impersonationExit':              'Biztosan ki akar lépni a megszemélyesítésből?',
  'confirmation.updateEmailList':                'Biztosan frissíteni akarja az email listát?',

  'tooltip.correct':                             'Javítás',
  'tooltip.correctNote':                         'Javítási megjegyzés',
  'tooltip.delete':                              'Törlés',
  'tooltip.edit':                                'Szerkesztés',
  'tooltip.inspect':                             'Ellenőrzés',
  'tooltip.read':                                'Áttekintés',
  'tooltip.reject':                              'Javítás kérése',
  'tooltip.fieldHistory':                        'Mezőtörténet',
  'tooltip.filter':                              'Szűrés',
  'tooltip.fix':                                 'Adminisztratív javítás',
  'tooltip.reset':                               'Helyreállítás',
  'tooltip.acl':                                 'Engedélyek',
  'tooltip.addBioSample':                        'Csatolás',
  'tooltip.detachBioSample':                     'Lecsatolás',
  'tooltip.createDescendantSample':              'Származtatott minta rögzítése',
  'tooltip.jumpToAttendancePeriod':              'Ugrás az ellátásra',
  'tooltip.resetFormApproval':                   'Űrlap visszadobása piszkozatba',
  'tooltip.resetAttendanceApproval':             'Ellátás visszadobása piszkozatba',
  'tooltip.impersonate':                         'Felhasználó megszemélyesítése',

  'action.addNew':                               'Új hozzáadás',

  'attendanceList.id':                           'Ellátás azonosító',
  'attendanceList.inpatient':                    'Rögzített űrlapok száma',
  'attendanceList.completion':                   'Befekvés teljessége',
  'attendanceList.legacyDisplayId':              'Régi azonosító',
  'attendanceList.randomGroup.label':            'Randomizációs csoport',
  'attendanceList.ownerName':                    'Tulajdonos',

  'button.ok':                                   'Ok',
  'button.cancel':                               'Mégsem',
  'button.close':                                'Bezárás',
  'button.back':                                 'Vissza',
  'button.send':                                 'Elküldés',
  'button.save':                                 'Mentés',
  'button.reset':                                'Alaphelyzet',
  'button.update':                               'Módosít',
  'button.more':                                 'Részletes információ',
  'button.moreInfo':                             'Bővebben...',
  'button.impersonate':                          'Megszemélyesít',
  'button.impersonate.exit':                     'Megszemélyesítésből kilépés',

  'gen.name':                                    'Gének',
  'gen.tested':                                  'Tesztek',
  'gen.region':                                  'Régió',
  'gen.result':                                  'Eredmények',
  'gen.duplicate':                               'Már létezik ilyen gén!',
  'gen.emptyField':                              'A gén neve kötelező!',
  'gen.addNewGen':                               'Új gén felvétele',

  'unitConverter.title':                         'Adja meg a konvertálandó értéket.',
  'unitConverter.button':                        'Konvertálás',

  'error.pageNotFound':                          'Az oldal nem található',
  'error.dataLoadError':                         'Hiba az adatok betöltése során',
  'error.default':                               'Úgy látszik egy hibába futottál!',

  'formBackup.backupLoad':                       'Mentés betöltése',
  'formBackup.notSupported':                     "Ez a böngésző nem támogatja az automatikus mentést. Kérjük válts Chrome-ra vagy Firefox-ra ha használni akarod ezt a funkciót!",
  'formBackup.backupAvailable':                  'Elérhető automatikus mentés! A visszatöltéshez válassza ki a visszatölteni kívánt időpontot.',
  'formBackup.backupAvailableShort':             'Elérhető automatikus mentés!',
  'formBackup.selectHint':                       'Az automatikus mentés visszatöltése, felülírja a jelenlegi űrlap állapotot!',

  'form.noValue':                                "<nincs kitöltve>",

  'form.button.form_history':                    'Űrlap történet',
  'form.button.approve':                         'Jóváhagyás',
  'form.button.close':                           'Bezárás',
  'form.button.finalize':                        'Lezárás',
  'form.button.finish_correction':               'Javítás befejezése',
  'form.button.reject':                          'Javítás kérése',
  'form.button.save':                            'Mentés',
  'form.button.save_draft':                      'Piszkozat mentés',
  'form.button.dataImport':                      'Adat áttöltés',

  'form.dataImport.text':                        'Válassza ki mely űrlapról szeretne adatot áttölteni!',
  'form.dataImport.importableData':              'A következő elemek kerülnek áttöltésre:',

  'form.error.loadError':                        'Hiba az űrlap betöltése során',
  'form.error.saveError':                        'Hiba az űrlap mentése során',
  'form.error.deleteError':                      'Hiba az űrlap törlése során',

  'form.success.delete':                         'Az űrlap törlése sikerült',

  'form.validation.error.mandatory':             'kötelező',
  'form.validation.error.invalidFormat':         'érvénytelen formátum',
  'form.validation.error.doNotMatch':            'nem egyezik',
  'form.validation.error.atLeastOneRequired':    'legalább egyet meg kell adni',
  'form.validation.error.atLeastOnRequiredFromTheList': 'legalább egyet ki kell tölteni a listából: {list}',
  'form.validation.error.cannotUseBothApprovalsAndFile': 'nem lehet egyszerre fájlt feltölteni és az ellátásonkénti legkisebb jóváhagyási szintet megjeleníteni',

  'form.confirmation.dirtyClose':                'Biztosan bezárja az űrlapot?<br/>**A nem mentett adatok el fognak veszni!**',
  'form.confirmation.delete':                    'Biztosan törli az űrlapot ({displayId})?<br/>**A törlés végleges, az űrlap nem állítható helyre!**',
  'form.confirmation.delete-a':                  'Biztosan törli az ellátás indító űrlapot ({displayId})?<br/>**Az űrlap törlése a kapcsolódó ellátás és az összes kapcsolódó űrlap törlésével jár. A törlés végleges, az űrlap és az ellátás nem állítható helyre!**',

  'form.censoredMessage':                        "<Nincs engedélye a megtekintéshez>",

  'form.resetAttendanceApproval':                'Ezzel a művelettel az ellátás összes jóváhagyása törlődik! Biztos folytatja?',
  'form.resetFormApproval':                      'Ezzel a művelettel az űrlap összes jóváhagyása törlődik! Biztos folytatja?',

  'fieldHistory.title':                          '{fieldName} - Mezőtörténet',
  'fieldHistory.column.timestamp':               'Időpont',
  'fieldHistory.column.user':                    'Felhasználó',
  'fieldHistory.column.operation':               'Művelet',
  'fieldHistory.column.formState':               'Állapot',
  'fieldHistory.column.fieldValue':              'Mezőérték',
  'fieldHistory.column.notes':                   'Megjegyzések',

  'formHistory.title':                           'Űrlaptörténet',
  'formHistory.column.timestamp':                'Időpont',
  'formHistory.column.user':                     'Felhasználó',
  'formHistory.column.operation':                'Művelet',
  'formHistory.column.formState':                'Állapot',
  'formHistory.column.approvalState':            'Jóváhagyás',

  'formList.deleteConfirmationQuestion':         'Biztosan törölni akarja?',
  'formList.noFormAvailable':                    'Nincs megjeleníthető űrlap',
  'formList.pageTitle':                          'Űrlapok',

  'formList.column.attendanceNumber':            'Űrlap azonosító',
  'formList.column.patientName':                 'Név',
  'formList.column.patientGender':               'Beteg neme',
  'formList.column.patientRace':                 'Rassz',
  'formList.column.patientDateOfBirth':          'Szül. dátum',
  'formList.column.patientInsuranceNumber':      'Bizt. azonosító',
  'formList.column.formState':                   'Állapot',
  'formList.column.approvalState':               'Jóváhagyás',
  'formList.column.operations':                  'Műveletek',

  'formList.error.loadError':                    'Hiba az űrlap lista betöltése során',
  'formList.error.deleteError':                  'Hiba az űrlap törlése során',

  'formState.draft':                             'piszkozat',
  'formState.await_inspect':                     'jóváhagyásra vár',
  'formState.rejected':                          'javításra vár',
  'formState.correct_draft':                     'javítás alatt',
  'formState.corrected':                         'javított',
  'formState.final':                             'kész',

  'formOp.create':                               'létrehozás',
  'formOp.update':                               'módosítás',
  'formOp.approve':                              'jóváhagyás ({inspectLevel}. szint)',
  'formOp.reject':                               'javítás kérés ({inspectLevel}. szint)',
  'formOp.correct':                              'javítás',
  'formOp.approval_reset':                       'jóváhagyás törlés',
  'formOp.system_update':                        'rendszer módosítás',

  'loginFormContainer.mfaSkipSetupRequest.message': 'MFA beállítás kihagyása...',
  'loginFormContainer.error.cannotSkipMfaSetup': 'Az MFA beállítás nem hagyható ki!',

  'loginForm.button.login':                      'Bejelentkezés',
  'loginForm.button.sendPasswordResetLink':      'Jelszó helyreállító link küldése',
  'loginForm.button.forgotPassword':             'Elfelejtette a jelszavát?',
  'loginForm.button.resendEmail':                'Email újra küldése',
  'loginForm.button.changePassword':             'Jelszó megváltoztatása',
  'loginForm.button.backToLogin':                'Vissza a bejelentkezéshez',
  'loginForm.placeholder.email':                 'Az Ön email címe',
  'loginForm.placeholder.password':              'Jelszó',
  'loginForm.placeholder.confirmPassword':       'Jelszó megerősítése',
  'loginForm.error.invalidEmail':                'Érvénytelen e-mail cím',
  'loginForm.error.loginFailed':                 'A bejelentkezés sikertelen',
  'loginForm.error.tokenExpired':                'A munkamenet lejárt',
  'loginForm.error.loginError':                  'A bejelentkezés sikertelen',
  'loginForm.error.forgetPassword':              'Kérés sikertelen! Kérlek próbáld meg később!',
  'loginForm.error.passwordMismatch':            'A két jelszó nem egyezik meg!',
  'loginForm.header.changePassword':             'Adja meg az új jelszavát!',
  'loginForm.header.passwordChanged':            'Sikeres jelszó változtatás',
  'loginForm.header.login':                      'Bejelentkezés',
  'loginForm.header.reqPasswordResetLink':       'Jelszóhelyreállító link igénylése',
  'loginForm.header.emailSent':                  'Jelszóhelyreállító link',
  'loginForm.content.emailSent':                 'A jelszóváltoztatás elvégzéséhez szükséges linket kézbesítjük az Ön által megadott email címre. Kérjük ellenőrizze a postafiókját!',
  'loginForm.content.passwordChanged':           'A jelszavát sikeresen megváltoztattuk! Most már bejelentkezhet az új jelszavával.',

  'mfaError.skipMfaSetup':                       'MFA beállítás kihagyása',
  'mfaError.cancel':                             'Mégsem',

  'mfaForm.button.login':                        'Bejelentkezés',
  'mfaForm.button.backToLogin':                  'Vissza a bejelentkezéshez',
  'mfaForm.link.recoveryTokenRequired':          'MFA helyreállítási kulcs használata',
  'mfaForm.placeholder.mfaToken':                '6 számjegyű token...',
  'mfaForm.error.invalidToken':                  'Érvénytelen token',
  'mfaForm.error.tokenExpired':                  'Munkamenet lejárt',
  'mfaForm.error.loginFailed':                   'Bejelentkezés nem sikerült',
  'mfaForm.header.login':                        'Belépés MFA tokennel',
  'mfaForm.hint':                                'Használja az autentikátor alkalmazást a hatjegyű kód generálásához!',

  'mfaSetupContainer.mfaSetupRequired.message':  'MFA beállítás szükséges',
  'mfaSetupContainer.mfaSetupRequest.message':   'Az MFA beállítás inicializálása',
  'mfaSetupContainer.mfaSetupFailure.title':     'MFA beállítás hiba',
  'mfaSetupContainer.mfaVerifyFailure.message':  'Ellenőrzés sikertelen',
  'mfaSetupContainer.mfaVerifySuccess.title':    'Sikeres MFA token ellenőrzés!',
  'mfaSetupContainer.mfaVerifySuccess.message':  `<p>
      Az MFA-token ellenőrzése sikeres volt. A jövőben a hitelesítő alkalmazást
      kell használnia a bejelentkezési folyamat által megkövetelt 6 számjegyű
      token létrehozásához.
      </p>
      <p>
        <b>
          Mielőtt visszatérne a bejelentkezési képernyőre, mentse el vagy
          nyomtassa ki helyreállítási kulcsait! A helyreállítási kulcsokat
          később nem fogja tudni megtekinteni!
        </b>
      </p>
      <p>
      Ha elveszíti hozzáférését a hitelesítő alkalmazáshoz, másodlagos
      bejelentkezési tényezőként használhatja helyreállítási kulcsait.
      Minden helyreállítási kulcs csak egyszer használható! Sikeres
      bejelentkezés után létrehozhat egy új helyreállítási kulcskészletet.
    </p>`,

  'mfaSetupForm.setupFailureError.title':        'MFA beállítás hiba',
  'mfaSetupForm.title':                          'Többtényezős hitelesítés (MFA) beállítása',
  'mfaSetupForm.intro':                          'Fiókját mostantól többtényezős hitelesítés (MFA) védi. Kövesse az alábbi lépéseket az MFA konfigurálásához okostelefonján. A hitelesítő alkalmazás kiválasztásakor népszerű lehetőség a Google Authenticator (ingyenes Androidon és iOS-en).',
  'mfaSetupForm.step1.title':                    'Töltse le a hitelesítő alkalmazást',
  'mfaSetupForm.step1.explanation':              'Telepítsen egy hitelesítő alkalmazást okostelefonjára. Keressen a \'hitelesítő\' kulcsszóval az alkalmazásboltjában.',
  'mfaSetupForm.step2.title':                    'Olvassa be a QR-kódot',
  'mfaSetupForm.step2.explanation1':             'Használja a frissen telepített hitelesítő alkalmazást az alábbi vonalkód beolvasásához:',
  'mfaSetupForm.step2.explanation2':             'Ha nem tudja beolvasni a QR-kódot, manuálisan adhatja meg az alábbi kulcsot a hitelesítő alkalmazásban:',
  'mfaSetupForm.step3.title':                    'Adja meg a hitelesítési kódot',
  'mfaSetupForm.step3.explanation':              'Adja meg a hitelesítő alkalmazás által megjelenített 6 számjegyű kódot',
  'mfaSetupForm.button.verifyAndContinue':       'Ellenőrzés és folytatás',
  'mfaSetupForm.button.skipMfaSetup':            'MFA beállítás kihagyása',
  'mfaSetupForm.button.cancel':                  'Mégsem',
  'mfaSetupForm.verifyingMessage':               'Token ellenőrzés...',

  'mfaRecoveryForm.hint':                        'Használja MFA helyreállítási kulcsát a belépéshez!',
  'mfaRecoveryForm.header.login':                'Belépés MFA helyreállítási kulccsal',
  'mfaRecoveryForm.link.backToRequestToken':     'MFA token használata',
  'mfaRecoveryForm.placeholder.recoveryKey':     'MFA helyreállítási kulcs',
  'mfaRecoveryForm.button.login':                'Bejelentkezés',
  'mfaRecoveryForm.error.invalidToken':          'Érvénytelen helyreállítási kulcs',
  'mfaRecoveryForm.error.tokenExpired':          'Munkamenet lejárt',
  'mfaRecoveryForm.error.loginFailed':           'Bejelenkezés sikertelen',

  'mfaShowRecoveryKeys.button.print':            'Nyomtatás',
  'mfaShowRecoveryKeys.button.copy':             'Másolás',
  'mfaShowRecoveryKeys.button.confirm':          'Mentettem a kulcsokat',
  'mfaShowRecoveryKeys.keyList.screen.title':    'MFA helyreállítási kulcsok',
  'mfaShowRecoveryKeys.keyList.print.title':     'Az Ön ECDMS MFA helyreállítási kulcsai:',
  'mfaShowRecoveryKeys.keyList.print.warning':   'Kérjük tartsa a kinyomtatott helyreállítási kulcsokat biztonságos helyen!',

  'registrationForm.header.registration':        'Regisztráció',

  'registration.confirmYourEmail.header':             'Erősítse meg az email címét!',
  'registration.confirmYourEmail.body':               'Kérjük erősítse meg email címét a kapott linkre való kattintással. Nem érkezett meg az emial? Kérjen újat az alábbi link segítségével!',
  'registration.confirmYourEmail.backToLogin':        'Vissza a bejelentkezésre',
  'registration.confirmYourEmail.resendConfirmation': 'Megerősítés újra küldése',

  'registration.resendEmail.header':             'Megerősítő email újra küldése',
  'registration.resendEmail.body':               'Ha nem érkezett meg a megerősítő email az alábbi form kitöltésével igényelhet újat. Kérjük adja meg a regisztrációs email-t és kiküldjük ismét a megerősítést.',
  'registration.resendEmail.success':            'Konfirmációs e-mailt sikeresen kiküldtük ismét.',

  'registration.confirmationSuccess.header':     'Email cím megerősítés',
  'registration.confirmationSuccess.body':       'Email címét sikeresen megerősítettük. Az aktivizációja után használatba veheti a rendszert. Aktivizációjáról a regisztrált email címen fog értesülni.',
  'registration.confirmationSuccess.fail':       'Nem sikerült megerősíteni az email címét kérjük keresse fel az adminisztrátort.',

  'institutes.column.name':                      'Intézmény neve',
  'institutes.column.shortName':                 'Rövid név',
  'institutes.column.country':                   'Ország',
  'institutes.column.city':                      'Város',
  'institutes.column.contactName':               'Kapcsolattartó neve',
  'institutes.column.contactEmail':              'Kapcsolattartó email',
  'institutes.column.contactPhone':              'Kapcsolattartó telefon',
  'institutes.column.notes':                     'Megjegyzések',
  'institutes.column.timeZone':                  'Időzóna',
  'institutes.column.postCode':                  'Irányítószám',
  'institutes.column.region':                    'Régió, megye',
  'institutes.column.address':                   'Cím',
  'institutes.column.lat':                       'Földrajzi szélesség',
  'institutes.column.lon':                       'Földrajzi hosszúság',

  'institutes.error.listLoadError':              'Az intézmények nem tölthetőek be, vegye fel a kapcsolatot egy adminisztrátorral!',
  'institutes.error.deleteError':                'Nem tudja törölni ezt az intézményt!',
  'institutes.error.addError':                   'Nem tudjuk felvenni ezt az inténzményt, kérjük ellenőrizze az adatok helyességét!',

  'institutes.timeZone.hint':                    'Az időzóna módosítása esetén az adott intézményhez tartozó űrlapok azonosítója megváltozhat!',
  'institutes.timeZone.change':                  'Ha megváltoztatja az intézmény időzónáját, akkor az intézményhez kapcsolódó űrlapok azonosítója újragenerálódik és a választott időzónától függően megváltozhat! Az azonosítók generálása azonnal megkezdődik és hosszabb időt vehet igénybe. Biztosan megváltoztatja az intézmény időzónáját?',

  'patient.confirmation.edit':                   'Biztos benne ?<br/>**Ezen adatok a kapcsolódó űrlapokon nem kerülnek módosításra!**',
  'patient.error.saveError':                     'Hiba lépett fel az adatok mentése során',
  'patient.error.loadError':                     'Hiba a beteg betöltése során',
  'patient.error.mandatorySelect':               'Ehhez a művelethez a beteg megadása kötelező',
  'patient.confirmation.merge':                  'A **beteg már létezik** ezekkel az adatokkal, össze kívánja vonni a két beteget?',
  'patient.success.delete':                      'A beteg törlése sikerült',

  'patients.column.internalPatientNumber':       'Belső betegazonosító',
  'patients.column.insuranceNumber':             'Betegbiztosítási azonosító',
  'patients.column.gender':                      'Nem',
  'patients.column.dob':                         'Születési dátum',
  'patients.column.name':                        'Név',
  'patients.column.registryPatientNumber':       'Reg. spec. betegazonosító',
  'patients.column.country':                     'Ország',
  'patients.column.instituteCount':              'Csatolt intézmények száma',
  'patients.column.attendanceCount':             'Ellátások száma',
  'patients.column.bioSampleCount':              'Bio minták száma',
  'patients.column.createdAt':                   'Rögzítés dátuma',
  'patients.column.registry':                    'Regiszter',
  'patients.column.institute':                   'Csatolt intézmény',


  'patients.header.patientsList':                'Betegtörzs',

  'patients.error.listLoadError':                'Az betegek nem tölthetőek be, vegye fel a kapcsolatott egy adminisztrátorral!',
  'patients.error.deleteError':                  'Nem tudja törölni ezt az beteget!',
  'patients.error.addError':                     'Nem tudjuk felvenni ezt az beteget, kérjük ellenőrizze az adatok helyességét!',

  'institutePatient.error.attributeMismatch':      'A beteg már létezik más adatokkal',
  'institutePatient.error.instituteNotFound':      'Az intézmény nem található',
  'institutePatient.error.invalidInsuranceNumber': 'Érvénytelen biztosítási azonosító',
  'institutePatient.error.createError':            'Hiba a beteg létrehozása során',

  'users.column.name':                           'Név',
  'users.column.email':                          'Email',
  'users.column.activationState':                'Aktiváció állapota',
  'users.column.password':                       'Jelszó',
  'users.column.passwordConfirmation':           'Jelszó megerősítés',
  'users.column.phone':                          'Telefonszám',
  'users.column.doctor':                         'Doktor?',
  'users.column.role':                           'Szerepkör',
  'users.column.roles':                          'Szerepkörök',
  'users.column.institute':                      'Intézmény',
  'users.column.notes':                          'Megjegyzések',
  'users.column.state':                          'Állapot',
  'users.column.country':                        'Ország',
  'users.column.city':                           'Város',
  'users.column.legacyDoctorCode':               'Régi orvos kód',
  'users.column.userGroup':                      'Felhasználói csoportok',
  'users.column.preventAutomaticInactivation':   'Automatikus inaktiválás blokkolása',
  'users.column.deactivatedUsersNotification':   'Értesítés inaktivált felhasználókról',
  'users.column.inactiveUsersNotification':      'Értesítés inaktív felhasználókról',
  'users.column.registry':                       'Regiszter',
  'users.column.mfaPolicy':                      'MFA házirend',
  'users.column.mfaEnabled':                     'MFA engedélyezve',
  'users.column.mfaEnforcedAt':                  'MFA kikényszerítés időpontja',
  'users.column.mfaConfirmed':                   'MFA megerősítve',
  'users.column.displayId':                      'Felhasználó azonosító',

  'users.roles.local_administrator':             'Helyi feltöltő adminisztrátor',
  'users.roles.local_investigator':              'Helyi ellenőrző orvos',
  'users.roles.monitor_administrator':           'Központi ellenőrző adminisztrátor',
  'users.roles.monitor_investigator':            'Központi ellenőrző orvos',
  'users.roles.admin':                           'Központi Admin',
  'users.roles.user':                            'Felhasználó',
  'users.roles.biobank_admin':                   'Biobank Admin',
  'users.roles.active_substance_admin':          'Hatóanyag Admin',
  'users.roles.data_exporter':                   'Adat lekérdező',
  'users.roles.data_importer':                   'Adat importáló',
  'users.roles.impersonator':                    'Megszemélyesítő',

  'users.state.unconfirmed':                     'nincs megerősítve',
  'users.state.confirmed':                       'megerősítve',
  'users.state.inactive':                        'inaktív',
  'users.state.active':                          'aktív',

  'users.mfaPolicy.enforced':                    'Kikényszerítve',
  'users.mfaPolicy.forbidden':                   'Tiltva',
  'users.mfaPolicy.policy':                      'Felhasználói beállítás vagy házirend alapján',

  'users.updateEmails.newUsers':                 'Új emailek',
  'users.updateEmails.updatedUsers':             'Módosított emailek',

  'users.error.listLoadError':                   'Az betegek nem tölthetőek be, vegye fel a kapcsolatott egy adminisztrátorral!',
  'users.error.deleteError':                     'Nem tudja törölni ezt az beteget!',
  'users.error.addError':                        'Nem tudjuk felvenni ezt az beteget, kérjük ellenőrizze az adatok helyességét!',
  'users.error.impersonationError':              'Hiba történt a megszemélyesítés kezdeményezése során!',
  'users.error.impersonationExitError':          'Hiba történt a megszemélyesítésből való kilépés során!',
  'users.error.emailListUpdateError':            'Nem frissíthető az email lista!',

  'userForm.legend.basicData':                   'Alap adatok',
  'userForm.legend.account':                     'Fiók',
  'userForm.legend.mfa':                         'Többtényezős hitelesítés',
  'userForm.legend.inactivation':                'Inaktiválás',

  'permissions.filter':                          'Szűrés',
  'permissions.add':                             'Engedély hozzáadása',
  'permissions.reload':                          'Alaphelyzet',
  'permissions.refresh':                         'Frissít',
  'permissions.newPermission':                   'Új engedély',
  'permissions.permissionScheme':                'Engedély sémák',

  'permission.scheme.uploader':                       'Feltöltő adminisztrátor',
  'permission.scheme.physician':                      'Helyi kezelőorvos',
  'permission.scheme.leadPhysician':                  'Vizsgálatvezető',
  'permission.scheme.cooperativeLocalAdministrator':  'Kooperatív feltöltő adminisztrátor',
  'permission.scheme.cooperativeLocalInvestigator':   'Kooperatív kezelőorvos',
  'permission.scheme.monitorAdministrator':           'Ellenőrző adminisztrátor',
  'permission.scheme.cooperativeMonitorInvestigator': 'Kooperatív vizsgálatvezető',
  'permission.scheme.guest':                          'Betekintő',

  'permissions.column.registry':                 'Regiszter',
  'permissions.column.formType':                 'Űrlap típus',
  'permissions.column.institute':                'Intézmény',
  'permissions.column.physician':                'Kezelőorvos',
  'permissions.column.userGroup':                'Felhasználói csoport',
  'permissions.column.permission':               'Engedély',
  'permissions.column.adminPermissions':         'Központi engedélyek',
  'permissions.column.institutePermissions':     'Intézményi engedélyek',
  'permissions.column.attachPermission':         'Minta csatolás engedély',
  'permissions.column.trusteeName':              'Felhasználó neve',
  'permissions.column.trustee':                  'Felhasználó/Csoport',
  'permissions.column.createdAt':                'Létrehozás',

  'permission.hint.registry':                   'Ha nem választ ki regisztert akkor az engedély az összes regiszterre vonatkozik (*).',
  'permission.hint.formType':                   'Ha nem választ ki űrlap típust akkor az engedély az összes űrlap típusra vonatkozik (*).',
  'permission.hint.institute':                  'Ha nem választ ki intézményt akkor az engedély az összes intézményre vonatkozik (*).',
  'permission.hint.physician':                  'Ha nem választ ki kezelőorvost akkor az engedély az összes kezelőorvosra vonatkozik (*).',

  'read_admin_bio_sample.tooltip':              'Központi minta megtekintés',
  'create_admin_bio_sample.tooltip':            'Központi minta létrehozás',
  'update_admin_bio_sample.tooltip':            'Központi minta szerkesztés',
  'delete_admin_bio_sample.tooltip':            'Központi minta törlés',

  'read_institute_bio_sample.tooltip':          'Intézményi minta megtekintés',
  'create_institute_bio_sample.tooltip':        'Intézményi minta létrehozás',
  'update_institute_bio_sample.tooltip':        'Intézményi minta szerkesztés',
  'delete_institute_bio_sample.tooltip':        'Intézményi minta törlés',
  'attach_sample_to_form.tooltip':              'Minta csatolás',

  'permissions.error.saveError':                 'Hiba lépett fel az engedély mentése során',
  'permissions.error.loadError':                 'Hiba lépett fel az adatok betöltése során',

  'rejectFieldModal.title':                      '{fieldName} - Mező javítás kérése',
  'rejectFieldModal.label.rejectReason':         'Javítás kérésének indoka',
  'rejectFieldModal.button.reject':              'Javítás kérése',
  'rejectFieldModal.button.revoke':              'Visszavonás',
  'rejectFieldModal.button.modifyReason':        'Indok módosítása',

  'correctNoteModal.title':                      '{fieldName} - Javítási megjegyzés',
  'correctNoteModal.label.correctNote':          'Megjegyzés',
  'correctNoteModal.button.save':                'Mentés',
  'correctNoteModal.button.delete':              'Törlés',
  'correctNoteModal.button.modifyNote':          'Megjegyzés módosítása',

  'bioSample.sampleNumber':                      'Kód',
  'bioSample.bioSampleType':                     'Típus',
  'bioSample.registryShortName':                 'Regiszter',
  'bioSample.institute':                         'Intézmény',
  'bioSample.sampleCollectedAt':                 'Mintavétel',
  'bioSample.sampleCollectedAtStartDate':        'Mintavétel',
  'bioSample.sampleCollectedAtEndDate':          'Mintavétel',
  'bioSample.legacySampleNumber':                'Régi kód',
  'bioSample.instituteBioSample.title':          'Intézményi biológiai minták',
  'bioSample.newBioSample':                      'Új biológiai minta felvétele',
  'bioSample.notes':                             'Megjegyzések',
  'bioSample.sampleParameters':                  'Minta jellemzői',
  'bioSample.title.show':                        'Biológiai minta megtekintése',
  'bioSample.title.edit':                        'Biológiai minta szerkesztése',
  'bioSample.patientOtherSamples':               'A beteg összes mintája',
  'bioSample.forms':                             'Mintához csatolt űrlapok',
  'bioSample.arrivedToBiobankAt':                'Bekerülés',
  'bioSample.add.success':                       'Sikeresen csatoltad a mintát!',
  'bioSample.add.error':                         'Hiba lépett fel a csatolás során!',
  'bioSample.addBioSample':                      'Biológiai minták csatolása',
  'bioSample.title':                             'Biológiai minták',
  'bioSample.admin.title':                       'Bio admin | Biológiai minták',
  'bioSample.addLocalization':                   'Tároló hozzáadása',
  'bioSample.removeLocalization':                'Tároló eltávolítása',
  'bioSample.localization':                      'Lokalizációk',
  'bioSample.localizationInfo':                  'Lokalizációs információk',
  'bioSample.fridge':                            'Hűtő',
  'bioSample.compartment':                       'Rekesz',
  'bioSample.box':                               'Doboz',
  'bioSample.anySampleInTheBank':                'Van minta?',
  'bioSample.has':                               'Van',
  'bioSample.none':                              'Nincs',
  'bioSample.anything':                          'Nincs adat',
  'bioSample.amount':                            'Mennyiség',
  'bioSample.ancestorSample':                    'Ős minta',
  'bioSample.addDispatch':                       'Új kiküldési információ hozzáadása',
  'bioSample.dispatchInfo':                      'Kiküldési információk',
  'bioSample.removeDispatch':                    'Kiküldés törlése',
  'bioSample.dispatchedAt':                      'Mikor lett kiküldve',
  'bioSample.dispatchedTo':                      'Melyik kutatás kapcsán',
  'bioSample.editBioSample':                     'Biológiai minta szerkesztése',
  'bioSample.unlink':                            'Minta lecsatolása',
  'bioSamples.samples':                          'Biológiai minták',
  'bioSample.detach':                            'Biztosan leválasztja a **{sampleNumber}** mintát?',
  'bioSample.approval':                          'Jóváhagyás',
  'bioSample.newVirtualBioSample':               'Virtuális ősminta alapján történő minta rögzítés',
  'bioSample.virtualSampleAncestorType':         'Virtuális minta típusa',
  'bioSample.virtalSampleCollectedAtStartDate':  'Virtuális minta mintavétele',
  'bioSample.newDescendantBioSample':            'Új származtatott minta rögzítése',
  'bioSample.virtualBiosample':                  'Virtuális bio minta',
  'bioSample.ancestorSampleType':                'Ős minta típus',
  'bioSample.ancestorCollectedAt':               'Ős minta mintavétele',
  'bioSample.adminLink':                         'Ugrás az admin oldalra',

  'bioSample.button.submitAndNext':              'Elküldés és új minta rögzítés',

  'bioSample.error.deleteError':                 'Nem tudja törölni ezt a bio mintát!',
  'bioSample.new.success':                       'Sikeresen rögzítettük a {sampleNumber} mintát!',

  'bioSampleApprovalState.collected':            'Minta begyűjtve',
  'bioSampleApprovalState.attached':             'Űrlaphoz csatolva',
  'bioSampleApprovalState.arrived':              'Beérkezett a biobankba',
  'bioSampleApprovalState.localized':            'Lokalizált',

  'ancestorSelector.title.selectSample':         'Ős minta kód keresése',
  'ancestorSelector.button.selectSample':        'Ős minta csatolása',

  'correctFormConfirmationModal.title':          'Javítás megerősítése',
  'rejectFormConfirmationModal.title':           'Elutasítás megerősítése',
  'approveFormConfirmationModal.title':          'Jóváhagyás megerősítése',
  'approveFormConfirmationModal.body':           'Biztos benne, hogy minden helyes?',

  'attendance.title':                            'Ellátásbeli űrlapok listája',
  'attendance.inpatient-day':                    'Nap',
  'attendance.forms':                            'űrlapok',
  'attendance.formId':                           'Űrlap azonosító',
  'attendance.state':                            'Állapot',
  'attendance.approvals':                        'Jóváhagyás',
  'attendance.physician':                        'Kezelőorvos',
  'attendance.formDate':                         'Űrlap dátuma',
  'attendance.formCreatedAt':                    'Rögzítés dátuma',
  'attendance.startDateTime':                    'Felvétel időpontja',
  'attendance.endDateTime':                      'Befekvés vége',
  'attendance.newForm':                          'Új űrlap csatolása',

  'attendancePeriod.title':                      '"{name}" űrlap',

  'attendance.error.loadError':                  'Hiba az ellátás betöltése során',
  'attendance.error.notFoundError':              'Az ellátás nem létezik',

  'attendance.forms.nextInpatientDay':           'következő befekvési nap',

  'topMenu.mainData':                            'Törzs adatok',
  'topMenu.data':                                'Adatok',
  'topMenu.dashboard':                           'Irányítópult',
  'topMenu.forms':                               'Űrlapok',
  'topMenu.admin':                               'Admin',
  'topMenu.signOut':                             'Kijelentkezés',
  'topMenu.institutes':                          'Intézmények',
  'topMenu.patients':                            'Betegek',
  'topMenu.users':                               'Felhasználók',
  'topMenu.backToApp':                           'Vissza az alkalmazáshoz',
  'topMenu.adminDashboard':                      'Irányítópult',
  'topMenu.registryPermissions':                 'Regiszter engedélyek',
  'topMenu.bioBankPermissions':                  'Biobank engedélyek',
  'topMenu.centralBioBank':                      'Központi biobank',
  'topMenu.passwordChange':                      'Jelszó módosítás',
  'topMenu.profileSettings':                     'Profil beállítások',
  'topMenu.statistic':                           'Statisztikák',
  'topMenu.permissions':                         'Engedélyek',
  'topMenu.dataExport':                          'Adat lekérdezés',
  'topMenu.dataImport':                          'Adat import',
  'topMenu.userGroups':                          'Csoportok',
  'topMenu.registries':                          'Regiszterek',
  'topMenu.applicationLog':                      'Alkalmazás napló',
  'topMenu.system':                              'Rendszer',

  'sidebar.forms.newForm':                       'Új űrlap',
  'sidebar.forms.listForms':                     'Űrlapok listája',
  'sidebar.forms.sectionList':                   'Szekció lista',

  'sidebar.admin.institutes':                    'Intézmények',
  'sidebar.admin.newInstitute':                  'Új intézmény',
  'sidebar.admin.listInstitutes':                'Intézmények listája',

  'sidebar.admin.users':                         'Felhasználók',
  'sidebar.admin.newUser':                       'Új felhasználó',
  'sidebar.admin.listUsers':                     'Felhasználók listája',

  'sidebar.admin.patients':                      'Betegek',
  'sidebar.admin.newPatient':                    'Új beteg',
  'sidebar.admin.listPatients':                  'Betegek listája',
  'sidebar.admin.editPatient':                   'Beteg szerkesztése',

  'sidebar.admin.registryPermissions':           'Regiszter engedélyek',
  'sidebar.admin.newRegistryPermission':         'Új regiszter engedély',
  'sidebar.admin.listRegistryPermissions':       'Regiszter engedélyek listázása',
  'sidebar.admin.listBiobankPermissions':        'Biobank engedélyek listázása',
  'sidebar.admin.editBiobankPermission':         'Biobank engedély szerkesztése',
  'sidebar.admin.newBiobankPermission':          'Biobank engedély létrehozása',
  'sidebar.admin.newUserGroup':                  'Új csoport létrehozása',
  'sidebar.admin.editUserGroup':                 'Csoport szerkesztése',

  'sidebar.admin.updateEmailList':               'Email lista frissítése',
  'sidebar.admin.syncingEmails':                 'Email lista szinkronizálása',

  'sidebar.admin.newDataImportJob':              'Új adat import',
  'sidebar.admin.listDataImportJobs':            'Adat importok listája',

  'zeroSection.label.institute':                 'Intézmény',
  'zeroSection.label.patient':                   'Beteg',
  'zeroSection.label.physician':                 'Kezelőorvos',

  'patientSelector.title.selectPatient':         'Beteg kiválasztása',

  'patientSelector.button.selectPatient':        'Kijelölt beteg kiválasztása',
  'patientSelector.button.newPatient':           'Új beteg rögzítése',

  'newPatientPane.title.addNewPatient':          'Új beteg létrehozás',

  'dashboard.accessibleRegistries.noRegistry':        'Nincs elérhető regiszter!',
  'dashboard.attendanceStart.noAttendanceStart':      'Nincs elérhető ellátás!',
  'dashboard.instituteBioSample.noSelectedInstitute': 'Nincs kiválasztva intézmény!',

  'dashboard.cardTitle.attendanceList':          'Ellátás lista',
  'dashboard.cardTitle.attendanceStart':         'Ellátás indítás',
  'dashboard.cardTitle.todoList':                'Tennivalók',
  'dashboard.cardTitle.instituteBioSamples':     'Intézményi biobank',

  'dashboard.generalFormList.approvableFormsLink':  'Jóváhagyandó űrlapok',
  'dashboard.generalFormList.correctableFormsLink': 'Javítandó űrlapok',
  'dashboard.generalFormList.nothingToDo':          'Nincs tennivaló',

  'newPermissionForm.description.conditions':    'Amikor ezek a feltételek teljesülnek',
  'newPermissionForm.description.permission':    'Rendeld ezeket az engedélyeket',
  'newPermissionForm.description.userGroup':     'Ehhez a felhasználóhoz',
  'newPermissionForm.description.trustee':       'Ehhez a felhasználóhoz/csoporthoz',

  'permission.label.reg_access':                 'Regiszter hozzáférés',
  'permission.label.frm_create':                 'Űrlap létrehozás',
  'permission.label.frm_read':                   'Űrlap megtekintés',
  'permission.label.frm_edit':                   'Űrlap szerkesztés',
  'permission.label.frm_delete':                 'Űrlap törlés',
  'permission.label.frm_destroy':                'Űrlap feltétel nélküli törlés',
  'permission.label.att_disqualify':             'Ellátás kizárás',
  'permission.label.frm_approve_1':              'Űrlap 1. szintű jóváhagyás',
  'permission.label.frm_approve_2':              'Űrlap 2. szintű jóváhagyás',
  'permission.label.frm_approve_3':              'Űrlap 3. szintű jóváhagyás',
  'permission.label.frm_approve_4':              'Űrlap 4. szintű jóváhagyás',
  'permission.label.personal_data_access':       'Személyes adat hozzáférés',
  'permission.label.frm_impl_read':              'Űrlap megtekintés (impl)',
  'permission.label.frm_impl_edit':              'Űrlap szerkesztés (impl)',
  'permission.label.frm_impl_delete':            'Űrlap törlés (impl)',
  'permission.label.frm_impl_approve_1':         'Űrlap 1. szintű jóváhagyás (impl)',
  'permission.label.frm_impl_approve_2':         'Űrlap 2. szintű jóváhagyás (impl)',
  'permission.label.frm_impl_approve_3':         'Űrlap 3. szintű jóváhagyás (impl)',
  'permission.label.frm_impl_approve_4':         'Űrlap 4. szintű jóváhagyás (impl)',
  'permission.label.implicit_access':            'Közvetett hozzáférés',
  'permission.label.frm_reset_approval':         'Jóváhagyás törlés',
  'permission.label.bio_manage':                 'Biológiai minták kezelése',
  'permission.label.read_institute_bio_sample':  'Intézményi minta megtekintés',
  'permission.label.create_institute_bio_sample':  'Intézményi minta létrehozás',
  'permission.label.update_institute_bio_sample':  'Intézményi minta szerkesztés',
  'permission.label.delete_institute_bio_sample':  'Intézményi minta törlés',
  'permission.label.create_admin_bio_sample':    'Központi minta létrehozás',
  'permission.label.read_admin_bio_sample':      'Központi minta megtekintés',
  'permission.label.update_admin_bio_sample':    'Központi minta szerkesztés',
  'permission.label.delete_admin_bio_sample':    'Központi minta törlés',
  'permission.label.attach_bio_sample_to_form':  'Minta csatolás',
  'permission.label.short.read_institute_bio_sample':  'Minta megtekintés',
  'permission.label.short.create_institute_bio_sample':  'Minta létrehozás',
  'permission.label.short.update_institute_bio_sample':  'Minta szerkesztés',
  'permission.label.short.delete_institute_bio_sample':  'Minta törlés',
  'permission.label.short.create_admin_bio_sample':    'Minta létrehozás',
  'permission.label.short.read_admin_bio_sample':      'Minta megtekintés',
  'permission.label.short.update_admin_bio_sample':    'Minta szerkesztés',
  'permission.label.short.delete_admin_bio_sample':    'Minta törlés',

  'permission.error.deleteError':                'Nem tudja ezt az engedélyt törölni!',

  'statistic.title':                             'Statisztikák',
  'statistic.downloadRegistryUploadsLabel':      'Regiszter feltöltésszám',
  'statistic.downloadRegistryUploadsButton':     'Regiszter feltöltésszám .xlsx letöltése',
  'statistic.downloadFollowUpLabel':             'Utánkövetés',
  'statistic.downloadFollowUpButton':            'Utánkövetés .xlsx letöltése',
  'statistic.downloadRejectionCountLabel':       'Visszadobásszám',
  'statistic.downloadRejectionCountButton':      'Visszadobásszám .xlsx letöltése',
  'statistic.downloadUserActivityLabel':         'Felhasználói aktivitás',
  'statistic.downloadUserActivityButton':        'Felhasználói aktivitás .xlsx letöltése',

  'VALIDATION_ERROR':                            'Validációs hiba',

  'api.invalidTimeZone':                         'érvénytelen időzóna',
  'api.unique':                                  'már létezik',
  'api.match':                                   'nem egyezik',
  'api.recaptcha':                               'captcha nincs megadva',

  'string.regex.base':                           'érvénytelen formátum',
  'string.notComplexEnough':                     'a jelszó nem elég erős',
  'string.dateRange':                            'a "{first}" dátumnak korábbinak kell lennie mint a "{second}" dátumnak',
  'string.base64':                               'nem base64 formátumú',
  'string.base':                                 'nem string',
  'string.creditCard':                           'nem bankkártya szám',
  'string.dataUri':                              'nem adat URI',
  'string.domain':                               'nem érvényes domain név',
  'string.email':                                'nem érvényes email cím',
  'string.empty':                                'üres',
  'string.guid':                                 'nem érvényes GUID',
  'string.hex':                                  'nem hexadecimális sztring',
  'string.hostname':                             'nem érvényes hoszt név',
  'string.ipVersion':                            'nem érvényes IP cím',
  'string.ip':                                   'nem érvényes IP cím',
  'string.isoData':                              'nem érvényes ISO dátum',
  'string.isoDuration':                          'nem érvényes ISO időtartam',
  'string.length':                               'nem {limit} hosszúságú',
  'string.lowercase':                            'kisbetűkből kell állnia',
  'string.max':                                  'hosszabb mint {limit} karakter',
  'string.min':                                  'rövidebb mint {limit} karakter',
  'string.pattern.base':                         'nem illeszkedik a mintára ({regex})',
  'string.pattern.name':                         'nem illeszkedik {name} mintára',
  'string.token':                                'csak a-z, A-Z, 0-9 és aláhúzás _ karaktereket tartalmazhat',
  'string.trim':                                 'szóközt tartamaz az elején vagy a végén',
  'string.uppercase':                            'nagybetűsnek kell lennie',
  'string.uri':                                  'érvényes URL-nek kell lennie',
  'string.uriCustomScheme':                      'érvényes URL-nek kell lennie a következő sémával: {scheme}://',
  'string.uriRelativeOnly':                      'érvényes relatív URL-nek kell lennie',

  'array.base':                                  'nem tömb',
  'array.length':                                'nem {limit} elemet tartalmaz',
  'array.max':                                   'több mint {limit} elemet tartalmaz',
  'array.min':                                   'kevesebb, mint {limit} elemet tartalmaz',
  'array.unique':                                'ismétlődő elemeket tartalmaz',

  'date.base':                                   'dátumnak kell lennie',
  'date.format':                                 '{format} formátumúnak kell lennie',
  'date.greater':                                'nem korábbi, mint {limit}',
  'date.less':                                   'nem későbbi, mint {limit}',
  'date.max':                                    'nem lehet későbbi, mint {limit}',
  'date.min':                                    'nem lehet korábbi, mint {limit}',
  'date.strict':                                 'nem dátum',

  'number':                                      'kötelező számot megadni',
  'number.base':                                 'nem szám',
  'number.greater':                              'nagyobbnak kell lennie mint {limit}',
  'number.infinity':                             'végtelen',
  'number.integer':                              'egésznek kell lennie',
  'number.less':                                 'kisebbnek kell lennie mint {limit}',
  'number.max':                                  'kisebbnek kell lennie mint {limit}',
  'number.min':                                  'nagyobbnak kell lennie mint {limit}',
  'number.multiple':                             'nem osztható a következő számmal: {multiple}',
  'number.negative':                             'negatívnak kell lennie',
  'number.port':                                 '0 és 65535 közöttinek kell lennie',
  'number.positive':                             'pozitívnak kell lennie',
  'number.precision':                            '{limit} tizedesjegy pontosságú kell legyen',
  'number.unsafe':                               'nem reprezentálható szám',

  'object.unknown':                              'nem várt attribútum: {child}',
  'object.base':                                 'nem objektum',
  'object.length':                               'több mint {limit} attribútuma van',
  'object.max':                                  'kevesebb mint {limit} attribútuma lehet',
  'object.min':                                  'több mint {limit} attribútuma kell, hogy legyen',
  'object.with':                                 'tartalmaznia kell "{peer}" attribútumot, ha "{main}" jelen van',
  'object.without':                              'nem tartalmazhatja a  "{peer}" attribútumot, ha "{main}" jelen van',

  'any.required':                                'kötelező',
  'any.unknown':                                 'nem várt érték',
  'any.only':                                    'érvénytelen értek: {valids}',

  'logic.with':                                  'megkell adni "{peer}" értéket, ha "{main}" jelen van',

  'defaultError':                                'érvénytelen érték',

  'formErrorsPane.title.formErrors':             'Űrlap mező hibák',
  'formErrorsPane.title.formWarnings':           'Űrlap mező figyelmeztetések',
  'formErrorsPane.column.field':                  'Mező',
  'formErrorsPane.column.errorMessage':          'Hiba',
  'formErrorsPane.legend':                       'Tekintse át az űrlapon lévő hibákat, majd a szerkesztett űrlap tárolásához kattintson a Mentés gombra. A Mégsem gombra kattintva az űrlap mentése nélkül folytathatja az űrlap szerkesztését. A félkövéren megjelenített mezők kitöltése piszkozat mentése esetén is kötelező! A Mentés gomb inaktív, amíg a félkövéren szedett összes mező nincs kitöltve.',
  'formErrorsPane.warningLegend':                'Az itt szereplő mezőknél figyelmeztetések vannak, kérjük ellenőrize őket!',
  'formErrorsPane.sectionHeader':                '{section}. szekció',

  'error.noAccess':                              'Nincs engedélye az erőforrás megtekintéséhez.',

  'formSectionListPane.title':                   'Űrlap szekciók',

  'dataGrid.rowSelected':                        '{count} db kiválasztva',
  'dataGrid.deleteSelection':                    'Kijelölés törlése',
  'dataGrid.selectPerPage':                      'Lista hossz: ',
  'dataGrid.noData':                             'Nincs megjeleníthető adat!',
  'dataGrid.loadError':                          'Hiba történt a betöltés során!',
  'dataGrid.search.placeholder':                 'Keresés...',
  'dataGrid.search.filterTooltip':               'Szűrés',
  'dataGrid.search.resetTooltip':                'Alaphelyzet',

  'form.hint':                                   'A műveletek az egész űrlapra vonatkoznak! Intézményi időzóna: {timeZone}.',
  'navigation.prompt':                           'Biztos benne? Minden elmentetetlen adat elfog veszni!',

  'userDashboard.institute':                     'Intézmény: ',
  'userDashboard.registryAclMissing':            'Jelenleg nem rendelkezik megfelelő engedélyekkel!',

  'searchableSelectList.selectValue':            'Válaszd ki az értéket!',

  'dataExport.fileName':                         'Excel fájl neve',
  'dataExport.locale':                           'Lekérdezés nyelve',
  'dataExport.registries':                       'Regiszter(ek)',
  'dataExport.institutes':                       'Intézmény(ek)',
  'dataExport.formTypes':                        'Csak ezek az űrlap típusok',
  'dataExport.minApprovals':                     'Legkisebb jóváhagyási szintek',
  'dataExport.components':                       'Mező típus (komponens)',
  'dataExport.formId':                           'Űrlap belső azonosító (UUID)?',
  'dataExport.rawFormValues':                    'Nyers űrlap adatok?',
  'dataExport.instituteName':                    'Intézmény név',
  'dataExport.instituteCity':                    'Intézmény város',
  'dataExport.patientDob':                       'Beteg születési dátuma',
  'dataExport.patientName':                      'Beteg neve',
  'dataExport.insuranceNumber':                  'Betegbiztosítási azonosító?',
  'dataExport.attendanceCount':                  'Befekvések száma',
  'dataExport.bioSamples':                       'Űrlaphoz rendelt biominták',
  'dataExport.bioSamplesLegacyDisplayId':        'Űrlaphoz rendelt biominták régi azonosítója',
  'dataExport.fields':                           'Csak ezek a mezők',
  'dataExport.fieldsLabel':                      'Címkék megjelenítése azonosítók helyett mezőválasztáskor',
  'dataExport.fields.hint':                      'Az azonosítókat vesszővel elválasztva add meg!',
  'dataExport.fieldCaseSensitivity':             'A mező szűrés esetén kis- és nagybetűk érzékenysége',
  'dataExport.groupBy':                          'Excel munkalapok csoportosítása',
  'dataExport.legacyDisplayId':                  'Régi azonosítók',
  'dataExport.randomGroup':                      'Randomizációs csoport',
  'dataExport.password':                         'Excel fájl jelszava',
  'dataExport.registry':                         'Regiszter',
  'dataExport.institute':                        'Intézmény',
  'dataExport.formType':                         'Űrlap típus',
  'dataExport.success':                          'Sikeresen elindult a lekérdezés! Kis idő múlva a regisztrált email címére küldjük a megadott jelszóval védett .xls-t!',
  'dataExport.approvals':                        'Űrlap jóváhagyási szintek',
  'dataExport.formState':                        'Űrlap állapot',
  'dataExport.complexFlatten':                   'Komplex mezők',
  'dataExport.divideArrayFields':                'Többválasztós mezők szétbontása',
  'dataExport.sortArrayFieldsInComplex':         'Többválasztós mezők egymás mellé helyezése',
  'dataExport.renderType':                       'Adat lekérési típusa',
  'dataExport.form':                             'Form szerint (soros)',
  'dataExport.attendance':                       'Befekvés szerinti (oszlopos)',
  'dataExport.dayPerSheet':                      'Űrlap maximális száma egy Excel lapon',
  'dataExport.maxColumns':                       'Oszlop maximális száma egy Excel lapon',
  'dataExport.maxDays':                          'Befekvési napok maximális száma',
  'dataExport.attendanceApprovals':              'Ellátásonkénti legkisebb jóváhagyási szint megjelenítése',
  'dataExport.enableFormData':                   'Űrlap adatok',
  'dataExport.groupByFields':                    'Befekvési nap helyett mező szerinti csoportosítás',
  'dataExport.approvalLevels':                   'Össz jóváhagyási szint megjelenítése',
  'dataExport.arraySeparator':                   'Multi input fieldek elválasztó stringje',
  'dataExport.defaultFormOrder':                 'Mezők formban megjelenő sorrendje?',
  'dataExport.formTypeAttendance':               'Befekvések űrlap típus szerinti csoportosítása',
  'dataExport.patientData':                      'Személyes adatok megjelenítése',
  'dataExport.orderingType':                     'Mezők sorrendje',
  'dataExport.whichDataToShow':                  'Megjelenítendő adatok',
  'dataExport.source':                           'Forrás és cél',
  'dataExport.formOrder':                        'Űrlapbeli sorrend alapján',
  'dataExport.alpabeticalOrder':                 'Ábécé sorrend alapján',
  'dataExport.complex.flatten':                  'Almezőnként külön oszlopban',
  'dataExport.complex.notFlatten':               'Egy cellában (JSON)',
  'dataExport.filledFormNumber':                 'Csoportosítás kitöltött formszám alapján',
  'dataExport.attendanceExport':                 'Űrlap szerinti lekérdezés paraméterei',
  'dataExport.formExport':                       'Befekvés szerinti lekérdezés paraméterei',
  'dataExport.fileUpload':                       'Mezők fájlból való kigenerálása',
  'dataExport.fileUploadText':                   'Header fájl feltöltése',
  'dataExport.fieldSorting':                     'Mező szűrű alapján készült sorrend',
  'dataExport.showRegistries':                   'Mező regiszterhez tartozása',
  'dataExport.fieldType':                        'Mező szűrés típusa',
  'dataExport.fieldType.text':                   'Szöveges mező (vesszővel elválasztva)',
  'dataExport.fieldType.select':                 'Legördülő lista',

  'dataImportForm.legend.dataImportJob':         'Adat import',
  'dataImportForm.column.name':                  'Megnevezés',
  'dataImportForm.column.csvFile':               'Fájl (.csv)',
  'dataImportForm.column.fileUploadText':        '.csv fájl feltöltése',

  'userGroups.column.name':                      'Név',
  'userGroups.column.groupType':                 'Csoport típus',
  'userGroups.column.members':                   'Tagok',
  'userGroups.newButton':                        'Új csoport',
  'userGroups.showUserGroup':                    'Csoport megtekintése',
  'registry.column.nameEn':                      'Angol név',
  'registry.column.nameHu':                      'Magyar név',
  'registry.column.shortName':                   'Rövid név',
  'registry.column.bioSampleCode':               'Bio minta kód',
  'registry.column.hasInpatientDay':             'Befekvéses?',
  'registry.column.mandatoryInsuranceNumber':    'Kötelező TAJ szám?',
  'registry.column.registryType':                'Regiszter típus',
  'registry.column.data.registry':               'regiszter',
  'registry.column.data.trial':                  'vizsgálat',
  'registry.column.trialManager.name':           'Vizsgálat vezető neve',

  'registry.column.formType.displayId':          'Azonosító',
  'registry.column.formType.name':               'Név',
  'registry.column.formType.shortName':          'Rövid név',
  'registry.column.formType.behaviorType':       'Viselkedés típus',
  'registry.column.formType.nameEn':             'Angol név',
  'registry.column.formType.nameHu':             'Magyar név',

  'registry.nameEn':                             'Angol név',
  'registry.nameHu':                             'Magyar név',
  'registry.shortName':                          'Rövid név',
  'registry.attendanceCount':                    'Ellátások száma',
  'registry.formCount':                          'Űrlapok száma',
  'registry.instituteCount':                     'Intézmények száma',
  'registry.patientCount':                       'Betegek száma',
  'registry.bioSampleCode':                      'Bio minta kód',
  'registry.hasInpatientDay':                    'Befekvéses?',
  'registry.mandatoryInsuranceNumber':           'Kötelező TAJ szám?',
  'registry.registryType':                       'Regiszter típus',
  'registry.createdAt':                          'Létrehozva',
  'registry.updatedAt':                          'Frissítve',
  'registry.registryGroupEn':                    'Angol regiszter csoport',
  'registry.registryGroupHu':                    'Magyar regiszter csoport',
  'registry.dataCollectionPeriod':               'Adatgyűjtési időtartam',
  'registry.registryManager.name':               'Regiszter vezető neve',
  'registry.registryManager.institute':          'Regiszter vezető intézménye',
  'registry.registryManager':                    'Regiszter vezető',
  'registry.trialManager.institute':             'Vizsgálat vezető intézménye',
  'registry.trialManager.name':                  'Vizsgálat vezető neve',
  'registry.trialManager':                       'Vizsgálat vezető',
  'registry.attendancePeriodDispNameEn':         'Angol ellátás azonosító',
  'registry.attendancePeriodDispNameHu':         'Magyar ellátás azonosító',
  'registry.randomGroupBlockMultipliers':        'Randomizált blokkméret szorzói',
  'registry.randomGroupVisibility':              'Randomizációs csoport láthatósága',
  'registry.randomGroups':                       'Randomizációs csoportok',
  'registry.randomizationStrategy':              'Randomizációs stratégia',
  'registry.randomizationStrategy.basic':        'Default',
  'registry.randomizationStrategy.permuted_block': 'Permutált blokk',
  'registry.targetPatientCount':                 'Kitűzött betegek száma',
  'registry.formTypes':                          'Űrlap típusok',
  'registry.formType.displayId':                 'Azonosító',
  'registry.formType.name':                      'Név',
  'registry.formType.shortName':                 'Rövid név',
  'registry.formType.behaviorType':              'Viselkedés típus',
  'registry.formType.locales':                   'Lokalizációk',
  'registry.formType.startDatetimeFieldId':      `'Befekvés kezdete' mező id`,
  'registry.formType.endDatetimeFieldId':        `'Befekvés vége' mező id`,
  'registry.formType.patientNameFieldId':        'Beteg név id',
  'registry.formType.lastVersion':               'Utolsó verzió',
  'registry.formType.createdAt':                 'Létrehozva',
  'registry.formType.updatedAt':                 'Frissítve',
  'registry.formType.externalFields':            'Külső mezők',
  'registry.formType.externalFields.displayId':  'Form típus megjelenített azonosító',
  'registry.formType.externalFields.id':         'Form típus azonosító',
  'registry.formType.externalFields.fields':     'Importálható mezők',

  'registry.externalFields.sourceDisplayId':     'Forrás formtípus azonosítója',
  'registry.externalFields.sourceField':         'Forrás mező azonosítója',
  'registry.externalFields.targetField':         'Cél mező azonosítója',

  'registry.dashboard.title':                    'Regiszterek',
  'registry.dashboard.countTrial':               'Vizsgálatok:',
  'registry.dashboard.countRegistries':          'Regiszterek:',

  'registry.randomGroupVisibility.available':          'Elérhető',
  'registry.randomGroupVisibility.reportOnly':         'Csak kimutatásban szerepel',
  'registry.randomGroupVisibility.hidden':             'Elérhető',

  'registry.error.listLoadError':                'A regiszterek nem tölthetőek be, vegye fel a kapcsolatott egy adminisztrátorral!',
  'registry.formTypes.error.loadError':          'A form típusok nem tölthetőek be, vegye fel a kapcsolatott egy adminisztrátorral!',
  'registry.error.loadError':                    'A regiszter nem tölthető be, vegye fel a kapcsolatott egy adminisztrátorral!',

  'mailchimp.error.updateError':                 'Hiba történt a Mailchimp email lista frissítése közben!',

  'applicationLog.column.id':                    'Id',
  'applicationLog.column.createdAt':             'Létrehozva',
  'applicationLog.column.reqId':                 'Kérés azonosító',
  'applicationLog.column.msg':                   'Rendszer üzenet',
  'applicationLog.column.httpMethod':            'HTTP parancs',
  'applicationLog.column.url':                   'URL',
  'applicationLog.column.userAgent':             'User Agent',
  'applicationLog.column.referer':               'Referer',
  'applicationLog.column.remoteAddress':         'Távoli cím',
  'applicationLog.column.httpResponseCode':      'Státusz',
  'applicationLog.column.responseTime':          'Idő (ms)',
  'applicationLog.column.data':                  'Adat',
  'applicationLog.column.event':                 'Esemény',
  'applicationLog.column.message':               'Üzenet',
  'applicationLog.column.reason':                'Ok',
  'applicationLog.column.severity':              'Súlyosság',
  'applicationLog.column.targetId':              'Cél azonosító',
  'applicationLog.column.targetName':            'Cél neve',
  'applicationLog.column.targetType':            'Cél típusa',
  'applicationLog.column.userId':                'Felhasználó azonosító',
  'applicationLog.column.userName':              'Felhasználó név',
  'applicationLog.column.rawLog':                'Eredeti bejegyzés',
  'applicationLog.form.from':                    'Mettől',
  'applicationLog.form.search':                  'Keresés',
  'applicationLog.form.to':                      'Meddig',
  'applicationLog.form.raw':                     'Nyers logok',

  'errorReport.title':                           'Úgy látszik egy hibába futottál!',
  'errorReport.subtitle':                        'Csapatunk már értesült a hibáról.',
  'errorReport.subtitle2':                       'Amennyibe segítenéd a munkákat kérlek tölsd ki a következő űrlapot.',
  'errorReport.labelName':                       'Név',
  'errorReport.labelEmail':                      'Email',
  'errorReport.labelComments':                   'Mi történt?',
  'errorReport.labelClose':                      'Bezárás',
  'errorReport.labelSubmit':                     'Hiba jelentés beküldése',
  'errorReport.successMessage':                  'Köszönjük a visszajelzésed!',

  'errorFallback.title':                         'Úgy látszik egy hibába futottál!',
  'errorFallback.description':                   'Csapatunk már értesült a hibáról! Kérlek próbáld megújra tölteni az oldalt. Amennyiben a hiba folyamatos a jobb sarokban található email ikonra kattintva jelezheted ezt nekünk.',

  'dataImport.column.id':                        'Azonosító',
  'dataImport.column.name':                      'Megnevezés',
  'dataImport.column.success':                   'Sikeres',
  'dataImport.column.initiatedByName':           'Kezdeményezte',
  'dataImport.column.createdAt':                 'Létrehozva',
  'dataImport.column.results':                   'Eredmények',
  'dataImport.column.errors':                    'Hibák',
  'dataImport.column.fileName':                  'Fájl név',

  'dataImport.list.button.removeFailedJobs':     'Sikertelen folyamatok eltávolítása',
  'dataImport.succeededBadge':                   'Sikeres',
  'dataImport.failedBadge':                      'Sikertelen',
}

const hu = {
  locale: 'hu',
  messages,
}

export default hu
