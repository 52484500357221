import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getApplicationLogs(options = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/application-log`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function getApplicationLog(id){
  const resp = await axiosInstance.get()
    .get(`/api/v1/application-log/${id}`)
  return resp.data.results[0]
}
