import axiosInstance from './axios_instance'

export function setLocale(locale) {
  axiosInstance.setLocale(locale)
}

export function setToken(token) {
  axiosInstance.setToken(token)
}

export function setUnauthorizedCallback(unauthorizedCallback) {
  axiosInstance.setUnauthorizedCallback(unauthorizedCallback)
}

export { default as axiosInstance } from './axios_instance'

export * from './attendance_period'
export * from './attendance'
export * from './bio_sample_type'
export * from './bio_sample'
export * from './client_configuration'
export * from './form_acl'
export * from './form_descriptor'
export * from './form_start_data'
export * from './form_type'
export * from './form'
export * from './institute'
export * from './patient'
export * from './permission'
export * from './registry'
export * from './user_group'
export * from './user'
export * from './registration'
export * from './statistic'
export * from './external_fields'
export * from './data_export'
export * from './data_import'
export * from './approval'
export * from './impersonation'
export * from './mailchimp'
export * from './application_logging'
export * from './profile_settings'

export * from './utils'
export * from './mocked'
