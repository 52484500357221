import React from 'react'
import styled from 'styled-components/macro'
import FontAwesome from 'react-fontawesome'
import Hint from './Hint'

const ErrorField = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid transparent;
  border-color: #f5c6cb;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin: ${props => (props.vertical ? '' : '0 1rem')};

  .fa {
    margin-right: 5px;
  }
`

const WarningField = styled(ErrorField)`
  color: #856404;
  border-color: #ffeeba;
  background-color: #fff3cd;
`

const Label = styled.label`
  min-width: ${props => (props.vertical ? '' : '200px')};
  max-width: ${props => (props.vertical ? '' : '200px')};
  margin-right: ${props => (props.vertical ? '' : '10px')};
  text-align: left;
  font-weight: 700;
  word-wrap: break-word;
`

const RequiredStar = styled.sup`
  color: #ef4836;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  /* flex-wrap: wrap; */
  align-items: ${props => (props.vertical ? 'flex-start' : 'center')};
  min-height: 2rem;
  transition: all 0.3s ease-in-out;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

type Props = {
  children?: Element,
  error?: string,
  warning?: string,
  label?: string,
  required?: boolean,
  style?: Object,
  vertical?: boolean,
}

class LabelFieldWrapper extends React.Component<Props> {
  render() {
    const {
      error,
      warning,
      label,
      required,
      vertical = false,
      className,
      hint,
      important,
    } = this.props
    return (
      <Wrapper vertical={vertical} className={className}>
        {label && (
          <Label vertical={vertical}>
            {label}:{required && <RequiredStar>{'*'}</RequiredStar>}
          </Label>
        )}
        <Container>
          {this.props.children}
          {hint && <Hint label={hint} important={important} />}
        </Container>
        {warning ? (
          error ? (
            <ErrorField vertical={vertical}>
              <FontAwesome name="ban" />
              {error}
            </ErrorField>
          ) : (
            <WarningField vertical={vertical}>
              <FontAwesome name="exclamation-triangle" />
              {warning}
            </WarningField>
          )
        ) : (
          error && (
            <ErrorField vertical={vertical}>
              <FontAwesome name="ban" />
              {error}
            </ErrorField>
          )
        )}
      </Wrapper>
    )
  }
}

export default LabelFieldWrapper
