// @flow

export type FilterOperator = string
export type FilterAttribute = string
export type FilterValue = string

export function escape(filterStr: ?string) {
  if (typeof filterStr !== 'string') {
    return filterStr
  }
  return filterStr.replace(/,/g, ',,')
}

/*
  FilterCondition represents a single filter condition in query params.
  E.g. `name=@John` becomes:
  {
    attr: 'name',
    op  : '=@',
    val : 'John'
  }
 */
export default class FilterCondition {

  _attr: FilterAttribute
  _op: FilterOperator
  _val: FilterValue

  constructor(attr: FilterAttribute, op: FilterOperator, val: FilterValue) {
    this._attr = attr
    this._op = op
    this._val = val
  }

  get attr(): FilterAttribute {
    return this._attr
  }

  get op(): FilterOperator {
    return this._op
  }

  get val(): FilterValue {
    return this._val
  }

  toString() {
    return `${this._attr}${this._op}${escape(this._val)}`
  }

  static serialize(filterConditions: FilterCondition[]): string {
    return filterConditions.map(fc => fc.toString()).join(',')
  }

}
