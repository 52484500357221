// @flow
import FilterCondition from './filter_condition'
import SortCondition from './sort_condition'

export type CommonCollectionQueryParameters = {
  offset?: number,
  limit?: number,
  filter?: FilterCondition[],
  sort?: SortCondition[],
}

export function objToFilterConditions(obj: { [string]: any }, operator: string = '=@') {
  if (obj === Object(obj)) {
    return Object.keys(obj).map(key => new FilterCondition(key, operator, obj[key]))
  }
  return []
}

export function sanitizeCommonCollectionQueryParameters(options: CommonCollectionQueryParameters): Object {
  return Object.keys(options).slice().sort().reduce((acc: Object, key: string) => {
    switch(key) {
      case 'filter':
        if (options.filter
            && options.filter.length > 0
            && options.filter.every(f => f instanceof FilterCondition)) {
          return { ...acc, [key]: FilterCondition.serialize(options.filter) }
        }
        return acc
      case 'sort':
        if (options.sort
            && options.sort.length > 0
            && options.sort.every(s => s instanceof SortCondition)) {
          return { ...acc, [key]: SortCondition.serialize(options.sort) }
        }
        return acc
      case 'limit':
      case 'offset':
      case 'statistics':
      case 'verbose':
        return { ...acc, [key]: options[key] }
      default:
        return acc
    }
  }, {})
}
