//@flow
import React from 'react'
import { connect } from 'react-redux'
import { 
  setSubmitAttemptedAction,
  closeAllConfirmationModalAction,
} from './actions'

type Props = {
  component: React$ElementType,
  remoteForm: string,
  setSubmitAttemptedAction: (formName: string) => void,
  closeAllConfirmationModalAction: (formName: string) => void,
  onClick: () => void,
  _myForm: Object,
  submitInProgress: boolean,
  submitButtonEnabled: boolean,
}

class ConnectedSubmitButton extends React.Component<Props> {

  static defaultProps = {
    submitType: 'submit',
  }

  componentClickHandler = (evt: SyntheticEvent<*>) => {
    const {
      remoteForm,
      _myForm,
      setSubmitAttemptedAction,
      closeAllConfirmationModalAction,
      submitInProgress,
    } = this.props
    const formName = remoteForm || _myForm.name
    if (submitInProgress) return
    setSubmitAttemptedAction(formName)
    closeAllConfirmationModalAction(formName)
    this.props.onClick()
  }

  render() {
    const { component, submitInProgress, submitButtonEnabled } = this.props
    return React.cloneElement(component, {
      disabled: submitButtonEnabled || submitInProgress,
      onClick: this.componentClickHandler,
    })
  }
}

export default connect(
  (state, ownProps) => ({
    submitInProgress: state.getIn(['ecrForm', ownProps._myForm.name, 'submitInProgress']) || false
  }),
  {
    setSubmitAttemptedAction,
    closeAllConfirmationModalAction,
  }
)(ConnectedSubmitButton)
