import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Immutable from 'immutable'
import {
  getIn,
  storePath,
} from './utils'

import {
  blurFieldAction,
  changeFieldValueAction,
  focusFieldAction,
  registerFieldAction,
  setSyncErrorsAction,
  unregisterFieldAction,
} from './actions'
import {
  FIELD_TYPE_FIELD,
} from './constants'
import {
  convertMapToJs
} from '../utils'

import type { MyFormType } from './types'

export function isEvent(val) {
  return Boolean(val && val.stopPropagation && val.preventDefault)
}

// TODO: shouldComponentUpdate

type Props = {
  _myForm: MyFormType,
  name: string,
  label: string,
  _parentSection: number,
}

class ConnectedField extends React.Component<Props> {

  constructor (props) {
    super(props)
    this.formName = props._myForm.name
    this.fieldName = props.name
    this.formValidator = props._myForm.formValidator
  }

  componentDidMount() {
    this.props.registerFieldAction(
      this.fieldName,
      this.props._parentSection,
      FIELD_TYPE_FIELD,
      this.formName
    )
  }

  componentWillUnmount () {
    this.props.unregisterFieldAction(this.fieldName, this.formName)
  }

  onChangeCallback = (val) => {
    const value = isEvent(val) ? val.target.value : val
    this.props.changeFieldValueAction(this.fieldName, value, this.formName, this.formValidator.flatValidate)
  }

  onBlurCallback = (evt) => {
    this.props.blurFieldAction(this.fieldName, this.props.value, this.formName, this.formValidator.flatValidate)
  }

  onFocusCallback = (evt) => {
    this.props.focusFieldAction(this.fieldName, this.formName)
  }

  render () {
    const {
      children,
      component: WrappedComponent,
      corrected,
      correctNote,
      displayMode,
      error,
      warning,
      fieldUnderFix,
      name,
      rejected,
      renderType,
      submitAttempted,
      value,
      ...rest
    } = this.props
    const convertedValue = convertMapToJs(this.props.value)
    if ((!children && !WrappedComponent) || (children && !_.isFunction(children))) {
      throw new Error("Field must have a function as children or component prop")
    }
    const fieldStates = this.props.fieldStates || Immutable.Map()

    if (children) {
      return children(
        convertedValue, // value
        {
          active: fieldStates.get('active'), // MISSING
          corrected: corrected,
          correctNote: correctNote,
          dirty: fieldStates.get('dirty'),
          displayMode: displayMode,
          error: error,
          warning: warning,
          fieldUnderFix: fieldUnderFix,
          name: name,
          onBlur: this.onBlurCallback, // onBlur
          onChange: this.onChangeCallback, // onChange
          onFocus: this.onFocusCallback,
          readOnly: fieldStates.get('readOnly'),
          rejected: rejected,
          renderType: renderType,
          submitAttempted: submitAttempted,
          touched: fieldStates.get('touched'),
          rest,
        }
      )
    }
    return (
      <WrappedComponent
        corrected={corrected}
        correctNote={correctNote}
        dirty={fieldStates.get('dirty')}
        displayMode={displayMode}
        error={error}
        warning={warning}
        fieldUnderFix={fieldUnderFix}
        name={name}
        onBlur={this.onBlurCallback} // onBlur
        onChange={this.onChangeCallback} // onChange
        onFocus={this.onFocusCallback}
        readOnly={fieldStates.get('readOnly')}
        rejected={rejected}
        renderType={renderType}
        submitAttempted={submitAttempted}
        touched={fieldStates.get('touched')}
        value={convertedValue}
        {...rest}
      />
    )
  }

}

export default connect((state, ownProps) => ({
  currentSection: state.getIn(storePath(['currentSection'], ownProps._myForm.name, 'ecrForm')),
  error: state.getIn(storePath(['syncErrors', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  warning: state.getIn(storePath(['syncWarnings', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  fieldStates: state.getIn(storePath(['fieldStates', ownProps.name], ownProps._myForm.name, 'ecrForm'), Immutable.Map()),
  fieldUnderFix: state.getIn(storePath(['fieldUnderFix'], ownProps._myForm.name, 'ecrForm')),
  rejected: state.getIn(storePath(['rejectedFields', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  corrected: state.getIn(storePath(['correctedFields', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  correctNote: state.getIn(storePath(['correctNotes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  renderType: state.getIn(storePath(['renderTypes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  displayMode: state.getIn(storePath(['displayModes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  value: getIn(state, `values.${ownProps.name}`, ownProps._myForm.name, 'ecrForm'),
  viewType: state.getIn(storePath(['viewType'], ownProps._myForm.name, 'ecrForm')),
  submitAttempted: state.getIn(storePath(['submitAttempted'], ownProps._myForm.name, 'ecrForm')),
}), {
  blurFieldAction,
  changeFieldValueAction,
  focusFieldAction,
  registerFieldAction,
  setSyncErrorsAction,
  unregisterFieldAction,
})(ConnectedField)
