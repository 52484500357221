import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function postDataImport(payload) {
  const resp = await axiosInstance.get().post(`/api/v1/data-import`, payload)
  return resp.data
}

export async function getDataImportJob(id) {
  const resp = await axiosInstance.get().get(`/api/v1/data-import/jobs/${id}`)
  return resp?.data?.results?.[0]
}

export async function getDataImportJobs({ offset, limit, filter, sort } = {}) {
  const resp = await axiosInstance.get().get('/api/v1/data-import/jobs', {
    params: sanitizeCommonCollectionQueryParameters({
      offset,
      limit,
      filter,
      sort,
    }),
  })
  return resp.data
}

export async function deleteDataImportJobsFailed() {
  return axiosInstance.get().delete('/api/v1/data-import/jobs/failed')
}