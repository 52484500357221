// @flow
import SortCondition from './sort_condition'

/**
 * parseSortString parses the string representation of a sort
 * definition into an array of SortConditions.
 * E.g. for `age,-salary` it returns:
 * [
 *   { attr: 'age', dir: 'asc' },
 *   { attr: 'salary', dir: 'desc' },
 * ]
 * @param  {string}            sortStr String representation of sorting conditions.
 * @return {SortCondition[]}           Array of SortCondition objects.
 */
export function parseSortString(sortString: string): SortCondition[] {
  if (!sortString) {
    return []
  }
  const attrs: string[] = sortString.split(',').map(s => s ? s.trim() : s)
  return attrs.reduce((acc: SortCondition[], sc: string) => {
    if (!sc) {
      return acc
    }
    const [, sign, attr] = sc.match(/(\+|-)?(.*)/) || []
    if (attr.length === 0) {
      throw new Error(`Invalid sort string ${sortString}`)
    }
    const dir = sign === '-' ? 'desc' : 'asc'
    return [
      ...acc,
      new SortCondition(attr, dir)
    ]
  }, [])
}

export const isValidSortString = (sortString: string): boolean =>
  /^(?:[+-]?[a-z0-9]+)(?:,(?:[+-]?[a-z0-9]+))*$/ig.test(sortString)
