// @flow
import FilterCondition from './filter_condition'
import type {
  FilterOperator,
  FilterAttribute,
  FilterValue,
} from './filter_condition'

const SUPPORTED_OPERATORS = ['==', '!=', '=@', '!@', '>=', '<=', '<', '>']
const EXPECTED_PARTS_COUNT = 4

// export function objToFilterConditions(obj: { [string]: any }, operator: string = '=@') {
//   if (obj === Object(obj)) {
//     return Object.keys(obj).map(key => new FilterCondition(key, operator, obj[key]))
//   }
//   return []
// }

/**
 * parseFilterString parses the string representation of a
 * filter list into an array of FilterConditions.
 * E.g. for `name=@John,age==25` it returns:
 * [
 *   { attr: 'name', op: '=@', val: 'John' },
 *   { attr: 'age', op: '==', val: '25' },
 * ]
 * @param  {string}            filterStr String representation of filter conditions.
 * @return {FilterCondition[]}           List of FilterCondition objects.
 */
export function parseFilterString(filterStr: string): FilterCondition[] {
  if (!filterStr) {
    return []
  }
  const filters: string[] = filterStr.split(
    new RegExp(
      `(?:(?<=\\w+(?:${SUPPORTED_OPERATORS.join(
        '|'
      )}).+)\\,(?=\\w+(?:${SUPPORTED_OPERATORS.join('|')}).+))|^\\,`,
      'g'
    )
  )
  return filters.reduce((acc: FilterCondition[], fs: string) => {
    if (!fs) {
      return acc
    }
    const parts = new RegExp(
      `(^\\w+)(${SUPPORTED_OPERATORS.join('|')})(.+)`,
      'g'
    ).exec(fs)

    if ((parts || []).length !== EXPECTED_PARTS_COUNT) {
      throw new Error(`Invalid filter string ${fs}`)
    }
    const attr: FilterAttribute = parts[1]
    const op: FilterOperator = parts[2]
    const val: FilterValue = unescape(parts[3])
    return [...acc, new FilterCondition(attr, op, val)]
  }, [])
}

export function parseFilterStringToObj(filterStr: string, searchStrings = {}): Object {
  if (!filterStr) {
    return []
  }
  const filters: string[] = filterStr.split(
    new RegExp(
      `(?:(?<=\\w+(?:${SUPPORTED_OPERATORS.join(
        '|'
      )}).+)\\,(?=\\w+(?:${SUPPORTED_OPERATORS.join('|')}).+))|^\\,`,
      'g'
    )
  )
  return filters.reduce((acc, fs) => {
    const parts = new RegExp(
      `(^\\w+)(${SUPPORTED_OPERATORS.join('|')})(.+)`,
      'g'
    ).exec(fs)

    if ((parts || []).length !== 4) {
      throw new Error(`Invalid filter string ${fs}`)
    }

    const keys = Object.keys(searchStrings).filter((ss) =>
      ss.toLowerCase().includes(parts[1].toLowerCase())
    )
    return {
      ...acc,
      ...(keys.length > 0
        ? keys.reduce(
            (acc, key) => ({
              ...acc,
              ...(searchStrings[key] === parts[2]
                ? { [key]: unescape(parts[3]) }
                : {}),
            }),
            {}
          )
        : { [parts[1]]: unescape(parts[3]) }),
    }
  }, {})
}

export function unescape(filterStr: string) {
  return filterStr.replace(/,,/g, ',')
}

export const isValidFilterString = (filterString: string): boolean =>
  new RegExp(
    `(^\\w+)(${SUPPORTED_OPERATORS.join(
      '|'
    )})(.+)(?:(?<=\\w+(?:${SUPPORTED_OPERATORS.join(
      '|'
    )}).+)\\,(?=\\w+(?:${SUPPORTED_OPERATORS.join('|')}).+))*`,
    'g'
  ).test(filterString)
