// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import IconButton from '../../components/IconButton'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components/macro'
import confirmationDialog from '../../components/confirmation_dialog'
import {
  pushMultiFieldValueAction,
  removeMultiFieldValueAction,
} from './actions'

const FieldGroupWrapper = styled.div`
  position: relative;
  padding: 13px 25px 5px 30px;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
  border: 1px solid rgba(0,0,0,.2);
  margin-bottom: 8px;

  border: ${props => props.highlight && '1px solid #ee968d'};
  box-shadow: ${props => props.highlight && '0px 0px 10px rgba(255, 0, 0, .2)'};
`
const StyledIconButton = styled(IconButton)`
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin-right: 3px;
  opacity: .5;
  transition: all .2s ease-in-out;

  &:hover {
    color: #EF4836!important;
    opacity: 1;
    transform: none;
  } 
`

type Props = {
  allowAdd: boolean,
  allowRemove: boolean,
  component: React$Element<*>,
  count: number,
  intl: Object,
  name: string,
  pushMultiFieldValueAction: Function,
  removeMultiFieldValueAction: Function,
}

type State = {
  highlight: ?number,
}

class MultiField extends React.Component<Props, State> {

  static contextTypes = {
    _myForm: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      highlight: null,
    }
  }

  addButtonHandler = () => {
    this.props.pushMultiFieldValueAction(this.props.name, {}, this.context._myForm.name)
  }

  removeButtonHandler = (index: number) => {
    const { intl: { formatMessage } } = this.props
    confirmationDialog(formatMessage({
      id: 'confirmation.delete',
    }), [
        { label: formatMessage({ id: 'no' }), value: false, icon: 'times' },
        { label: formatMessage({ id: 'yes' }), value: true, kind: 'primary', icon: 'check' },
      ])
      .then(async ok => {
        if (ok) {
          try {
            this.props.removeMultiFieldValueAction(this.props.name, index, this.context._myForm.name)
          } catch (err) {
            console.error(err)
          }
        }
      })
  }

  renderItems() {
    const { allowRemove, component, count, intl: { formatMessage }, name } = this.props
    // Make key depend on complex field item count.
    // This way the key of each item changes when a
    // new item is added or removed so each item gets
    // rerendered.
    const keyPrefix = `${name}-${count}`
    return (
      <div>
        {[...Array(count).keys()].map(c =>
          <FieldGroupWrapper
            highlight={this.state.highlight === c}
            key={`${keyPrefix}-${c}`}
            >
            {allowRemove &&
              <StyledIconButton
                onMouseEnter={() => this.setState({ highlight: c })}
                onMouseLeave={() => this.setState({ highlight: null })}
                kind={'default'}
                title={formatMessage({ id: 'tooltip.delete'})}
                data-multi-index={c}
                onClick={() => this.removeButtonHandler(c)}
                name={'times'}
                />}
            {React.cloneElement(component, {
              name: `${name}[${c}]`,
            })}
           </FieldGroupWrapper>
        )}
      </div>
    )
  }

  renderEmpty() {
    return (
      <div>
        <FormattedMessage id="form.noValue" />
      </div>
    )
  }

  render() {
    const { allowAdd, count, intl: { formatMessage } } = this.props
    return (
      <div>
        {count ? this.renderItems() : this.renderEmpty()}
        {allowAdd &&
          <IconButton
            disableHover
            kind={'primary'}
            onClick={this.addButtonHandler}
            >
            <FontAwesome name={'plus'} />&nbsp;{formatMessage({ id: 'action.addNew'})}
          </IconButton>}
      </div>
    )
  }

}

export default injectIntl(connect(null, {
  pushMultiFieldValueAction,
  removeMultiFieldValueAction,
})(MultiField))
