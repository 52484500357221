import {
  requestAccessToken,
  postOtpToken,
  postMfaRecoveryKey,
} from './api'
import { addSecondsToDate } from '../utils'
import accessToken from '../access_token'

function toAccessToken(tokenString, expiresIn) {
  return {
    token: tokenString,
    expiresAt: addSecondsToDate(expiresIn),
  }
}

// Redux action types are constants with string values.
// Strings are serializable so they are better choice
// than e.g. Symbols.
export const LOGOUT = 'auth/LOGOUT'
export const PASSWORD_AUTH_REQUEST = 'auth/PASSWORD_AUTH_REQUEST'
export const PASSWORD_AUTH_FAILURE = 'auth/PASSWORD_AUTH_FAILURE'
export const PASSWORD_AUTH_SUCCESS = 'auth/PASSWORD_AUTH_SUCCESS'
export const MFA_AUTH_REQUIRED = 'auth/MFA_AUTH_REQUIRED'
export const MFA_SETUP_REQUIRED = 'auth/MFA_SETUP_REQUIRED'
export const MFA_AUTH_REQUEST = 'auth/MFA_AUTH_REQUEST'
export const MFA_AUTH_FAILURE = 'auth/MFA_AUTH_FAILURE'
export const MFA_AUTH_SUCCESS = 'auth/MFA_AUTH_SUCCESS'
export const REFRESH_ACCESS_TOKEN = 'auth/REFRESH_ACCESS_TOKEN'
export const MFA_RECOVERY_KEY_REQUIRED = 'auth/MFA_RECOVERY_KEY_REQUIRED'
export const MFA_RECOVERY_KEY_REQUEST = 'auth/MFA_RECOVERY_KEY_REQUEST'
export const MFA_RECOVERY_KEY_FAILURE = 'auth/MFA_RECOVERY_KEY_FAILURE'
export const BACK_TO_MFA_AUTH_REQUIRED = 'auth/BACK_TO_MFA_AUTH_REQUIRED'

// Action creators which return functions are `thunks` (function that wraps
// an expression to delay its evaluation) and are
// processed by `redux-thunk` redux middleware which is configured
// in the `services/store' module. redux-thunk is used to perform
// asynchronous actions because normal action creators are pure
// (they must have no side-effect).
export function loginAction(username, password) {
  return async function (dispatch /*, getState */) {
    try {
      dispatch(passwordAuthRequestAction())
      // access_token
      // token_type
      // expires_in
      // mfa_required
      // mfa_configured
      // mfa_enforced_at
      const resp = await requestAccessToken(username, password)
      if (resp.mfa_required) {
        if (!resp.mfa_configured) {
          dispatch(
            mfaSetupRequiredAction(resp.access_token, resp.mfa_enforced_at)
          )
        } else {
          // Multi-factor authentication required
          dispatch(
            mfaAuthRequiredAction(resp.access_token)
          )
        }
      } else {
        dispatch(
          passwordAuthSuccessAction(
            toAccessToken(resp.access_token, resp.expires_in)
          )
        )
      }
    } catch (err) {
      console.error(err)
      dispatch(passwordAuthFailureAction(err.message))
    }
  }
}

export function logoutAction(message) {
  return {
    type: LOGOUT,
    payload: message,
  }
}

export function passwordAuthRequestAction() {
  return {
    type: PASSWORD_AUTH_REQUEST,
  }
}

export function passwordAuthFailureAction(errorMessage) {
  return {
    type: PASSWORD_AUTH_FAILURE,
    payload: {
      errorMessage,
    },
    error: true,
  }
}

/**
 *
 * @param {{ token: string, expiresAt: Date}} accessToken
 */
export function passwordAuthSuccessAction(accessToken) {
  return {
    type: PASSWORD_AUTH_SUCCESS,
    payload: {
      accessToken,
    },
  }
}

export function mfaSetupRequiredAction(mfaAccessToken, mfaEnforcedAt) {
  return {
    type: MFA_SETUP_REQUIRED,
    payload: {
      mfaAccessToken,
      mfaEnforcedAt,
    },
  }
}

export function mfaAuthRequiredAction(mfaAccessToken) {
  return {
    type: MFA_AUTH_REQUIRED,
    payload: {
      mfaAccessToken,
    },
  }
}

export function backToMfaAuthRequiredAction() {
  return {
    type: BACK_TO_MFA_AUTH_REQUIRED,
  }
}

export function postMfaTokenAction(otpToken) {
  return async function (dispatch, getState) {
    const mfaAccessToken = getState().getIn(['auth', 'mfaAccessToken'])
    try {
      dispatch(mfaAuthRequestAction())
      const resp = await postOtpToken(otpToken, mfaAccessToken)
      dispatch(
        mfaAuthSuccessAction(toAccessToken(resp.access_token, resp.expires_in))
      )
    } catch (err) {
      // err probably is an Axios error
      console.error(err, err?.response)
      switch (err?.response?.status) {
        case 400:
          dispatch(mfaFailureAction('invalid_token'))
          break
        case 401:
          dispatch(mfaFailureAction('token_expired'))
          break
        default:
          dispatch(mfaFailureAction('login_failed'))
      }
    }
  }
}

export function mfaAuthRequestAction(accessToken, otpToken) {
  return {
    type: MFA_AUTH_REQUEST,
  }
}

export function mfaAuthSuccessAction(accessToken) {
  return {
    type: MFA_AUTH_SUCCESS,
    payload: {
      accessToken,
    },
  }
}

export function mfaFailureAction(errorMessage) {
  return {
    type: MFA_AUTH_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export function loadTokenAction() {
  return function (dispatch) {
    const token = accessToken.loadToken()
    if (token) {
      dispatch(passwordAuthSuccessAction(token))
    }
  }
}

export function refreshTokenAction(accessToken) {
  return {
    type: REFRESH_ACCESS_TOKEN,
    payload: {
      accessToken,
    },
  }
}

export function mfaRecoveryKeyRequiredAction() {
  return {
    type: MFA_RECOVERY_KEY_REQUIRED,
  }
}

export function postMfaRecoveryKeyAction(recoveryKey) {
  return async function (dispatch, getState) {
    const mfaAccessToken = getState().getIn(['auth', 'mfaAccessToken'])
    try {
      dispatch(mfaRecoveryKeyAction())
      const resp = await postMfaRecoveryKey(recoveryKey, mfaAccessToken)
      dispatch(
        mfaAuthSuccessAction(toAccessToken(resp.access_token, resp.expires_in))
      )
    } catch (err) {
      // err probably is an Axios error
      console.error(err, err?.response)
      switch (err?.response?.status) {
        case 400:
          dispatch(mfaRecoveryKeyFailureAction('invalid_token'))
          break
        case 401:
          dispatch(mfaRecoveryKeyFailureAction('token_expired'))
          break
        default:
          dispatch(mfaRecoveryKeyFailureAction('login_failed'))
      }
    }
  }
}

export function mfaRecoveryKeyAction() {
  return {
    type: MFA_RECOVERY_KEY_REQUEST,
  }
}

export function mfaRecoveryKeyFailureAction(errorMessage) {
  return {
    type: MFA_RECOVERY_KEY_FAILURE,
    payload: {
      errorMessage,
    },
    error: true,
  }
}
