import {
  postSetupMfaToken,
  postVerifyMfaToken,
  postMfaTokensSkipSetup,
} from './api'
import { addSecondsToDate } from '../utils'
import { passwordAuthSuccessAction } from '../auth'

function toAccessToken(tokenString, expiresIn) {
  return {
    token: tokenString,
    expiresAt: addSecondsToDate(expiresIn),
  }
}

export const MFA_SETUP_REQUEST = 'mfa/MFA_SETUP_REQUEST'
export const MFA_SETUP_FAILURE = 'mfa/MFA_SETUP_FAILURE'
export const MFA_SETUP_SUCCESS = 'mfa/MFA_SETUP_SUCCESS'
export const MFA_VERIFY_REQUEST = 'mfa/MFA_VERIFY_REQUEST'
export const MFA_VERIFY_FAILURE = 'mfa/MFA_VERIFY_FAILURE'
export const MFA_VERIFY_SUCCESS = 'mfa/MFA_VERIFY_SUCCESS'
export const MFA_CONFIRM_RECOVERY_KEYS = 'mfa/MFA_CONFIRM_RECOVERY_KEYS'
export const MFA_SKIP_SETUP_REQUEST = 'mfa/SKIP_SETUP_REQUEST'
export const MFA_SKIP_SETUP_FAILURE = 'mfa/SKIP_SETUP_FAILURE'

export function startMfaSetupAction(mfaAccessToken) {
  return async function (dispatch, getState) {
    try {
      const apiAccessToken = getState().getIn(['auth', 'accessToken', 'token'])
      // if called inside the app there is no mfaAccessToken so we need
      // the regular api access token
      dispatch(mfaSetupRequestAction(mfaAccessToken ?? apiAccessToken))
      const { otpUrl, mfaEnforcedAt } = await postSetupMfaToken(
        mfaAccessToken ?? apiAccessToken
      )
      dispatch(mfaSetupSuccessAction(otpUrl, mfaEnforcedAt))
    } catch (err) {
      dispatch(mfaSetupFailureAction(`${err.message} (${err.name})`))
    }
  }
}

export function mfaSetupRequestAction(accessToken) {
  return {
    type: MFA_SETUP_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export function mfaSetupFailureAction(errorMessage) {
  return {
    type: MFA_SETUP_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export function mfaSetupSuccessAction(otpUrl, mfaEnforcedAt) {
  return {
    type: MFA_SETUP_SUCCESS,
    payload: {
      otpUrl,
      mfaEnforcedAt,
    },
  }
}

export function mfaSetupVerifyAction(verifyToken) {
  return async function (dispatch, getState) {
    try {
      const accessToken = getState().getIn(['mfaSetup', 'accessToken'])
      dispatch(mfaVerifyRequestAction(verifyToken))
      const { recoveryKeys } = await postVerifyMfaToken(
        accessToken,
        verifyToken
      )
      dispatch(mfaVerifySuccessAction(recoveryKeys))
    } catch (err) {
      dispatch(mfaVerifyFailureAction(`${err.message} (${err.name})`))
    }
  }
}

export function mfaVerifyRequestAction(verifyToken) {
  return {
    type: MFA_VERIFY_REQUEST,
    payload: {
      verifyToken,
    },
  }
}

export function mfaVerifyFailureAction(errorMessage) {
  return {
    type: MFA_VERIFY_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export function mfaVerifySuccessAction(recoveryKeys) {
  return {
    type: MFA_VERIFY_SUCCESS,
    payload: {
      recoveryKeys,
    },
  }
}

export function mfaSkipSetupRequest(accessToken) {
  return {
    type: MFA_SKIP_SETUP_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export function mfaSkipSetupSuccessAction(accessToken) {
  return async function (dispatch) {
    // TODO: Can we avoid import auth reducer's action?
    dispatch(passwordAuthSuccessAction(accessToken))
  }
}

export function mfaSkipSetupFailureAction(errorMessage) {
  return {
    type: MFA_SKIP_SETUP_FAILURE,
    payload: {
      errorMessage,
    },
    error: true,
  }
}

export function postMfaSkipSetupAction() {
  return async function (dispatch, getState) {
    const mfaAccessToken = getState().getIn(['mfaSetup', 'accessToken'])
    try {
      dispatch(mfaSkipSetupRequest())
      const resp = await postMfaTokensSkipSetup(mfaAccessToken)
      dispatch(
        mfaSkipSetupSuccessAction(
          toAccessToken(resp.access_token, resp.expires_in)
        )
      )
    } catch (err) {
      // err probably is an Axios error
      console.error(err)
      dispatch(mfaSkipSetupFailureAction(err.message))
    }
  }
}
