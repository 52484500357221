// @flow

import React from 'react'
import { groupBy, toPath } from 'lodash'
import styled from 'styled-components/macro'
import { injectIntl, FormattedMessage } from 'react-intl'
import Button from '../../components/Button'
import SlidePane from '../../components/SlidePane'
import SubmitButton from '../../lib/ecr_form/SubmitButton'

/**
 * Transform a composite label to its human counterpart
 * @param  {string} label           Input label
 * @param  {string} labelTranslator Function that translate a single label component
 *                                  (it is formDescriptor's getLabel)
 * @return {string}                 Translated label
 */
const toHumanLabel = (label: string, labelTranslator: (string, string) => string) => {
  return toPath(label).filter(x => x !== '_header').map(l => labelTranslator(l, l)).join(' / ')
}

const Table = styled.table`
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .1);
`

const HeaderCell = styled.th`
  padding: 10px 5px;
  font-weight: bold;
  background-color: #888;
  color: #fff;
`

const Td = styled.td`
  border: 1px solid #e1e1e1;
  padding: 5px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => props.color || 'inherit'};
`

const SectionHeader= styled.td`
  padding: 10px 5px;
  font-weight: bold;
  background-color: #efefef;
`

const Legend = styled.div`
  padding: 0 0 15px 0;
  color: #555;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 10px;

  & > button + button {
    margin-left: 16px;
  }
`

const Header = styled.div``

const Footer = styled.div``

const ErrorContainer = styled.div`
  overflow-y: scroll;
`

const PaneContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

type Props = {
  onClose: () => void,
  onCancelButtonClick: () => void,
  onSubmitButtonClick: () => void,
  submitButtonEnabled: boolean,
  visible: boolean,
  intl: Object,
  errors: Array<Object>,
  warnings: Array<Object>,
  indispensableFields: string[],
  fieldSections: {
    [string]: number,
  },
  fieldOrders: {
    [string]: number,
  },
  labelTranslator: string => string,
}

type State = {
  submitAttempted: boolean,
}

class FormErrorsPane extends React.Component<Props, State> {
  static defaultProps = {
    onSubmitButtonClick: () => {},
    submitButtonEnabled: false,
    errors: [],
    indispensableFields: [],
  }

  renderErrors(errors) {
    const groupedErrors = groupBy(errors, e => this.props.fieldSections[toPath(e.field)[0]] || 0)
    return (
      <Table>
        <thead>
          <tr>
            <HeaderCell>
              <FormattedMessage id="formErrorsPane.column.field" />
            </HeaderCell>
            <HeaderCell>
              <FormattedMessage id="formErrorsPane.column.errorMessage" />
            </HeaderCell>
          </tr>
        </thead>
        {Object.keys(groupedErrors).map(s =>
          <tbody key={s}>
            <tr>
              <SectionHeader colSpan={2}><FormattedMessage id="formErrorsPane.sectionHeader" values={{ section: s }}/></SectionHeader>
            </tr>
            {groupedErrors[s].sort((a,b) => this.props.fieldOrders[a.field] - this.props.fieldOrders[b.field]).map((e, idx) => (
              <tr key={e.field || String(idx)}>
                <Td bold={this.props.indispensableFields.includes(e.field)}>
                  {toHumanLabel(e.field, this.props.labelTranslator)}
                </Td>
                <Td color="red">{e.message}</Td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    )
  }

  closeHandler = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  cancelButtonClickHandler = () => {
    if (this.props.onCancelButtonClick) {
      return this.props.onCancelButtonClick()
    }
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {
      visible,
      intl: { formatMessage },
      errors,
      warnings,
      submitButtonEnabled,
    } = this.props
    let renderedErrors = this.renderErrors(errors)
    let renderedWarnings = warnings ? this.renderErrors(warnings) : null
    return (
      <SlidePane onClose={this.closeHandler} visible={visible} width={'90%'}>
        <PaneContent>
          <Header>
            <h1>{formatMessage({ id: 'formErrorsPane.title.formErrors' })}</h1>
            <Legend colSpan={2}>
              <FormattedMessage id="formErrorsPane.legend" />
            </Legend>
            
          </Header>
          <Footer>
            <ButtonWrapper>
              <SubmitButton 
                component={
                  <Button
                    type="submit"
                    kind="primary"
                    label={formatMessage({ id: 'button.save' })}
                  />
                }
                submitButtonEnabled={!submitButtonEnabled}
                onClick={this.props.onSubmitButtonClick}
                />
              
              <Button
                type="button"
                label={formatMessage({ id: 'button.cancel' })}
                onClick={this.cancelButtonClickHandler}
              />
            </ButtonWrapper>
          </Footer>
          <ErrorContainer>
            {visible && renderedErrors}
            {renderedWarnings && 
              <React.Fragment>
                <h2>{formatMessage({ id: 'formErrorsPane.title.formWarnings' })}</h2>
                <Legend colSpan={2}>
                  <FormattedMessage id="formErrorsPane.warningLegend" />
                </Legend>
              </React.Fragment>
            }
            {visible && renderedWarnings}
          </ErrorContainer>
        </PaneContent>
      </SlidePane>
    )
  }
}

export default injectIntl(FormErrorsPane)
