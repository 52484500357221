import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  HIDE_FORM_FIELD_HISTORY,
  SHOW_FORM_FIELD_HISTORY,
  SET_LOCALE,
  SET_CLIENT_CONFIGURATION,
  SET_CURRENT_INSTITUTE,
} from '../actions'
import { getAppLocale } from '../../i18n'

const initialState = Immutable.fromJS({
  locale: getAppLocale(),
  formFieldHistory: null,
  formFieldHistoryError: null,
})

export default createReducer(initialState, {
  [SHOW_FORM_FIELD_HISTORY]:
    (state, action) => state.set('formFieldHistory', Immutable.fromJS(action.payload)),
  [HIDE_FORM_FIELD_HISTORY]:
    (state, action) => state.set('formFieldHistory', null),
  [SET_LOCALE]:
    (state, action) => state.set('locale', action.payload),
  [SET_CLIENT_CONFIGURATION]:
    (state, action) => state.set('user', Immutable.fromJS(action.payload.user)),
  [SET_CURRENT_INSTITUTE]:
    (state, action) => state.set('currentInstitute', Immutable.fromJS(action.payload)),
})
