//@flow
import axiosInstance from './axios_instance'

export async function getExternalFieldsByPatientId(
  patientId: string,
  formTypeId: string
) {
  const resp = await axiosInstance
    .get()
    .get(
      `/api/v1/patient/${patientId}/form-types/${formTypeId}/external-fields`
    )

  return resp.data.results
}

export async function getExternalFieldsByFormTypeId(
  formTypeId: string,
) {
  const { 
    data: { 
      results
    }
  } = await axiosInstance
    .get()
    .get(
      `/api/v1/form-types/${formTypeId}/external-fields`
    )
  return results
}