import Immutable from 'immutable'
import { storePath } from './utils'
import _ from 'lodash'
import DeferredQueue from './deferred_queue'

export const INIT_FORM = 'ecrForm/INIT_FORM'
export const SET_FIELD_REJECT_REASON = 'ecrForm/SET_FIELD_REJECT_REASON'
export const CLEAR_FIELD_REJECT_REASON = 'ecrForm/CLEAR_FIELD_REJECT_REASON'
export const SET_FIELD_UNDER_REJECTION = 'ecrForm/SET_FIELD_UNDER_REJECTION'
export const CLEAR_FIELD_UNDER_REJECTION = 'ecrForm/CLEAR_FIELD_UNDER_REJECTION'

// export const CHANGE_FIELD_VALUE = 'ecrForm/CHANGE_FIELD_VALUE'
export const CLEAR_SUBMIT_TRIGGER = 'ecrForm/CLEAR_SUBMIT_TRIGGER'
export const DESTROY_FORM = 'ecrForm/DESTROY_FORM'
export const FOCUS_FIELD = 'ecrForm/FOCUS_FIELD'
export const REGISTER_FIELD = 'ecrForm/REGISTER_FIELD'
export const REMOVE_FIELD = 'ecrForm/REMOVE_FIELD'
export const SET_FIELD_VALUE = 'ecrForm/SET_FIELD_VALUE'
export const SET_SYNC_ERRORS = 'ecrForm/SET_SYNC_ERRORS'
export const SET_SYNC_ERRORS_AND_WARNINGS = 'ecrForm/SET_SYNC_ERRORS_AND_WARNINGS'
export const SUBMIT = 'ecrForm/SUBMIT'
export const UPDATE_FIELD_STATE = 'ecrForm/UPDATE_FIELD_STATE'
export const SET_FIELD_UNDER_FIX = 'ecrForm/SET_FIELD_UNDER_FIX'
export const CLEAR_FIELD_UNDER_FIX = 'ecrForm/CLEAR_FIELD_UNDER_FIX'
export const UPDATE_DEPENDENCY_TREE_RENDER_TYPE = 'ecrForm/UPDATE_DEPENDENCY_TREE_RENDER_TYPE'
export const FORM_SUBMISSION_SUCCESS = 'ecrForm/FORM_SUBMISSION_SUCCESS'
export const FORM_SUBMISSION_FAILED = 'ecrForm/FORM_SUBMISSION_FAILED'
export const CLOSE_ALL_CONFIRMATION_MODAL = 'ecrForm/CLOSE_ALL_CONFIRMATION_MODAL'

export const SET_FIELD_UNDER_CORRECT_NOTE_WRITING = 'ecrForm/SET_FIELD_UNDER_CORRECT_NOTE_WRITING'
export const CLEAR_FIELD_UNDER_CORRECT_NOTE_WRITING = 'ecrForm/CLEAR_FIELD_UNDER_CORRECT_NOTE_WRITING'
export const SET_FIELD_CORRECT_NOTE = 'ecrForm/SET_FIELD_CORRECT_NOTE'
export const CLEAR_FIELD_CORRECT_NOTE = 'ecrForm/CLEAR_FIELD_CORRECT_NOTE'

export const SET_CORRECT_FORM_SUBMIT_CONFIRMATION = 'ecrForm/SET_CORRECT_FORM_SUBMIT_CONFIRMATION'
export const CLEAR_CORRECT_FORM_SUBMIT_CONFIRMATION = 'ecrForm/CLEAR_CORRECT_FORM_SUBMIT_CONFIRMATION'

export const SET_REJECT_FORM_SUBMIT_CONFIRMATION = 'ecrForm/SET_REJECT_FORM_SUBMIT_CONFIRMATION'
export const CLEAR_REJECT_FORM_SUBMIT_CONFIRMATION = 'ecrForm/CLEAR_REJECT_FORM_SUBMIT_CONFIRMATION'

export const SET_APPROVE_FORM_SUBMIT_CONFIRMATION = 'ecrForm/SET_APPROVE_FORM_SUBMIT_CONFIRMATION'
export const CLEAR_APPROVE_FORM_SUBMIT_CONFIRMATION = 'ecrForm/CLEAR_APPROVE_FORM_SUBMIT_CONFIRMATION'

export const SET_FORM_SECTION_LIST_PANE = 'ecrForm/SET_FORM_SECTION_LIST_PANE'
export const CLEAR_FORM_SECTION_LIST_PANE = 'ecrForm/CLEAR_FORM_SECTION_LIST_PANE'

export const SET_CURRENT_SECTION = 'ecrForm/SET_CURRENT_SECTION'
export const SHOW_FORM_HISTORY = 'ecrForm/SHOW_FORM_HISTORY'
export const HIDE_FORM_HISTORY = 'ecrForm/HIDE_FORM_HISTORY'

export const PUSH_MULTI_FIELD_VALUE = 'ecrForm/PUSH_MULTI_FIELD_VALUE'
export const REMOVE_MULTI_FIELD_VALUE = 'ecrForm/REMOVE_MULTI_FIELD_VALUE'
export const SET_SUBMIT_ATTEMPTED = 'ecrForm/SET_SUBMIT_ATTEMPTED'

export const REHYDRATE = 'ecrForm/REHYDRATE'
export const LOAD_FORMDATA_FROM_FILE = 'ecrForm/LOAD_FORMDATA_FROM_FILE'

export const SWEEP_FORM_VALUES = 'ecrForm/SWEEP_FORM_VALUES'

export function initFormAction(
  {
    viewType,
    initialValues,
    rejectedFields,
    correctedFields,
    correctNotes,
    sectionCount,
    form,
    currentSection,
    submitAttempted,
    fieldDependencyGraph,
    fieldTypes,
    fieldSections,
  }) {
  return {
    type: INIT_FORM,
    payload: {
      viewType,
      initialValues,
      rejectedFields,
      correctedFields,
      correctNotes,
      sectionCount,
      currentSection,
      submitAttempted,
      fieldDependencyGraph,
      fieldTypes,
      fieldSections,
    },
    meta: {
      form,
    }
  }
}

export function setFieldRejectReasonAction(field, rejectReason, form) {
  return {
    type: SET_FIELD_REJECT_REASON,
    payload: {
      field,
      rejectReason,
    },
    meta: {
      form,
    }
  }
}

export function clearFieldRejectReasonAction(field, form) {
  return {
    type: CLEAR_FIELD_REJECT_REASON,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

const THROTTLE_INTERVAL_MS = 1000

const throttledValidateForm = _.throttle((validate, getState, dispatch, form) => {
  const viewType = getState().getIn(['ecrForm', form, 'viewType'], '')
  if (viewType !== 'read') {
    const values = getState().getIn(['ecrForm', form, 'values'], Immutable.Map()).toJS()
    const {errors = {}, warnings = {}} = validate(values) || {}
    dispatch(setSyncErrorsAndWarningsAction(errors, warnings, form))
  }
}, THROTTLE_INTERVAL_MS)

/**
 * Async action which initiates a field value change in form's redux store.
 * @param  {String}   field    Name of field
 * @param  {String}   value    Value of field
 * @param  {String}   form     Name of form
 * @param  {Func}     validate Field-level validation function for form
 * @return {Function}          A function for redux-thunk middleware
 */
export function changeFieldValueAction(field, value, form, validate) {
  return function(dispatch, getState) {
    dispatch(setFieldValueAction(field, value, form))
    // if (validate) {
    //   const values = _.assign(getState().getIn(['ecrForm', form, 'values'], Immutable.Map()).toJS(), { [field]: value})
    //   const errors = validate(values)
    //   dispatch(setSyncErrorsAction(errors || {}, form))
    // }
    throttledValidateForm(validate, getState, dispatch, form)
  }
}

export function blurFieldAction(field, value, form, validate) {
  return function(dispatch, getState) {
    dispatch(updateFieldStateAction(field, { touched: true }, form))
    // if (validate) {
    //   const values = getState().getIn(['ecrForm', form, 'values'], Immutable.Map()).toJS()
    //   const errors = validate(values)
    //   dispatch(setSyncErrorsAction(errors || {}, form))
    // }
    throttledValidateForm(validate, getState, dispatch, form)
  }
}


/**
 * Deletes the `triggerSubmit` flag. It is used by the Form component
 * the clean up the flag after it has initiated the submit process.
 * @param  {String} form Name of the form
 * @return {FSA}         Flux Standard Action
 */
export function clearSubmitTriggerAction(form) {
  return {
    type: CLEAR_SUBMIT_TRIGGER,
    meta: {
      form,
    }
  }
}

/**
 * Remove all keys that belongs to a particular form.
 * Called by Form component in its `ComponentWillUnmount` method.
 * @param  {String} form Name of the form
 * @return {FSA}         Flux Standard Action
 */
export function destroyFormAction(form) {
  return {
    type: DESTROY_FORM,
    meta: {
      form,
    }
  }
}

export function _registerFieldAction(field, parentSection, fieldType, form) {
  return {
    type: REGISTER_FIELD,
    payload: {
      field,
      parentSection,
      fieldType,
    },
    meta: {
      form,
    }
  }
}

const registerFieldDeferredQueue = new DeferredQueue(function(queue){
  this.dispatch(updateDependencyTreeRenderTypeAction(queue, this.form))
}, {
  idleTime: 5,
})

export function registerFieldAction(field, parentSection, fieldType, form) {
  return function(dispatch, getState) {
    registerFieldDeferredQueue.setContext({
      dispatch,
      form,
    })
    registerFieldDeferredQueue.enqueue(field)
    dispatch(_registerFieldAction(field, parentSection, fieldType, form))
  }
}

export function unregisterFieldAction(field, form) {
  return function(dispatch, getState) {
    if (getState().getIn(storePath(['registeredFields'], form, 'ecrForm')) && // do not run after DESTROY_FORM
        getState().getIn(storePath(['registeredFields', field], form, 'ecrForm')) &&
        getState().getIn(storePath(['currentSection'], form, 'ecrForm')) // do not remove field on paging
          === getState().getIn(storePath(['fieldSections', field], form, 'ecrForm'))) {
      dispatch(removeFieldAction(field, form))
    }
  }
}

export function removeFieldAction(field, form) {
  return {
    type: REMOVE_FIELD,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

export function setFieldValueAction(field, value, form) {
  return function(dispatch, getState) {
    dispatch(updateFieldStateAction(field, { touched: true }, form))
    dispatch({
      type: SET_FIELD_VALUE,
      payload: {
        field,
        value,
      },
      meta: {
        form,
      }
    })
  }
}

export function focusFieldAction(field, form) {
  return {
    type: FOCUS_FIELD,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

export function updateFieldStateAction(field, state, form) {
  return {
    type: UPDATE_FIELD_STATE,
    payload: {
      field,
      state,
    },
    meta: {
      form,
    }
  }
}

export function setSyncErrorsAction(errors, form) {
  return {
    type: SET_SYNC_ERRORS,
    payload: {
      errors,
    },
    meta: {
      form,
    }
  }
}

export function setSyncErrorsAndWarningsAction(errors, warnings, form) {
  return {
    type: SET_SYNC_ERRORS_AND_WARNINGS,
    payload: {
      errors,
      warnings,
    },
    meta: {
      form,
    }
  }
}

export function setFieldUnderRejectionAction(field, form) {
  return {
    type: SET_FIELD_UNDER_REJECTION,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

export function clearFieldUnderRejectionAction(form) {
  return {
    type: CLEAR_FIELD_UNDER_REJECTION,
    meta: {
      form,
    }
  }
}

export function submitAction(submitType, form) {
  return {
    type: SUBMIT,
    payload: {
      submitType,
    },
    meta: {
      form,
    }
  }
}

export function setFieldUnderFixAction(field, form) {
  return {
    type: SET_FIELD_UNDER_FIX,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

export function clearFieldUnderFixAction(form) {
  return {
    type: CLEAR_FIELD_UNDER_FIX,
    meta: {
      form,
    }
  }
}

export function updateDependencyTreeRenderTypeAction(fields, form) {
  return {
    type: UPDATE_DEPENDENCY_TREE_RENDER_TYPE,
    payload: {
      fields,
    },
    meta: {
      form,
    }
  }
}

export function setFieldUnderCorrectNoteWritingAction(field, label, form) {
  return {
    type: SET_FIELD_UNDER_CORRECT_NOTE_WRITING,
    payload: {
      field,
      label,
    },
    meta: {
      form,
    }
  }
}

export function clearFieldUnderCorrectNoteWritingAction(form) {
  return {
    type: CLEAR_FIELD_UNDER_CORRECT_NOTE_WRITING,
    meta: {
      form,
    }
  }
}

export function setFieldCorrectNoteAction(field, correctNote, form) {
  return {
    type: SET_FIELD_CORRECT_NOTE,
    payload: {
      field,
      correctNote,
    },
    meta: {
      form,
    }
  }
}

export function clearFieldCorrectNoteAction(field, form) {
  return {
    type: CLEAR_FIELD_CORRECT_NOTE,
    payload: {
      field,
    },
    meta: {
      form,
    }
  }
}

export function setRejectFormSubmitConfirmationAction(form) {
  return {
    type: SET_REJECT_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function clearRejectFormSubmitConfirmationAction(form) {
  return {
    type: CLEAR_REJECT_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function setCorrectFormSubmitConfirmationAction(form) {
  return {
    type: SET_CORRECT_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function clearCorrectFormSubmitConfirmationAction(form) {
  return {
    type: CLEAR_CORRECT_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function setApproveFormSubmitConfirmationAction(form) {
  return {
    type: SET_APPROVE_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function clearApproveFormSubmitConfirmationAction(form) {
  return {
    type: CLEAR_APPROVE_FORM_SUBMIT_CONFIRMATION,
    meta: {
      form,
    }
  }
}

export function setCurrentSectionAction(sectionSerial, form) {
  return {
    type: SET_CURRENT_SECTION,
    payload: {
      sectionSerial,
    },
    meta: {
      form,
    }
  }
}

export function setSubmitAttemptedAction(form) {
  return {
    type: SET_SUBMIT_ATTEMPTED,
    meta: {
      form,
    }
  }
}

export function showFormHistoryAction(form) {
  return {
    type: SHOW_FORM_HISTORY,
    meta: {
      form,
    }
  }
}

export function hideFormHistoryAction(form) {
  return {
    type: HIDE_FORM_HISTORY,
    meta: {
      form,
    }
  }
}

export function pushMultiFieldValueAction(field, value, form) {
  return {
    type: PUSH_MULTI_FIELD_VALUE,
    payload: {
      field,
      value,
    },
    meta: {
      form,
    }
  }
}

export function removeMultiFieldValueAction(field, index, form) {
  return {
    type: REMOVE_MULTI_FIELD_VALUE,
    payload: {
      field,
      index,
    },
    meta: {
      form,
    }
  }
}

export function reHydrate(ecrForm, form) {
  return {
    type: REHYDRATE,
    payload: {
      ecrForm,
    },
    meta: {
      form,
    }
  }
}

export function sweepFormValuesAction(formDescriptor, form) {
  return {
    type: SWEEP_FORM_VALUES,
    payload: {
      formDescriptor,
    },
    meta: {
      form,
    }
  }
}

export function formSubmissionSuccessAction(form) {
  return {
    type: FORM_SUBMISSION_SUCCESS,
    meta: {
      form,
    }
  }
}

export function formSubmissionFailedAction(form) {
  return {
    type: FORM_SUBMISSION_FAILED,
    meta: {
      form
    }
  }
}

export function closeAllConfirmationModalAction(form) {
  return {
    type: CLOSE_ALL_CONFIRMATION_MODAL,
    meta: {
      form
    }
  }
}

export function setFormSectionListPaneAction(form) {
  return {
    type: SET_FORM_SECTION_LIST_PANE,
    meta: {
      form,
    }
  }
}

export function clearFormSectionListPaneAction(form) {
  return {
    type: CLEAR_FORM_SECTION_LIST_PANE,
    meta: {
      form,
    }
  }
}

export function loadFormDataFromFile(formData, form) {
  return {
    type: LOAD_FORMDATA_FROM_FILE,
    payload: {
      formData
    },
    meta: {
      form
    }
  }
}