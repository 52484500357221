//@flow
import React from 'react'
import styled from 'styled-components/macro'
import FontAwesome from 'react-fontawesome'

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  appearance: none;

  &:hover {
    ${(props) => (props.disableHover ? '' : 'transform: scale(1.3)')}
  }
  &.default {
    color: #333;
    &:hover {
      color: #000;
    }
  }
  &.primary {
    color: #589ceb;
    &:hover {
      color: #2e82e4;
    }
  }
  &.info {
    color: #7ae2ed;
    &:hover {
      color: #2dd5e7;
    }
  }
  &.success {
    color: #5cb85c;
    &:hover {
      color: #23a123;
    }
  }
  &.warning {
    color: #dd9c45;
    &:hover {
      color: #d68a23;
    }
  }
  &.danger {
    color: #d9534f;
    &:hover {
      color: #d62c28;
    }
  }
`
type Props = {
  name?: string,
  kind?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'danger',
  disableHover?: boolean,
  styles?: Object,
  children?: Element,
  spin?: boolean,
}

class IconButton extends React.Component<Props> {

  render() {
    const {
      name,
      children,
      disableHover = false,
      kind = 'default',
      styles, // eslint-disable-line
      spin,
      onClick,
      'data-testid': testId,
      className,
      ...rest
    } = this.props
    return (
      <Button
        className={`${kind} ${className}`}
        disableHover={disableHover}
        onClick={onClick}
        data-testid={testId}
        {...rest}
      >
        {name ? <FontAwesome name={name} spin={spin} {...rest} /> : children}
      </Button>
    )
  }
}

export default IconButton
