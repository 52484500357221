// @flow
import PropTypes from 'prop-types'
import FormDescriptor from '../../services/generator/form_descriptor'
import { sweepFormValuesAction } from './actions'
import store from '../../services/store'

export default function FormValuesSweeperHOC() {

  return function (WrappedForm) {

    return class extends WrappedForm {

      static contextTypes = {
        formDescriptor: PropTypes.instanceOf(FormDescriptor),
      }

      /**
       * Override Form's submitHandler function
       *
       * @param {SyntheticEvent} evt
       * @param {string} submitType
       */
      async submitHandler(evt: SyntheticEvent<*>, submitType: 'draft' | 'final') {
        if (evt) {
          evt.preventDefault()
        }
        store.dispatch(sweepFormValuesAction(this.context.formDescriptor, 'generatedForm'))
        // give a chance to React to refresh redux spy's valus subscription
        await new Promise((resolve) => setTimeout(() => resolve(), 0))
        super.submitHandler(evt, submitType)
      }

    }

  }
  
}
