// @flow
import get from 'lodash/get'

export function formatRawApiError(err: Object, fallbackMessage: string = "Error"): string {
  if (err.response) {
    // If we have err.response that means that we can access response body (`data`).
    return `${get(err, ['response', 'data', 'message'])} (${err.response.status})`
  }
  // if no err.response available we return the fallback message
  return fallbackMessage
}
