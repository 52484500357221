import * as Immutable from 'immutable'
import _ from 'lodash'

var NUMBER_GROUPS = /(\d+)/g;

var naturalSortComparator = function (a, b) {
  let aa = String(a).split(NUMBER_GROUPS),
      bb = String(b).split(NUMBER_GROUPS),
      min = Math.min(aa.length, bb.length)
  for (let i = 0; i < min; i++) {
    let x = parseInt(aa[i], 10) || aa[i].toLowerCase(),
        y = parseInt(bb[i], 10) || bb[i].toLowerCase()
    if (x < y) {
      return -1
    }
    else if (x > y) {
      return 1
    }
  }
  return 0
}

/**
 * Sort immutable map keys using natural sort order.
 * @param  {Immutable.Map} srcMap Input immutable map
 * @return {Immutable.OrderedMap} Keys sorted in natural order
 */
export function toNaturalOrderedMap(srcMap) {
  return srcMap.sortBy(
    (v, k) => k,
    naturalSortComparator
  )
}

export function toFieldPrefixRegexp(field, index) {
  const path = _.toPath(field + `[${index}]`)
  return new RegExp(path.reduce((acc, pathItem, index) => {
    if (isNaN(pathItem)) {
      return acc + (index !== 0 ? '\\.' : '') + pathItem
    }
    return acc + '\\[\\d+\\]'
  }, "^"))
}

const fieldIndexArray = k => _.toPath(k).filter(x => !Number.isNaN(Number(x))).map(x => Number(x))

const isIndexArrayMatches = (a1, a2, n) =>
  _.zipWith(a1, a2, (a,b) => a === b).slice(0, n).every(x => x)

const decrementKeyIndex = (key, pos) =>
  key.split(/(\[\d+\])/).map((s,i) => i === pos*2+1 ? `[${Number(s.split('')[1])-1}]` : s).join('')

const nextIndexPos = (a1, a2, n) =>
  _.zipWith(a1.slice(0,n), a2.slice(0,n), (a,b) => a === b).lastIndexOf(false)

export function renumberMapKeys(srcMap, field, index) {
  const keys = srcMap.keySeq().toList()
  let keyMap = Immutable.Map()
  const deletedKeyPrefix = `${field}[${index}]`
  const deletedIndexArray = fieldIndexArray(deletedKeyPrefix)
  let currentIndexArray = deletedIndexArray
  let currentIndexPos = deletedIndexArray.length - 1
  let siblingCount = 0
  keys.forEach(k => {
    if (naturalSortComparator(k, deletedKeyPrefix) === -1) {
      keyMap = keyMap.set(k, k)
      if (isIndexArrayMatches(currentIndexArray, fieldIndexArray(k), currentIndexPos - 1)) {
        siblingCount += 1
      }
      return
    }
    if (isIndexArrayMatches(currentIndexArray, fieldIndexArray(k), currentIndexPos)) {
      keyMap = keyMap.set(k, decrementKeyIndex(k, currentIndexPos))
      siblingCount += 1
      return
    } else if (siblingCount === 0){
      currentIndexPos = nextIndexPos(currentIndexArray, fieldIndexArray(k), currentIndexPos)
    }
    // else if (currentIndexPos !== deletedIndexArray.length - 1) {
    //   siblingCount = 0
    // }
    if (siblingCount === 0) {
      keyMap = keyMap.set(k, decrementKeyIndex(k, currentIndexPos))
      currentIndexArray = fieldIndexArray(k)
      return
    }
    keyMap = keyMap.set(k, k)
  })
  return srcMap.mapKeys(k => keyMap.get(k))
}
// 'a[1].b[0].c[0].v': '100'
//
// 0 0
// 1 0 -> 0 0
// 1 1 -> 0 1
// 1 2 -> 0 2
// 2 0 -> 1 0
// 3 0 -> 2 0
export function storeFlatMapRemove(srcMap, field, index) {
  const regExp = toFieldPrefixRegexp(field, index)
  // split srcMap to two parts:
  // - subjects: fields related to field to be removed
  // - rest: fields unrelated to field to be removed
  const splitted = srcMap
    .groupBy((v, k) => regExp.test(k) ? 'subjects' : 'rest')
  return renumberMapKeys(
    toNaturalOrderedMap(
      splitted.get('subjects', Immutable.Map())
        .filter((v, k) => !k.startsWith(`${field}[${index}]`))
    ), field, index).merge(splitted.get('rest')
  )
}
