//@flow
import React from 'react'
import PropTypes from 'prop-types'
import ConnectedSubmitButton from './ConnectedSubmitButton'

type Props = {

}

class SubmitButton extends React.Component<Props> {

  static contextTypes = {
    _myForm: PropTypes.object,
  }

  render () {
    return React.createElement(ConnectedSubmitButton, {
      ...this.props,
      _myForm: this.context._myForm,
    })
  }
}

export default SubmitButton