import axiosInstance from './axios_instance'

export async function getAttendancePeriodFormStartData(attendancePeriodId, formTypeId) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/attendance-periods/${attendancePeriodId}/form-types/${formTypeId}/form-start-data`)
  return resp.data.results[0]
}

export async function getFormStartData(formId) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/${formId}/form-start-data`)
  return resp.data.results[0]
}

export async function getAttendanceStartFormStartData(instituteId, formTypeId) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/form-types/${formTypeId}/form-start-data`)
  return resp.data.results[0]
}