// @flow
import {
  CommonCollectionQueryParameters,
  sanitizeCommonCollectionQueryParameters,
} from '../../lib/common_query_parameters'

import axiosInstance from './axios_instance'

export async function deleteRegistryAce(id: string): Promise<void> {
  await axiosInstance.get()
    .delete(`/api/v1/registry-acl/${id}`)
}

export async function deleteBiobankAce(id: string): Promise<void> {
  await axiosInstance.get()
    .delete(`/api/v1/biobank-acl/${id}`)
}

export type CreateRegistryAcePayload = {
  registryId: string,
  formTypeId: ?string,
  instituteId: ?string,
  physicianId: ?string,
  userGroupId: string,
  permission: string,
}

export async function createRegistryAce(payload: CreateRegistryAcePayload): Promise<Object> {
  const resp = await axiosInstance.get()
    .post(`/api/v1/registry-acl`, payload)
  return resp.data.results[0]
}

export async function createBiobankAce(payload: CreateRegistryAcePayload): Promise<Object> {
  const resp = await axiosInstance.get()
    .post(`/api/v1/biobank-acl`, payload)
  return resp.data.results[0]
}

export async function getRegistryAcl(options: { includeNames: boolean } & CommonCollectionQueryParameters) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registry-acl`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options),
      }
    })
  return resp.data
}

export async function getBiobankAcl(options: { includeNames: boolean } & CommonCollectionQueryParameters) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/biobank-acl`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options),
      }
    })
  return resp.data
}

export async function getBiobankAce(id: string) {
  const resp = await axiosInstance.get().get(`/api/v1/biobank-acl/${id}`)
  return resp.data.results[0]
}

export async function getRegistryAce(id: string) {
  const resp = await axiosInstance.get().get(`/api/v1/registry-acl/${id}`)
  return resp.data.results[0]
}

export async function updateBiobankAce(id: string, payload: Object) {
  const resp = await axiosInstance.get().put(`/api/v1/biobank-acl/${id}`, payload)
  return resp.data.results[0]
}

export async function updateRegistryAce(id: string, payload: Object) {
  const resp = await axiosInstance.get().put(`/api/v1/registry-acl/${id}`, payload)
  return resp.data.results[0]
}

const registryPermissions = [
  'frm_create',
  'frm_read',
  'frm_edit',
  'frm_delete',
  'frm_destroy',
  'frm_approve_1',
  'frm_approve_2',
  'frm_approve_3',
  'frm_approve_4',
  'frm_reset_approval',
  'personal_data_access',
  'implicit_access',
]

const biobankPermissions = [
  'read_admin_bio_sample',
  'create_admin_bio_sample',
  'update_admin_bio_sample',
  'delete_admin_bio_sample',
  'read_institute_bio_sample',
  'create_institute_bio_sample',
  'update_institute_bio_sample',
  'delete_institute_bio_sample',
  'attach_bio_sample_to_form'
]

export function getPermissionIds(): Promise<string[]> {
  return new Promise((resolve, reject) => {
    resolve(registryPermissions)
  })
}

export function getPermissionFlags(): Promise<string[]> {
  return new Promise((resolve, reject) => {
    resolve(biobankPermissions)
  })
}
