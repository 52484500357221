// @flow

import * as Immutable from 'immutable'
import { every, negate, isNil } from 'lodash'
import { createPredicate, collectPredicateParameters, evaluatePredicate } from './predicate'

/**
 * This method create a function from formula and formulaParameters
 * and evulate it with the collected parameters, if every parameter is present or
 * doNotCheckParameters is true.
 * @param {string}             formula 
 * @param {string[]}           formulaParameters 
 * @param {Immutabel.Map<*,*>} values 
 * @param {boolean}            doNotCheckParameters 
 */
export function calculateCalculatedFieldValue(
  formula: string,
  formulaParameters: string[],
  values: Immutable.Map<*, *>,
  doNotCheckParameters: boolean = false
) {
  const calculator = createPredicate(formulaParameters, formula)
  const parameterValues = collectPredicateParameters(formulaParameters, values)

  if (doNotCheckParameters || every(parameterValues, negate(isNil))) {
    return evaluatePredicate(calculator, parameterValues)
  }
}
