//@flow
import React from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components/macro'
import ReactMarkdown from 'react-markdown/with-html'
import gfm from 'remark-gfm'

const HintSpan = styled.span`
  font-size: ${(props) => String(props.fontSize)}px;
  display: flex;
  color: #888;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  & > p {
    margin: 0;
  }

  &.important {
    color: red;
  }

  .fa {
    padding-right: 5px;
  }

  div p {
    margin: 0;
  }
`

type Props = {
  label: string,
  important?: boolean,
  children?: React$Element<*>,
  fontSize?: number,
  margin?: string,
}

const changeBRTags = (text: string) => {
  if (!text.includes('</br>')) return text
  return text.replace(/<\/br>/g, '<br/>')
}

const Hint = (props: Props) => {
  return (
    <HintSpan
      className={props.important ? 'important' : ''}
      fontSize={props.fontSize || 12}
      margin={props.margin}
    >
      <FontAwesome name="info-circle" />
      {props.label ? (
        <ReactMarkdown
          escapeHtml={false}
          plugins={[gfm]}
          source={changeBRTags(props.label)}
        />
      ) : (
        props.children
      )}
    </HintSpan>
  )
}

export default Hint
