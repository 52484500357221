// @flow
import React from 'react'
import { connect } from 'react-redux'
import * as Immutable from 'immutable';
import FontAwesome from 'react-fontawesome'
import _ from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'

// imported this way because of circular dependencies
import MultiField from '../../lib/ecr_form/MultiField'
import {
  getIn,
  storePath,
} from './utils'
import {
  showFormFieldHistoryAction,
} from '../../services/actions'
import {
  registerFieldAction,
  setFieldUnderRejectionAction,
  setFieldUnderCorrectNoteWritingAction,
  unregisterFieldAction,
} from './actions'
import {
  FIELD_DISPLAY_MODE_REJECTED,
  FIELD_DISPLAY_MODE_CORRECTED,
  FIELD_DISPLAY_MODE_DIRTY,
  FIELD_RENDER_TYPE_CORRECT,
  FIELD_RENDER_TYPE_CORRECT_FIX,
  FIELD_RENDER_TYPE_EDIT,
  FIELD_RENDER_TYPE_INSPECT_REJECT,
  FIELD_RENDER_TYPE_NEW,
  FIELD_RENDER_TYPE_READ,
  FIELD_TYPE_COMPLEX_FIELD,
  FORM_VIEW_TYPE_NEW,
  FORM_VIEW_TYPE_EDIT,
  FORM_VIEW_TYPE_INSPECT,
  FORM_VIEW_TYPE_CORRECT,
} from './constants'
import ContentWithIcon from '../../components/ContentWithIcon'
import FieldValueDisplay from '../../components/FieldValueDisplay'
import SimpleTextFormat from '../../components/SimpleTextFormat'
import FormDescriptor from '../../services/generator/form_descriptor'

const RejectInfo = styled.div`
  font-size: 13px;
`

const RejectReason = styled.div`
  color: #EF4836;
`

const CorrectNote = styled.div`
  color: #dd9c45;
`

const ComplexFieldWrapper = styled.div`
  padding: 5px 20px 5px 30px;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 4px rgba(0,0,0,.1);
  border-left: 5px solid transparent;
  border-right: 1px solid rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 8px;
  border-color: ${props => props.rejected && '#EF4836'};
  border-color: ${props => props.corrected && '#FFCC00'};
  border-color: ${props => props.dirty && '#89C4F4'};
`

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  padding: 10px 0 15px 0;
  color: #000;
`

type Props = {
  _myForm: Object,
  component: React$Element<*>,
  correctedField?: Immutable.Map<*, *>,
  correctNote?: string,
  displayMode: string,
  fieldUnderFix?: string,
  formId?: string,
  intl: Object,
  label: string,
  showFormFieldHistoryAction: Function,
  name: string,
  rejectedField?: Immutable.Map<*, *>,
  registerFieldAction: Function,
  renderType: string,
  setFieldUnderRejectionAction: Function,
  setFieldUnderCorrectNoteWritingAction: Function,
  unregisterFieldAction: Function,
  value: Immutable.Map<*, *>,
  viewType: string,
  _parentSection: number,
  formDescriptor: FormDescriptor,
}

class ConnectedComplexField extends React.Component<Props> {

  componentDidMount() {
    this.props.registerFieldAction(
      this.props.name,
      this.props._parentSection,
      FIELD_TYPE_COMPLEX_FIELD,
      this.props._myForm.name,
    )
  }

  componentWillUnmount () {
    this.props.unregisterFieldAction(this.props.name, this.props._myForm.name)
  }

  fieldHistoryButtonClickHandler = () => {
    this.props.showFormFieldHistoryAction(
      this.props.formId,
      this.props.name,
      this.props.label
    )
  }

  rejectButtonClickHandler = () => {
    this.props.setFieldUnderRejectionAction(
      this.props.name,
      this.props._myForm.name,
    )
  }

  correctNoteButtonClickHandler = () => {
    this.props.setFieldUnderCorrectNoteWritingAction(
      this.props.name,
      this.props.label,
      this.props._myForm.name,
    )
  }

  // TODO: field history button handling
  render() {
    const {
      intl: { formatMessage },
      component,
      correctedField,
      correctNote,
      displayMode,
      fieldUnderFix,
      formDescriptor,
      label,
      name,
      rejectedField,
      renderType,
      value = Immutable.Map(),
      viewType,
    } = this.props
    const renderRejectButton = this.props.renderType === FIELD_RENDER_TYPE_INSPECT_REJECT
    const renderCorrectNoteButton = this.props.renderType === FIELD_RENDER_TYPE_CORRECT
    const renderFieldHistoryButton = this.props.renderType !== FIELD_RENDER_TYPE_NEW
    const allowAddRemove =
      _.includes([FORM_VIEW_TYPE_NEW, FORM_VIEW_TYPE_EDIT], viewType)
      || (viewType === FORM_VIEW_TYPE_INSPECT && renderType !== FIELD_RENDER_TYPE_READ && !rejectedField)
      || (viewType === FORM_VIEW_TYPE_CORRECT
        && _.includes([
          FIELD_RENDER_TYPE_CORRECT,
          FIELD_RENDER_TYPE_CORRECT_FIX,
          FIELD_RENDER_TYPE_EDIT,
        ], renderType))

    const actualRejectReason = (rejectedField && rejectedField.get('rejectReason'))
      || (correctedField && correctedField.get('rejectReason'))
    const actualRejectedValue = (rejectedField && rejectedField.get('rejectedValue'))
      || (correctedField && correctedField.get('rejectedValue'))
    const actualCorrectNote = correctNote || (correctedField && correctedField.get('correctNote'))
    return (
      <ComplexFieldWrapper
        rejected={ displayMode === FIELD_DISPLAY_MODE_REJECTED }
        corrected={ displayMode === FIELD_DISPLAY_MODE_CORRECTED }
        dirty={ displayMode === FIELD_DISPLAY_MODE_DIRTY }
      >
        <div className={'form-group top-label'}>
          <Label>
            {label}
            {(renderRejectButton || renderCorrectNoteButton || renderFieldHistoryButton) &&
              <div className={'field-actions'}>
                {renderFieldHistoryButton &&
                  <button
                    className={'history-button'}
                    onClick={this.fieldHistoryButtonClickHandler}
                    title={ formatMessage({ id: 'tooltip.fieldHistory' }) }
                    >
                    <FontAwesome name="history"/>
                  </button>}
                {renderRejectButton &&
                  <button
                    className={'reject-button'}
                    onClick={fieldUnderFix ? () => {} : this.rejectButtonClickHandler}
                    title={ formatMessage({ id: 'tooltip.reject' }) }
                    >
                    <FontAwesome name="ban" style={{ cursor: fieldUnderFix ? 'not-allowed' : 'pointer' }}/>
                  </button>}
                {renderCorrectNoteButton &&
                  <button
                    className={'correct-note-button'}
                    onClick={fieldUnderFix ? () => {} : this.correctNoteButtonClickHandler}
                    title={ formatMessage({ id: 'tooltip.correctNote' }) }
                    >
                    <FontAwesome name="comment" style={{ cursor: fieldUnderFix ? 'not-allowed' : 'pointer' }}/>
                  </button>}

              </div>}
          </Label>
          <div>
            {actualRejectReason &&
              <RejectInfo>
                <RejectReason title={ formatMessage({ id: 'rejectFieldModal.label.rejectReason' }) }>
                  <ContentWithIcon icon="ban">
                    <SimpleTextFormat text={actualRejectReason} />
                  </ContentWithIcon>
                  {(displayMode === FIELD_DISPLAY_MODE_CORRECTED) &&
                    <ContentWithIcon icon="strikethrough">
                      {actualRejectedValue
                        ? <FieldValueDisplay
                            value={formDescriptor.transformValue(
                              name,
                              actualRejectedValue
                            )}
                            />
                        : <FormattedMessage id={'form.noValue'} />}
                    </ContentWithIcon>}
                </RejectReason>
                {(displayMode === FIELD_DISPLAY_MODE_CORRECTED) && actualCorrectNote &&
                  <CorrectNote title={ formatMessage({ id: 'tooltip.correctNote' }) }>
                    <ContentWithIcon icon="comment">
                      <SimpleTextFormat text={actualCorrectNote} />
                    </ContentWithIcon>
                  </CorrectNote>}
              </RejectInfo>}
            <MultiField
              component={component}
              count={value.size}
              name={name}
              allowAdd={allowAddRemove}
              allowRemove={allowAddRemove}
            />
          </div>
        </div>
      </ComplexFieldWrapper>
    )
  }
}

export default injectIntl(connect((state, ownProps) => ({
  displayMode: state.getIn(storePath(['displayModes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  fieldUnderFix: state.getIn(storePath(['fieldUnderFix'], ownProps._myForm.name, 'ecrForm')),
  rejectedField: state.getIn(storePath(['rejectedFields', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  correctedField: state.getIn(storePath(['correctedFields', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  correctNote: state.getIn(storePath(['correctNotes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  renderType: state.getIn(storePath(['renderTypes', ownProps.name], ownProps._myForm.name, 'ecrForm')),
  value: getIn(state, `values.${ownProps.name}`, ownProps._myForm.name, 'ecrForm'),
  viewType: state.getIn(storePath(['viewType'], ownProps._myForm.name, 'ecrForm')),
}), {
  showFormFieldHistoryAction,
  registerFieldAction,
  setFieldUnderRejectionAction,
  setFieldUnderCorrectNoteWritingAction,
  unregisterFieldAction,
})(ConnectedComplexField))
