import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  LOGOUT,
  PASSWORD_AUTH_REQUEST,
  PASSWORD_AUTH_FAILURE,
  PASSWORD_AUTH_SUCCESS,
  MFA_AUTH_REQUIRED,
  MFA_AUTH_REQUEST,
  MFA_AUTH_FAILURE,
  MFA_AUTH_SUCCESS,
  MFA_SETUP_REQUIRED,
  MFA_RECOVERY_KEY_REQUIRED,
  MFA_RECOVERY_KEY_REQUEST,
  MFA_RECOVERY_KEY_FAILURE,
  REFRESH_ACCESS_TOKEN,
  BACK_TO_MFA_AUTH_REQUIRED,
} from './actions'

const initialState = Immutable.fromJS({
  accessToken: null, // { token: string, expiresAt: Date }
  // | 'loggedOut'
  // | 'loggedIn'
  // | 'passwordAuthRequest'
  // | 'passwordAuthFailure'
  // | 'mfaAuthRequired'
  // | 'mfaAuthRequest'
  // | 'mfaAuthFailure'
  // | 'mfaSetupRequired'
  step: 'loggedOut',
  mfaAccessToken: null, // string
  errorMessage: null, // string
  mfaEnforcedAt: null, // timestamp
})

export default createReducer(initialState, {
  [LOGOUT]: (state, action) =>
    state.merge({
      step: 'loggedOut',
      accessToken: null,
      mfaSetupAccessToken: null,
      errorMessage: null,
    }),
  [PASSWORD_AUTH_REQUEST]: (state, action) =>
    state.merge({
      step: 'passwordAuthRequest',
      accessToken: null,
      mfaSetupAccessToken: null,
      errorMessage: null,
    }),
  [PASSWORD_AUTH_FAILURE]: (state, action) =>
    state.merge({
      step: 'passwordAuthFailure',
      errorMessage: action.payload.errorMessage,
    }),
  [PASSWORD_AUTH_SUCCESS]: (state, action) =>
    state.merge({
      step: 'loggedIn',
      accessToken: action.payload.accessToken,
      mfaSetupAccessToken: null,
      errorMessage: null,
    }),
  [MFA_AUTH_REQUIRED]: (state, action) =>
    state.merge({
      step: 'mfaAuthRequired',
      mfaAccessToken: action.payload.mfaAccessToken,
    }),
  [BACK_TO_MFA_AUTH_REQUIRED]: (state, action) =>
    state.merge({
      step: 'mfaAuthRequired',
    }),
  [MFA_AUTH_REQUEST]: (state, action) =>
    state.merge({
      step: 'mfaAuthRequest',
    }),
  [MFA_AUTH_FAILURE]: (state, action) =>
    state.merge({
      step: 'mfaAuthFailure',
      errorMessage: action.payload.errorMessage,
    }),
  [MFA_AUTH_SUCCESS]: (state, action) =>
    state.merge({
      step: 'loggedIn',
      accessToken: action.payload.accessToken,
      mfaSetupAccessToken: null,
      errorMessage: null,
    }),
  [MFA_SETUP_REQUIRED]: (state, action) =>
    state.merge({
      step: 'mfaSetupRequired',
      mfaAccessToken: action.payload.mfaAccessToken,
      mfaEnforcedAt: action.payload.mfaEnforcedAt,
    }),
  [MFA_RECOVERY_KEY_REQUIRED]: (state, action) =>
    state.merge({
      step: 'mfaRecoveryKeyRequired',
    }),
  [MFA_RECOVERY_KEY_REQUEST]: (state, action) =>
    state.merge({
      step: 'mfaRecoveryKeyRequest',
    }),
  [MFA_RECOVERY_KEY_FAILURE]: (state, action) =>
    state.merge({
      step: 'mfaRecoveryKeyFailure',
      errorMessage: action.payload.errorMessage,
    }),
  [REFRESH_ACCESS_TOKEN]: (state, action) =>
    state.merge({
      accessToken: action.payload.accessToken,
    }),
})
