//@flow
import React from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components/macro'
import { memoize } from 'lodash'
import ReactMarkdown from 'react-markdown/with-html'
import { FormattedMessage } from 'react-intl'
import gfm from 'remark-gfm'

const Input = styled.input`
  transition: all 0.30s ease-in-out;
  padding: .5rem .75rem;
  font-size: 14px;
  line-height: 1.25em;
  outline: none;
  border: 1px solid #aaaaaa;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  background-clip: padding-box;
  background-image: none;
  display: block;
  word-break: break-word;
  width: ${props => props.width ?? 'auto'};

  &:disabled {
    background-color: #eee;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(37, 197, 255, 1);
    border: 1px solid rgba(37, 197, 255, 1);
  }

  &:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
`

export const ValueDisplay = styled.span`
  word-break: break-word;
`

const InputGroupAddon = styled.span`
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  p,
  div {
    margin: 0;
  }

  &:not(:first-child) {
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:not(:last-child) {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  /* margin: 5px 1px 3px 0px; */
  max-height: 35px;
`

const NoValueContianer = styled.div`
  color: #aaa;
`

export type DecorationOptions = {
  postFixLabel?: string,
  preFixLabel?: string,
  postFixIcon?: string,
  preFixIcon?: string,
  postFixMarkdownLabel?: string,
  preFixMarkdownLabel?: string,
}

type Props = {
  datePicker?: boolean,
  decorationOptions?: DecorationOptions,
  onBlur?: (event: SyntheticEvent) => void,
  onChange: (value: SyntheticInputEvent) => void,
  onFocus?: (event: SyntheticEvent) => void,
  placeholder?: string,
  readOnly?: boolean,
  value: string,
  type?: string,
  hint?: string,
  important?: boolean,
}

const markdown = memoize((label: string) => {
  const root = props => (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
  return (
    <ReactMarkdown
      plugins={[gfm]}
      data-name={label}
      escapeHtml={false} 
      source={label} 
      renderers={{root}}
      />
  )
})

const DecoratedInputField = React.forwardRef<*, *>((props: Props, ref) => {

  const renderInputField = () => {
    const {
      readOnly = false,
      value = '',
      type,
      datePicker,
      'data-testid': testId,
      ...rest
    } = props

    if (readOnly) {
      if (value) {
        return (
          <div>
            <ValueDisplay data-testid={testId}>{value}</ValueDisplay>
          </div>
        )
      } else {
        return (
          <NoValueContianer>
            <FormattedMessage id={'form.noValue'} data-testid={testId}/>
          </NoValueContianer>
        )
      }
    }

    return (
      <InputGroup>
        <Input
          ref={ref}
          readOnly={datePicker}
          type={type || "text"}
          value={value}
          data-testid={testId}
          {...rest}
        />
      </InputGroup>
    )
  }

  const renderPostFix = () => {
    const {
      readOnly = false,
      value = '',
      type,
      decorationOptions = {},
      datePicker,
      'data-testid': testId,
      ...rest
    } = props
  
    const {
      postFixIcon,
      postFixLabel,
      postFixMarkdownLabel,
    } = decorationOptions
    if (readOnly) {
      if (value) {
        if (postFixMarkdownLabel || postFixLabel) {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{`${value} ${postFixMarkdownLabel ? markdown(postFixMarkdownLabel) : postFixLabel}`}</ValueDisplay>
            </div>
          )
        } else {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{value}</ValueDisplay>
            </div>
          )
        }
      } else {
        return (
          <NoValueContianer>
            <FormattedMessage id={'form.noValue'} data-testid={testId}/>
          </NoValueContianer>
        )
      }
    }

    return (
      <div>
        <InputGroup>
          <Input
            type={type || "text"}
            value={value}
            readOnly={datePicker}
            data-testid={testId}
            {...rest}
          />
          <InputGroupAddon data-testid='DecoratedInputField-postfix'>
            {postFixIcon ? <FontAwesome name={postFixIcon}/> : postFixMarkdownLabel ? markdown(postFixMarkdownLabel) : postFixLabel }
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }

  const renderPreFix = () => {
    const {
      readOnly = false,
      value = '',
      type,
      decorationOptions = {},
      datePicker,
      'data-testid': testId,
      ...rest
    } = props
  
    const {
      preFixIcon,
      preFixLabel,
      preFixMarkdownLabel,
    } = decorationOptions
    if (readOnly) {
      if (value) {
        if (preFixMarkdownLabel || preFixLabel) {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{`${preFixMarkdownLabel ? markdown(preFixMarkdownLabel) : preFixLabel} ${value}`}</ValueDisplay>
            </div>
          )
        } else {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{value}</ValueDisplay>
            </div>
          )
        }
      } else {
        return (
          <NoValueContianer>
            <FormattedMessage id={'form.noValue'} data-testid={testId}/>
          </NoValueContianer>
        )
      }
    }

    return (
      <div>
        <InputGroup>
          <InputGroupAddon data-testid='DecoratedInputField-prefix'>
            {preFixIcon ? <FontAwesome name={preFixIcon}/> : preFixMarkdownLabel ? markdown(preFixMarkdownLabel) : preFixLabel}
          </InputGroupAddon>
          <Input
            type={type || "text"}
            value={value}
            readOnly={datePicker}
            data-testid={testId}
            {...rest}
          />
        </InputGroup>
      </div>
    )
  }

  const renderPostPreFix = () => {
    const {
      readOnly = false,
      value = '',
      type,
      decorationOptions = {},
      datePicker,
      'data-testid': testId,
      ...rest
    } = props
  
    const {
      preFixIcon,
      preFixLabel,
      preFixMarkdownLabel,
      postFixIcon,
      postFixLabel,
      postFixMarkdownLabel,
    } = decorationOptions
    if (readOnly) {
      if (value) {
        if ((preFixLabel && postFixLabel) || (postFixMarkdownLabel && preFixMarkdownLabel)) {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{`${preFixMarkdownLabel ? markdown(preFixMarkdownLabel) : preFixLabel} ${value} ${postFixMarkdownLabel ? markdown(postFixMarkdownLabel) : postFixLabel}`}</ValueDisplay>
            </div>
          )
        } else {
          return (
            <div>
              <ValueDisplay data-testid={testId}>{value}</ValueDisplay>
            </div>
          )
        }
      } else {
        return (
          <NoValueContianer>
            <FormattedMessage id={'form.noValue'} data-testid={testId}/>
          </NoValueContianer>
        )
      }
    }

    return (
      <div>
        <InputGroup>
          <InputGroupAddon data-testid='DecoratedInputField-prefix'>
            {preFixIcon ? <FontAwesome name={preFixIcon}/> : preFixMarkdownLabel ? markdown(preFixMarkdownLabel) : preFixLabel}
          </InputGroupAddon>
          <Input
            type={type || "text"}
            value={value}
            readOnly={datePicker}
            data-testid={testId}
            {...rest}
          />
          <InputGroupAddon data-testid='DecoratedInputField-postfix'>
            {postFixIcon ? <FontAwesome name={postFixIcon}/> : postFixMarkdownLabel ? markdown(postFixMarkdownLabel) : postFixLabel}
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }

  const renderField = () => {
    const {
      decorationOptions = {},
    } = props
  
    const {
      preFixIcon,
      preFixLabel,
      preFixMarkdownLabel,
      postFixIcon,
      postFixLabel,
      postFixMarkdownLabel,
    } = decorationOptions

    if ((preFixIcon && postFixIcon) ||
        (preFixIcon && (postFixLabel || postFixMarkdownLabel)) ||
        ((preFixLabel || preFixMarkdownLabel) && (postFixLabel || postFixMarkdownLabel)) ||
        ((preFixLabel || preFixMarkdownLabel) && postFixIcon)) {
      return renderPostPreFix()
    } else if (preFixIcon || (preFixLabel || preFixMarkdownLabel)) {
      return renderPreFix()
    } else if (postFixIcon || (postFixLabel || postFixMarkdownLabel)) {
      return renderPostFix()
    } else {
      return renderInputField()
    }
  }

  return renderField()
  
})

export default DecoratedInputField
