// @flow
import axiosInstance from './axios_instance'

export async function getStatisticApprovalLevels() {
  const resp = await axiosInstance
    .get()
    .get('/api/v1/statistics/approval-levels', { responseType: 'blob' })
  return resp
}

export async function getStatisticUserActivity() {
  const resp = await axiosInstance
    .get()
    .get('/api/v1/statistics/user-activity', { responseType: 'blob' })
  return resp
}

export async function getStatisticRejectionCount() {
  const resp = await axiosInstance
    .get()
    .get('/api/v1/statistics/rejection-count', { responseType: 'blob' })
  return resp
}

export async function getFollowUp(payload: Object) {
  // const url = qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'comma' })
  const url = Object.keys(payload).map((v) => `${v}=${payload[v].join(',')}`).join('&')
  const resp = await axiosInstance.get()
    .get(`/api/v1/statistics/follow-up?${url}`, { responseType: 'blob' })
  return resp
}