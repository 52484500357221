import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getUser(id, { withInstitute = false } = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/users/${id}`, {
      params: {
        withInstitute,
      }
    })
  return resp.data.results[0]
}

export async function getUsers({ offset, limit, filter, sort } = {}) {
  const resp = await axiosInstance.get()
    .get('/api/v1/users', {
      params: sanitizeCommonCollectionQueryParameters({ offset, limit, filter, sort }),
    })
  return resp.data
}

export async function createUser(payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/users`, payload)
  return resp.data.results[0]
}

export async function updateUser(id, payload) {
  const resp = await axiosInstance.get()
    .put(`/api/v1/users/${id}`, payload)
  return resp.data.results[0]
}

export async function deleteUser(id) {
  await axiosInstance.get()
    .delete(`/api/v1/users/${id}`)
}

export async function getInstitutePhysicians(instituteId) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/physicians`)
  return resp.data.results
}

export async function getInstituteAccessiblePhysicians(instituteId, formTypeId) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/accessible-physicians/${formTypeId}`)
  return resp.data.results
}
