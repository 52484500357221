// @flow
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import * as Immutable from 'immutable'
import { Prompt } from 'react-router-dom'

import './form.css'
import {
  clearSubmitTriggerAction,
  destroyFormAction,
  initFormAction,
} from './actions'
import { storePath } from './utils'
import { ValidatorHOC } from '../../services/validator'
import { FormValidator } from 'form-validators'
import { setSyncErrorsAction } from '../../lib/ecr_form/actions'
import FormValuesSweeperHOC from './form_values_sweeper_hoc'
import calculateFieldDependencyGraph from './field_dependency_graph_calculator'
import { FIELD_TYPE_FIELD } from './constants'
import reduxSpy from '../redux_spy'

import type MyFormType from './types'

const zeroSectionFieldTypes = {
  patient: FIELD_TYPE_FIELD,
  instituteId: FIELD_TYPE_FIELD,
  physicianId: FIELD_TYPE_FIELD,
}

type Props = {
  children: Function,
  clearSubmitTriggerAction: Function,
  correctNotes?: Immutable.Map<*, *>,
  currentSection: number,
  destroyFormAction: Function,
  fieldUnderFix?: string,
  hasRejectedFields?: boolean,
  hasRejectedNotCorrectedFields?: boolean,
  initialCorrectedFields?: Object,
  initialCorrectNotes?: Object,
  initialRejectedFields?: Object,
  initialValues: Object,
  initFormAction: Function,
  +intl: Object,
  name: string,
  onSubmit: (
    values: Object,
    options: {
      correctNotes?: Object,
      rejectedFields?: Object,
      submitType?: string,
    }
  ) => void,
  rejectedFields?: Immutable.Map<*, *>,
  sectionCount: number,
  triggerSubmit?: string,
  formValidator: FormValidator,
  values: Immutable.Map<*, *>,
  viewType: 'read' | 'new' | 'edit' | 'inspect' | 'correct',
  spy: string => any,
  history: Object,
  intl: Object,
}

const isFormDirty = (fieldStates: Immutable.Map<string, Object>): boolean => {
  if (!fieldStates || fieldStates.isEmpty()) return false
  return fieldStates.some((fs) => fs.get('dirty'))
}

const checkFormDisplayModes = (
  displayModes: Immutable.Map<string, Object>,
  displayMode: string
): boolean => {
  if (!displayModes || displayModes.isEmpty()) return false
  return displayModes.some(dm => dm === displayMode)
}


class Form extends React.Component<Props> {

  static childContextTypes = {
    _myForm: PropTypes.object,
  }

  static contextTypes = {
    formDescriptor: PropTypes.object,
  }

  getChildContext() {
    const _myForm: MyFormType = {
      name: this.props.name,
      submitHandler: this.submitHandler,
      formValidator: this.props.formValidator,
    }
    return {
      _myForm,
    }
  }

  static defaultProps = {
    initialValues: {},
  }

  UNSAFE_componentWillMount() {
    const { dependencies: fieldDependencyGraph, fieldTypes } = calculateFieldDependencyGraph(this.context.formDescriptor)
    this.props.initFormAction({
      viewType: this.props.viewType,
      initialValues: this.props.initialValues,
      rejectedFields: this.props.initialRejectedFields,
      correctedFields: this.props.initialCorrectedFields,
      correctNotes: this.props.initialCorrectNotes,
      sectionCount: this.props.sectionCount,
      form: this.props.name,
      currentSection: this.props.currentSection,
      fieldSections: this.context.formDescriptor.fieldSections,
      fieldDependencyGraph,
      fieldTypes: {
        ...fieldTypes,
        ...zeroSectionFieldTypes,
      }
    })
  }

  /**
   * @todo it should be tested
   */
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.currentSection !== nextProps.currentSection
  //     || this.props.fieldUnderFix !== nextProps.fieldUnderFix
  //     || this.props.hasRejectedFields !== nextProps.hasRejectedFields
  //     || this.props.hasRejectedNotCorrectedFields !== nextProps.hasRejectedNotCorrectedFields
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Submit can be triggered by setting `triggerSubmit` flag
    // in redux store. This call checks the flag and calls
    // `submitHandler` if necessary. This mechanism allows to
    // submit the form "remotely".
    this.submitIfNeeded(nextProps)
  }

  componentWillUnmount() {
    this.props.destroyFormAction(this.props.name)
  }

  /**
   * Checks `triggerSubmit` prop which is connected to the store.
   * If `triggerSubmit` has changed to true it clears the flag
   * and initiates submit by calling `submitHandler`.
   * @param {Props} nextProps
   */
  submitIfNeeded(nextProps) {
    if (!this.props.triggerSubmit && nextProps.triggerSubmit) {
      const submitType = nextProps.triggerSubmit
      this.submitHandler(null, submitType)
      this.props.clearSubmitTriggerAction(this.props.name)
    }
  }

  submitHandler(evt, submitType) {
    if (evt) {
      evt.preventDefault()
    }
    if (this.props.onSubmit) {
      const values = this.props.spy('values').toJS()
      this.props.onSubmit(values, {
        correctNotes: this.props.correctNotes && this.props.correctNotes.toJS(),
        rejectedFields:
          this.props.rejectedFields && this.props.rejectedFields.toJS(),
        submitType,
      })
    }
  }

  render() {
    if (_.isFunction(this.props.children)) {
      return (
        <React.Fragment>
          <Prompt
            message={location => {
              if (this.props.history.location.pathname === location.pathname) {
                return true
              }
              if (this.props.spy('formSubmissionSuccess')) return true
              if (
                isFormDirty(this.props.spy('fieldStates')) ||
                (this.props.viewType === 'correct' && checkFormDisplayModes(this.props.spy('displayModes'), 'corrected')) ||
                (this.props.viewType === 'inspect' && checkFormDisplayModes(this.props.spy('displayModes'), 'rejected'))
              ) {
                return this.props.intl.formatMessage({
                  id: 'navigation.prompt',
                })
              }
              return true
            }}
          />
          {this.props.children({
            currentSection: this.props.currentSection,
            fieldUnderFix: this.props.fieldUnderFix,
            hasRejectedFields: this.props.hasRejectedFields,
            hasRejectedNotCorrectedFields: this.props
              .hasRejectedNotCorrectedFields,
          })}
        </React.Fragment>
      )
    }
    return <div className="form">{this.props.children}</div>
  }
}

export default connect(
  (state, ownProps) => ({
    correctNotes: state.getIn(
      storePath(['correctNotes'], ownProps.name, 'ecrForm'),
      Immutable.Map()
    ),
    fieldUnderFix: state.getIn(
      storePath(['fieldUnderFix'], ownProps.name, 'ecrForm')
    ),
    hasRejectedFields: state.getIn(
      storePath(['hasRejectedFields'], ownProps.name, 'ecrForm')
    ),
    hasRejectedNotCorrectedFields: state.getIn(
      storePath(['hasRejectedNotCorrectedFields'], ownProps.name, 'ecrForm')
    ),
    rejectedFields: state.getIn(
      storePath(['rejectedFields'], ownProps.name, 'ecrForm'),
      Immutable.Map()
    ),
    triggerSubmit: state.getIn(
      storePath(['triggerSubmit'], ownProps.name, 'ecrForm')
    ),
    // values: state.getIn(storePath(['values'], ownProps.name, 'ecrForm'), Immutable.Map()),
  }),
  {
    clearSubmitTriggerAction,
    destroyFormAction,
    initFormAction,
    setSyncErrorsAction,
  }
)(
  reduxSpy(state => ({
    values: state.getIn(
      ['ecrForm', 'generatedForm', 'values'],
      Immutable.Map()
    ),
    fieldStates: state.getIn(
      ['ecrForm', 'generatedForm', 'fieldStates'],
      Immutable.Map()
    ),
    formSubmissionSuccess: state.getIn([
      'ecrForm',
      'generatedForm',
      'formSubmissionSuccess',
    ]),
    hasRejectedNotCorrectedFields: state.getIn([
      'ecrForm',
      'generatedForm',
      'hasRejectedFields',
    ]),
    displayModes: state.getIn(['ecrForm', 'generatedForm', 'displayModes']),
  }))(
    FormValuesSweeperHOC()(
      ValidatorHOC({
        formName: 'generatedForm',
      })(Form)
    )
  )
)
