// @flow

import axiosInstance from './axios_instance'
/**
 * Returns the form descriptor of the `active` form type
 * with the specified `formTypeId` and `locale`. If `locale`
 * is not provided then it returns a form descriptor with
 * any locale, preferably `en`.
 * @param  {string} formTypeId Id of the form type (e.g. 'AP-A')
 * @param  {string} locale     Desired locale (e.g. 'hu')
 * @return {Promise<Object>}   Form descriptor
 */
export async function getActiveFormTypeFormDescriptor(
  formTypeId: string,
  locale?: string
): Promise<Object> {
  const resp = await axiosInstance
    .get()
    .get(
      `/api/v1/form-types/${formTypeId}/form-descriptors${locale
        ? '/' + locale
        : ''}`
    )
  return resp.data.results[0]
}

export async function getFormDescriptor(
  formTypeId: string,
  formTypeVersion: number,
  locale?: string
): Promise<Object> {
  const resp = await axiosInstance
    .get()
    .get(
      `/api/v1/form-types/${formTypeId}/form-descriptors/${formTypeVersion}`,
      {
        params: {
          ...(locale ? { locale } : {}),
        },
      }
    )
  return resp.data.results[0]
}
