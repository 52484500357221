// @flow

import React from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components/macro'

const fadeInAnimation = keyframes`
  to {
    background-color: rgba(0, 0, 0, .25);
  }
`

const fadeOutAnimation = keyframes`
  from {
    background-color: rgba(0, 0, 0, .25);
  }
`

const slideInAnimation = keyframes`
  to {
    transform: translateX(0%);
  }
`

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0%);
  }
`

const Root = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  ${props => (props.side === 'right' ? 'right: 0;' : 'left: 0;')} width: 100%;
  height: 100%;
  animation: ${props => (props.isEnter ? fadeInAnimation : fadeOutAnimation)}
    ${props => props.animationDuration}ms linear;
  animation-fill-mode: ${props => (props.isEnter ? 'forwards' : 'backwards')};
  overflow-x: hidden;
  z-index: 10;
`

const SlideIn = styled.div`
  position: fixed;
  top: 0;
  ${props => (props.side === 'right' ? 'right: 0' : 'left: 0')};
  width: ${props => props.width};
  height: 100%;
  scroll-behavior: smooth;
  background-color: #fff;
  transform: translateX(
    ${props => (props.side === 'right' ? '100%' : '-100%')}
  );
  animation: ${props => (props.isEnter ? slideInAnimation : slideOutAnimation)}
    ${props => props.animationDuration}ms
    ${props => (props.isEnter ? 'ease-out' : 'ease-in')};
  animation-fill-mode: ${props => (props.isEnter ? 'forwards' : 'backwards')};
  box-shadow: -10px 10px 15px rgba(0, 0, 0, .1);
  overflow: hidden;
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
`

const ClosingX = styled.span`
  display: block;
  position: absolute;
  top: 16px;
  ${props => (props.side === 'right' ? 'left: 16px' : 'right: 16px')};
  font-size: 15px;
  line-height: .5;
  color: #888;
  cursor: pointer;
  &::before {
    content: '\u274c'
  }
`

type Props = {
  animationDuration?: number,
  children?: React$Element<any>,
  closeMaskOnClick?: boolean,
  onClose: () => void,
  side?: 'left' | 'right',
  visible: boolean,
  width?: string,
  disableClosingX?: boolean
}

type State = {
  isShow: boolean,
  isEnter: boolean,
}

/**
 * Slide-in modal pane component.
 *
 * This component renders a modal pane which slides in form left or right when
 * its `visible` prop is set to true. This module is using React portals. The 
 * module elements are rendered in the 'modal-root' div.
 *
 * animationDuration Length of the sliding animation in ms (default: 200)
 * children          Children elements will be rendered as the content of the pane.
 * closeMaskOnClick  When set to true the `onClose` callback will be called when
 *                   the user clicks on the masked area outside the pane (false
 *                   by default)
 * onClose           Callback which will be called when te user initiates the
 *                   the closing of the pane.
 * side              From which side to slide in (default: `left`)
 * visible           When true the pane is visible when false the pane is hidden.
 *                   (default: false)
 * width             Width of pane as a string e.g. '300px' or '80%' (default:
 *                   '500px')
 */
class SlidePane extends React.Component<Props, State> {
  inner: {current: null | React$ElementRef<'div'>}
  modalRoot: HTMLElement | null
  el: HTMLElement

  constructor(props: Props) {
    super(props)
    this.inner = React.createRef();
    this.el = document.createElement('div');
    this.state = {
      isShow: false,
      isEnter: false,
    }
  }

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root')
    this.modalRoot && this.modalRoot.appendChild(this.el);
    if (this.props.visible) {
      this.enter()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.visible !== nextProps.visible) {
      if (nextProps.visible) {
        this.enter()
      } else {
        this.leave()
      }
    }
  }

  componentWillUnmount() {
    this.modalRoot && this.modalRoot.removeChild(this.el);
  }

  enter() {
    this.setState({
      isShow: true,
      isEnter: true,
    })
  }

  leave() {
    this.setState({
      isEnter: false,
    })
  }

  closeHandler = (evt: SyntheticEvent<*>): void => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  animationEndHandler = (evt: SyntheticEvent<*>): void => {
    if (this.state.isEnter) {
      if (this.inner) {
        this.inner.current.scrollTop = 0
      }
    } else {
      this.setState({
        isShow: false,
      })
    }
  }

  outsideClickHandler = (evt: SyntheticEvent<*>): void => {
    const { closeMaskOnClick = false } = this.props
    if (
      closeMaskOnClick &&
      evt.target === evt.currentTarget &&
      this.props.onClose
    ) {
      this.props.onClose()
    }
  }

  render() {
    const {
      animationDuration = 200,
      width = '500px',
      side = 'left',
      disableClosingX,
    } = this.props
    return (
      createPortal(
        <Root
          animationDuration={animationDuration}
          isEnter={this.state.isEnter}
          onClick={this.outsideClickHandler}
          side={this.props.side}
          visible={this.state.isShow}
          >
          <SlideIn
            animationDuration={animationDuration}
            isEnter={this.state.isEnter}
            onAnimationEnd={this.animationEndHandler}
            side={side}
            width={width}
            >
              <Inner ref={this.inner}>
                {this.props.children}
              </Inner>
            {!disableClosingX && <ClosingX
              data-testid='slide-pane-close-x'
              onClick={this.closeHandler}
              side={this.props.side}
              />}
          </SlideIn>
        </Root>,
        this.el
      )
    )
  }
}

export default SlidePane
