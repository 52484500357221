import axiosInstance from './axios_instance'

export async function getUserRegistrationsInstitutes() {
  const resp = await axiosInstance.get().get(`/api/v1/user-registrations/institutes`)
  return resp.data
}

export async function createUserRegistration(payload) {
  await axiosInstance.get().post(`/api/v1/user-registrations/users`, payload)
}

export async function resendConfirmationEmail(payload) {
  await axiosInstance.get().post('/api/v1/user-registrations/resend-confirmation-email', payload)
}

export async function authConfirmation(userId, payload) {
  await axiosInstance.get().post(`/api/v1/user-registrations/users/${userId}/email-confirmation-token`, payload)
}
