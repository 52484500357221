import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function createInstitute(payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/institutes`, payload)
  return resp.data.results[0]
}

export async function getInstitutes(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get('/api/v1/institutes', {
      params: sanitizeCommonCollectionQueryParameters(options),
    })
  return resp.data
}

export async function updateInstitute(id, payload) {
  const resp = await axiosInstance.get()
    .put(`/api/v1/institutes/${id}`, payload)
  return resp.data.results[0]
}

export async function deleteInstitute(id) {
  await axiosInstance.get()
    .delete(`/api/v1/institutes/${id}`)
}

export async function getInstitute(id) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${id}`)
  return resp.data.results[0]
}

export async function getInstituteName(id) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${id}/name`)
  return resp.data.results[0]
}

export async function getAccessibleInstitutes() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/accessible-institutes`)
  return resp.data.results
}

export async function getPublicInstitutes() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/public`)
  return resp.data
}
