import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'
import _ from 'lodash'
import { formValuesSweeper } from './form_values_sweeper'

import {
  getIn,
  setIn,
  deleteIn,
  splice,
  storePath,
} from './utils'
import {
  fromPath,
  hasImmutableValueChangedWithCoercedNils,
} from '../utils'
import {
  CLEAR_APPROVE_FORM_SUBMIT_CONFIRMATION,
  CLEAR_CORRECT_FORM_SUBMIT_CONFIRMATION,
  CLEAR_FIELD_CORRECT_NOTE,
  CLEAR_FIELD_REJECT_REASON,
  CLEAR_FIELD_UNDER_CORRECT_NOTE_WRITING,
  CLEAR_FIELD_UNDER_FIX,
  CLEAR_FIELD_UNDER_REJECTION,
  CLEAR_FORM_SECTION_LIST_PANE,
  CLEAR_REJECT_FORM_SUBMIT_CONFIRMATION,
  CLEAR_SUBMIT_TRIGGER,
  DESTROY_FORM,
  FOCUS_FIELD,
  FORM_SUBMISSION_SUCCESS,
  HIDE_FORM_HISTORY,
  INIT_FORM,
  PUSH_MULTI_FIELD_VALUE,
  REGISTER_FIELD,
  REMOVE_FIELD,
  REMOVE_MULTI_FIELD_VALUE,
  SET_APPROVE_FORM_SUBMIT_CONFIRMATION,
  SET_CORRECT_FORM_SUBMIT_CONFIRMATION,
  SET_CURRENT_SECTION,
  SET_FIELD_CORRECT_NOTE,
  SET_FIELD_REJECT_REASON,
  SET_FIELD_UNDER_CORRECT_NOTE_WRITING,
  SET_FIELD_UNDER_FIX,
  SET_FIELD_UNDER_REJECTION,
  SET_FIELD_VALUE,
  SET_FORM_SECTION_LIST_PANE,
  SET_SYNC_ERRORS,
  SET_SYNC_ERRORS_AND_WARNINGS,
  SET_SUBMIT_ATTEMPTED,
  SET_REJECT_FORM_SUBMIT_CONFIRMATION,
  SHOW_FORM_HISTORY,
  SUBMIT,
  UPDATE_DEPENDENCY_TREE_RENDER_TYPE,
  UPDATE_FIELD_STATE,
  REHYDRATE,
  SWEEP_FORM_VALUES,
  FORM_SUBMISSION_FAILED,
  CLOSE_ALL_CONFIRMATION_MODAL,
  LOAD_FORMDATA_FROM_FILE,
} from './actions'

import {
  calcFieldDisplayMode,
  backupField,
  updateFieldDependencyGraph,
  getFieldUnderFix,
  recalcGlobalFlags,
  removeMultiFieldItem,
  updateComplexFieldAncestorsState,
  updateDependencyTreeRenderType,
  updateFieldDisplayMode,
  updateFieldAndAncestorsDisplayModes,
} from './reducer_helpers'

import {
  FIELD_RENDER_TYPE_FIX,
  FIELD_TYPE_FIELD,
} from './constants'

/*
- MultiField REMOVE_MULTI_FIELD_VALUE esetén nincs field backup és az initalValues-ból
  is törlünk, hogy a dirty ne zavarjon be új FieldGroup hozzáadásakor

Redux state structure:
Immutable.Map({
  myFormName: {
    fieldSections: { // flat
      patientName: 1,
      'medication[2].drugName': 2,
    },
    currentSection: 0,
    displayModes: { // flat
      patientName: 'dirty', // 'dirty', 'corrected', 'rejected', null
    },
    rejectedFields: {
      patientGender: {
        rejectReason: '',
        rejectedValue: '',
      }
    },
    correctedFields: { // flat
      patientDateOfBirth: {
        rejectReason: '',
        rejectedValue: '',
      }
    },
    correctNotes: {
      patientName: 'Javítási megjegyzésem.',
    },
    renderTypes: {
     patientName: 'read',  // 'read' | 'new' | 'edit' | 'inspect' | 'admin_fix' | 'correct' | 'correct_fix',
    },
    fieldStates: { // flat
      patientName: {
        touched: true,
        active: true,
        dirty: true,
        readOnly: false,  // ????????????????
      },
      medicationDetails[0]: {
        pushed: true, // just added field group
      }
    },
    initialValues: { // nested
      patientName: 'John Doe',
      medication: [{
        drugName: '',
      }],
    },
    registeredFields: { // flat structure
      'medication[2].drugName': {
        name: 'medication[2].drugName'
      }
    },
    backupFields: { // flat
      alcoholCurrentFrequency: {
        value: 2,
        rejectedField: {
          rejectReason: '',
          rejectedValue: '',
        },
        correctedField: {
          rejectReason: '',
          rejectedValue: '',
        }
      }
    },
    syncErrors: {
      patientName: 'Too long',
    },
    values: {
      patientName: 'Johnatan Doe',
      medication: [
      {
      drugName: ''
      }
      ]
    },
    active: 'patientName',
    hasErrors: true,
    viewType: 'read' | 'new' | 'edit' | 'inspect' | 'correct',
    fieldUnderRejection: 'patientName',
    fieldUnderFix: 'patientName',
    fieldUnderCorrectNoteWriting: {
      field: 'patientName',
      label: 'Beteg neve'
    },
    triggerSubmit: 'submitType',
    hasRejectedFields: true,
    hasRejectedNotCorrectedFields: true,
    correctFormSubmitConfirmation: true,
    sectionCount: 3, // number of sections in the generated form
    showFormHistory: true,
    submitAttempted: false,
    staticFieldDependencyGraph : { // FDG generated from form descriptor
      'f1': ['c1', 'c1.f2'],
      'c1': ['c1.f2'],
      'c1.f2': []
    },
    fieldDependencyGraph: { // static FDG with extended complex fields
      'f1': ['c1', 'c1[0].f2', 'c1[1].f2'],
      'c1': ['c1[0].f2', 'c1[1].f2'],
      'c1[0].f2': [],
      'c1[1].f2': []
    }
  }
})
*/

const initialState = Immutable.fromJS({})

export default createReducer(initialState, {
  // only called once before field registrations
  [INIT_FORM]:
    recalcGlobalFlags(
      (state, {
        payload: {
          sectionCount,
          viewType,
          initialValues,
          rejectedFields,
          correctedFields,
          correctNotes,
          currentSection,
          submitAttempted,
          submitInProgress,
          fieldDependencyGraph,
          fieldTypes,
          fieldSections,
        },
        meta: {
          form,
        }
      }) => {
        const fromJS = Immutable.fromJS
        let newState = state.withMutations(s =>
          s.setIn(storePath(['currentSection'], form), currentSection)
           .setIn(storePath(['sectionCount'], form), sectionCount)
           .setIn(storePath(['submitAttempted'], form), submitAttempted)
           .setIn(storePath(['submitInProgress'], form), false)
           .setIn(storePath(['viewType'], form), viewType)
           .setIn(storePath(['values'], form), fromJS(initialValues || {}))
           .setIn(storePath(['initialValues'], form), fromJS(initialValues || {}))
           .setIn(storePath(['rejectedFields'], form), fromJS(rejectedFields || {}))
           .setIn(storePath(['correctedFields'], form), fromJS(correctedFields || {}))
           .setIn(storePath(['correctNotes'], form), fromJS(correctNotes || {}))
           .setIn(storePath(['staticFieldDependencyGraph'], form), fromJS(fieldDependencyGraph || {}))
           .setIn(storePath(['fieldTypes'], form), fromJS(fieldTypes || {}))
           .setIn(storePath(['fieldSections'], form), fromJS(fieldSections || {}))
          )
        newState = updateFieldDependencyGraph(newState, form)
        return newState.setIn(
          storePath(['displayModes'], form),
          newState.getIn(storePath(['rejectedFields'], form), Immutable.Map())
            .keySeq()
            .toSet()
            .union(
              newState.getIn(storePath(['correctedFields'], form), Immutable.Map())
              .keySeq()
              .toSet()
            )
            .reduce((acc, v) =>
              acc.setIn(
                [v],
                calcFieldDisplayMode(newState, v, form)
              )
            , Immutable.Map())
        )
      }
    ),

  [REGISTER_FIELD]:
    (state, {
      payload: { field, fieldType, parentSection },
      meta: { form}
    }) => {
      let newState = state.withMutations(s =>
        s.setIn(
          storePath(['registeredFields', field], form),
          Immutable.fromJS({
            name: field,
            fieldType,
          })
        )
        // TODO: megvizsgalni, hogy kiváltható-e form descriptorral
        // .setIn(storePath(['fieldSections', field], form), parentSection)
      )
      const path = _.toPath(field)
      if (path.length > 2 && !Number.isNaN(Number(path[path.length - 2]))) {
        const fieldGroupPath = fromPath(path.slice(0, -1))
        if (newState.getIn(storePath(['fieldStates', fieldGroupPath, 'pushed'], form), false)) {
          newState = newState.updateIn(
            storePath(['fieldStates', field], form),
            fs => (fs || Immutable.Map()).merge({ pushed: true })
          )
        }
      }
      // in registerFieldAction (actions.js) defer calculation of render types
      // newState = updateDependencyTreeRenderType(newState, field, form)
      const fieldBackup = newState.getIn(storePath(['backupFields', field], form))
      if (fieldBackup) {
        newState = setIn(newState, `values.${field}`, fieldBackup.get('value'), form)
        .setIn(
          storePath(['correctedFields', field], form),
          fieldBackup.get('correctedField')
        )
        .deleteIn(storePath(['backupFields', field], form))
      }
      if (fieldType === FIELD_TYPE_FIELD) {
        const fieldInitialValue = getIn(newState, `initialValues.${field}`, form)
        newState = newState.setIn(
          storePath(['fieldStates', field, 'dirty'], form),
          hasImmutableValueChangedWithCoercedNils(
            getIn(newState, `values.${field}`, form),
            fieldInitialValue
          )
        )
      }
      newState = updateComplexFieldAncestorsState(newState, field, form)
      newState = updateFieldAndAncestorsDisplayModes(newState, field, form) // because of previously set dirty
      return newState
    },

  [REMOVE_FIELD]:
    recalcGlobalFlags(
      (state, { payload: { field }, meta: { form } }) => {
        let newState = state
        newState = backupField(newState, field, form)
        newState = deleteIn(newState, `values.${field}`, form)
        return newState.withMutations(s =>
          s.deleteIn(storePath(['registeredFields', field], form))
           .deleteIn(storePath(['fieldStates', field], form))
           .deleteIn(storePath(['rejectedFields', field], form))
           .deleteIn(storePath(['correctedFields', field], form))
           .deleteIn(storePath(['renderTypes', field], form))
           .deleteIn(storePath(['displayModes', field], form))
        )
      }
    ),

  [SET_FIELD_VALUE]:
    recalcGlobalFlags(
      (state, {
        payload: { field, value },
        meta: { form}
      }) => {
        const fieldInitialValue = getIn(state, `initialValues.${field}`, form)
        //TODO: Wrap value with Immutable.JS()
        let newState = setIn(state, `values.${field}`, Immutable.fromJS(value), form)
        .setIn(
          storePath(['fieldStates', field, 'dirty'], form),
          hasImmutableValueChangedWithCoercedNils(Immutable.fromJS(value), fieldInitialValue)
        )
        newState = updateComplexFieldAncestorsState(newState, field, form)
        newState = updateFieldAndAncestorsDisplayModes(newState, field, form)
        // because of previously set dirty
        return updateDependencyTreeRenderType(newState, field, form)
      }
    ),

  [FOCUS_FIELD]:
    (state, { payload: { field }, meta: { form }}) => {
      const prevActive = state.getIn(storePath(['active'], form))
      let newState = state
      if (prevActive) {
        newState = newState.deleteIn(
          storePath(['fieldStates', prevActive, 'active'], form)
        )
      }
      return newState.withMutations(s =>
        s.setIn(storePath(['fieldStates', field, 'active'], form), true)
          .setIn(storePath(['active'], form), field)
       )
    },

  [SET_SUBMIT_ATTEMPTED]:
    (state, { meta: { form } }) => {
      return (
        state
          .setIn(
            storePath(['submitAttempted'], form),
            true
          )
          .setIn(storePath(['submitInProgress'], form), true)
      )
    },

  [DESTROY_FORM]:
    (state, { meta: { form } }) => {
      if (form) {
        return state.delete(form)
      }
      return state.deleteAll([
        'fieldStates',
        'initialValues',
        'registeredFields',
        'values',
        'active',
        'syncErrors',
        'viewType',
        'hasErrors',
        'triggerSubmit',
        'rejectedFields',
        'correctedFields',
        'renderTypes',
        'displayModes',
        'hasRejectedFields',
        'fieldUnderRejection',
        'sectionCount',
        'submitAttempted',
      ])
    },

  [UPDATE_FIELD_STATE]:
    (state, { payload: { field, state: fieldState }, meta: { form } }) =>
      state.mergeDeepIn(
        storePath(['fieldStates', field], form),
        Immutable.fromJS(fieldState)
      ),

  [SET_SYNC_ERRORS]:
    recalcGlobalFlags(
      (state, { payload: { errors }, meta: { form }}) => {
        return state.setIn(
          storePath(['syncErrors'], form),
          Immutable.fromJS(errors)
        )
      }
    ),

  [SET_SYNC_ERRORS_AND_WARNINGS]:
    recalcGlobalFlags(
      (state, { payload: { errors, warnings }, meta: { form }}) => {
        return state
          .setIn(storePath(['syncWarnings'], form),Immutable.fromJS(warnings))
          .setIn(storePath(['syncErrors'], form), Immutable.fromJS(errors))
      }
    ),

  [SET_FIELD_UNDER_REJECTION]:
    (state, { payload: { field }, meta: { form } }) =>
      state.setIn(
        storePath(['fieldUnderRejection'], form),
        field
      ),

  [CLEAR_FIELD_UNDER_REJECTION]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['fieldUnderRejection'], form)
      ),

  [SUBMIT]:
    (state, { payload: { submitType }, meta: { form } }) =>
      state.setIn(storePath(['triggerSubmit'], form), submitType || 'submit'),

  [CLEAR_SUBMIT_TRIGGER]:
    (state, { meta: { form } }) =>
      state.deleteIn(storePath(['triggerSubmit'], form)),

  [SET_FIELD_REJECT_REASON]:
    recalcGlobalFlags(
      (state, { payload: { field, rejectReason }, meta: { form }}) =>
        {
          let newState = state.setIn(
            storePath(['rejectedFields', field], form),
            Immutable.fromJS({
              rejectReason,
              rejectedValue: getIn(state, `values.${field}`, form)
            })
          )
          // because of previously set rejectedFields
          newState = updateFieldDisplayMode(newState, field, form)
          return updateDependencyTreeRenderType(newState, field, form)
        }
    ),

  [CLEAR_FIELD_REJECT_REASON]:
    recalcGlobalFlags(
      (state, { payload: { field }, meta: { form } }) => {
        let newState = state.deleteIn(storePath(['rejectedFields', field], form))
        // because of previously deleted rejectedFields
        newState = updateFieldDisplayMode(newState, field, form)
        return updateDependencyTreeRenderType(newState, field, form)
      }
    ),

  [SET_FIELD_UNDER_FIX]:
    (state, { payload: { field }, meta: { form } }) =>
      state
        .setIn(storePath(['fieldUnderFix'], form), field)
        .setIn(storePath(['renderTypes', field], form), FIELD_RENDER_TYPE_FIX),

  [CLEAR_FIELD_UNDER_FIX]:
    (state, { meta: { form } }) => {
      const fieldUnderFix = getFieldUnderFix(state, form)
      const newState = state.deleteIn(storePath(['fieldUnderFix'], form))
      return updateDependencyTreeRenderType(newState, fieldUnderFix, form)
    },

  [UPDATE_DEPENDENCY_TREE_RENDER_TYPE]:
    (state, { payload: { fields }, meta: { form }}) =>
      updateDependencyTreeRenderType(state, fields, form),

  [SET_FIELD_UNDER_CORRECT_NOTE_WRITING]:
    (state, { payload: { field, label }, meta: { form } }) =>
      state.setIn(
        storePath(['fieldUnderCorrectNoteWriting'], form),
        Immutable.fromJS({
          field: field,
          label: label, // TODO: helyettesitheto-e formDescriptorral?
        })
      ),

  [CLEAR_FIELD_UNDER_CORRECT_NOTE_WRITING]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['fieldUnderCorrectNoteWriting'], form)
      ),

  [SET_FIELD_CORRECT_NOTE]:
    recalcGlobalFlags(
      (state, { payload: { correctNote, field }, meta: { form }}) =>
        {
          const newState = state.setIn(
            storePath(['correctNotes', field], form),
            correctNote
          )
          return updateFieldAndAncestorsDisplayModes(newState, field, form) // because of previously set correctNotes
        }
    ),

  [CLEAR_FIELD_CORRECT_NOTE]:
    recalcGlobalFlags(
      (state, { payload: { field }, meta: { form }}) => {
        const newState = state.deleteIn(storePath(['correctNotes', field], form))
        return updateFieldAndAncestorsDisplayModes(newState, field, form) // because of previously deleted correctNotes
      }
    ),

  [SET_REJECT_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['rejectFormSubmitConfirmation'], form),
        true
      ),

  [CLEAR_REJECT_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['rejectFormSubmitConfirmation'], form)
      ),

  [SET_CORRECT_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['correctFormSubmitConfirmation'], form),
        true
      ),

  [CLEAR_CORRECT_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['correctFormSubmitConfirmation'], form)
      ),

  [SET_APPROVE_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['approveFormSubmitConfirmation'], form),
        true
      ),

  [CLEAR_APPROVE_FORM_SUBMIT_CONFIRMATION]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['approveFormSubmitConfirmation'], form)
      ),
  
  [CLOSE_ALL_CONFIRMATION_MODAL]:
    (state, { meta: { form }}) =>
      state
        .deleteIn(storePath(['approveFormSubmitConfirmation'], form))
        .deleteIn(storePath(['correctFormSubmitConfirmation'], form))
        .deleteIn(storePath(['rejectFormSubmitConfirmation'], form)),

  [SET_CURRENT_SECTION]:
    (state, { payload: { sectionSerial }, meta: { form } }) => // TODO: sectionCount: formDescriptor
      state.setIn(
        storePath(['currentSection'], form),
        Math.min(
          Math.max(sectionSerial, 0),
          state.getIn(storePath(['sectionCount'], form), 1) - 1
        )
      ),

  [SHOW_FORM_HISTORY]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['showFormHistory'], form),
        true
      ),

  [HIDE_FORM_HISTORY]:
    (state, { meta: { form } }) =>
      state.deleteIn(
        storePath(['showFormHistory'], form)
      ),

  [PUSH_MULTI_FIELD_VALUE]:
    (state, { payload: { field, value }, meta: { form } }) => {
      const current = getIn(state, `values.${field}`, form)
      const index = current ? current.size : 0
      let newState = setIn(
        state,
        `values.${field}`,
        splice(current, index, 0, Immutable.fromJS(value)),
        form
      )
      newState = newState.updateIn(
        storePath(['fieldStates', `${field}[${index}]`], form),
        v => (v || Immutable.Map()).merge({ pushed: true }) // just added field group
      )
      newState = updateComplexFieldAncestorsState(newState, field, form)
      newState = updateFieldAndAncestorsDisplayModes(newState, field, form)
      return updateFieldDependencyGraph(newState, form)
    },

  [REMOVE_MULTI_FIELD_VALUE]:
    recalcGlobalFlags(
      (state, action) => {
        const { payload: { field, index }, meta: { form} } = action
        const currentValue = getIn(state, `values.${field}`, form)
        const newValue = splice(currentValue, index, 1)
        let newState = setIn(
          state,
          `values.${field}`,
          // If the resulting list is empty we return undefined instead.
          // This is necessary because otherwise adding and immediately removing
          // an item to a complex field changes its value from undefined to empty array.
          newValue && newValue.isEmpty && newValue.isEmpty() ? undefined : newValue,
          form
        )
        newState = updateComplexFieldAncestorsState(newState, field, form)
        newState = updateFieldAndAncestorsDisplayModes(newState, field, form)
        newState = removeMultiFieldItem(
          newState,
          [
            'correctedFields',
            'displayModes',
            'fieldStates',
            'registeredFields',
            'rejectedFields',
            'renderTypes',
          ],
          field,
          index,
          form
        )
        newState = updateDependencyTreeRenderType(newState, field, form)
        return updateFieldDependencyGraph(newState, form)
      }
    ),

  [REHYDRATE]:
    (state, { payload: { ecrForm }, meta: { form }}) =>
      // state.mergeIn(
      //   [form],
      //   ecrForm
      // )
      //
      state.setIn(
        [form],
        Immutable.fromJS(ecrForm)
      ),

  [SWEEP_FORM_VALUES]:
    (state, { payload: { formDescriptor }, meta: { form }}) =>
      state.setIn(
        [form, 'values'],
        formValuesSweeper(formDescriptor, state.getIn([form, 'values']))
      ),

  [FORM_SUBMISSION_SUCCESS]:
    (state, { meta: { form } }) => {
      return (
        state.getIn(storePath(['formSubmissionSuccess'], form))
          ? state
          : state.setIn(
            storePath(['formSubmissionSuccess'], form),
            true
          )
      )
    },
  [FORM_SUBMISSION_FAILED]: 
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['formSubmissionFailed'], form),
        true
      ).setIn(storePath(['submitInProgress'], form), false),

  [SET_FORM_SECTION_LIST_PANE]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['formSectionListPane'], form),
        true
      ),

  [CLEAR_FORM_SECTION_LIST_PANE]:
    (state, { meta: { form } }) =>
      state.setIn(
        storePath(['formSectionListPane'], form),
        false,
      ),

  [LOAD_FORMDATA_FROM_FILE]:
    (state, { payload: { formData }, meta: { form }}) => {
      const header = state.getIn([form, 'values', '_header']).toJS()
      return (
        state.setIn(
          [form, 'values'],
          Immutable.fromJS({...formData, _header: { ...header }})
        )
      )
    },
})
