import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getBioSampleTypes(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/bio-sample-types`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getRegistryBioSampleTypes(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries/${id}/bio-sample-types`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getFormAttachableBioSampleTypes(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/${id}/attachable-bio-sample-types`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getDerivableBioSampleTypes(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/bio-sample-types/${id}/derivables`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}