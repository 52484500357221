import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getInstituteBioSamples(instituteId, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/bio-samples`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function getInstituteBioSample(instituteId: string, bioSampleId: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/bio-samples/${bioSampleId}`)
  return resp.data.results[0]
}

export async function getBioSamples(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/bio-samples`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function getBioSample(id) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/bio-samples/${id}`)
  return resp.data.results[0]
}

export async function getBioSampleForms(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/bio-samples/${id}/forms`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function getPatientBioSamples(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/patients/${id}/bio-samples`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function deleteBioSample(id) {
  await axiosInstance.get()
    .delete(`/api/v1/bio-samples/${id}`)
}

export async function createInstituteBioSample(id, payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/institutes/${id}/bio-samples`, payload)
  return resp.data.results[0]
}

export async function createAndAttachInstituteBioSample(
  instituteId: string,
  formId: string,
  payload: Object
) {
  const resp = await axiosInstance.get()
    .post(
      `/api/v1/institutes/${instituteId}/forms/${formId}/bio-samples`, payload
    )
  return resp.data.results[0]
}

export async function updateInstituteBioSample(instituteId, bioSampleId, payload) {
  const resp = await axiosInstance.get()
        .put(`/api/v1/institutes/${instituteId}/bio-samples/${bioSampleId}`, payload)
  return resp.data.results[0]
}

export async function getAttachableBioSamples(id, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/forms/${id}/attachable-bio-samples`, {
      params: {
        ...sanitizeCommonCollectionQueryParameters(options)
      }
    })
  return resp.data
}

export async function postFormAttachedBioSamples(id, payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/forms/${id}/attached-bio-samples`, payload)
  return resp.data
}

export async function createBioSample(payload) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/bio-samples`, payload)
  return resp.data.results[0]
}

export async function updateBioSample(bioSampleId, payload) {
    const resp = await axiosInstance.get()
    .put(`/api/v1/bio-samples/${bioSampleId}`, payload)
    return resp.data.results[0]
}

export async function detachBioSample(formId, sampleId) {
  await axiosInstance.get()
    .post(`/api/v1/forms/${formId}/attached-bio-samples/${sampleId}/detach`)
}
