import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  MFA_SETUP_REQUEST,
  MFA_SETUP_FAILURE,
  MFA_SETUP_SUCCESS,
  MFA_VERIFY_REQUEST,
  MFA_VERIFY_FAILURE,
  MFA_VERIFY_SUCCESS,
  MFA_SKIP_SETUP_REQUEST,
  MFA_SKIP_SETUP_FAILURE,
} from './actions'

const initialState = Immutable.fromJS({
  accessToken: null,
  // | 'mfaSetupRequest'
  // | 'mfaSetupFailure'
  // | 'mfaSetupSuccess'
  // | 'mfaVerifyRequest'
  // | 'mfaVerifyFailure'
  // | 'mfaVerifySuccess'
  step: 'mfaSetupRequest',
  otpUrl: null,
  mfaEnforcedAt: null,
  errorMessage: null,
  verifyToken: null,
})

export default createReducer(initialState, {
  [MFA_SETUP_REQUEST]: (state, action) =>
    state.merge({
      step: 'mfaSetupRequest',
      accessToken: action.payload.accessToken,
      errorMessage: null,
      otpUrl: null,
      mfaEnforcedAt: null,
    }),
  [MFA_SETUP_FAILURE]: (state, action) =>
    state.merge({
      step: 'mfaSetupFailure',
      errorMessage: action.payload.errorMessage,
    }),
  [MFA_SETUP_SUCCESS]: (state, action) =>
    state.merge({
      step: 'mfaSetupSuccess',
      otpUrl: action.payload.otpUrl,
      mfaEnforcedAt: action.payload.mfaEnforcedAt,
    }),
  [MFA_VERIFY_REQUEST]: (state, action) =>
    state.merge({
      step: 'mfaVerifyRequest',
      verifyToken: action.payload.verifyToken,
      recoveryKeys: null,
    }),
  [MFA_VERIFY_FAILURE]: (state, action) =>
    state.merge({
      step: 'mfaVerifyFailure',
      errorMessage: action.payload.errorMessage,
    }),
  [MFA_VERIFY_SUCCESS]: (state, action) =>
    state.merge({
      step: 'mfaVerifySuccess',
      recoveryKeys: action.payload.recoveryKeys,
      accessToken: null,
      otpUrl: null,
      mfaEnforcedAt: null,
      errorMessage: null,
      verifyToken: null,
    }),
    [MFA_SKIP_SETUP_REQUEST]: (state, action) =>
    state.merge({
      step: 'mfaSkipSetupRequest',
    }),
    [MFA_SKIP_SETUP_FAILURE]: (state, action) =>
    state.merge({
      step: 'mfaSkipSetupFailure',
      errorMessage: action.payload.errorMessage,
    }),
})
