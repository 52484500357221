// @flow
import FormDescriptor from '../../services/generator/form_descriptor'

import type { FormDescriptorJsonNode } from '../../services/generator/form_descriptor'
import { FIELD_TYPE_FIELD, FIELD_TYPE_COMPLEX_FIELD } from './constants'

export type Result = {
  dependencies: {
    [string]: string[],
  },
  fieldTypes: {
    [string]: 'field' | 'complexField'
  }
}
type Context = {
  ancestors: string[], // dependent ancestors
  complex: string[], // complex ancestors
}

function unique(arr) {
  return [...new Set(arr)]
}

function prefixComplex(complexAncestors, fieldId) {
  return [...(complexAncestors || []), ...(fieldId ? [fieldId] : [])].join('.')
}

export default function calculateFieldDependencyGraph(
  formDescriptor: FormDescriptor
): Result {
  if (formDescriptor.form) {
    return walkFormDescriptor(
      formDescriptor.form,
      { dependencies: {}, fieldTypes: {} },
      { ancestors: [], complex: [] }
    )
  }
  throw new Error(
    'calculateFieldDependencyGraph: `form` not available in formDescriptor'
  )
}

function walkFormDescriptor(
  nodes: FormDescriptorJsonNode[],
  result: Result,
  context: Context
): Result {
  return nodes.reduce(
    (acc, child) => processFormDescriptorChild(child, acc, context),
    result
  )
}

function processFormDescriptorChild(
  child: FormDescriptorJsonNode,
  result: Result,
  context: Context
): Result {
  switch (child.type) {
    case 'dependentFields':
      // console.log(child.label, context.ancestors)
      const { predicateParameters, refField } = child || {}
      return walkFormDescriptor(child.children, result, {
        ...context,
        ancestors: unique([
          ...(refField ? [refField] : []),
          ...(predicateParameters || []),
          ...context.ancestors,
        ]),
      })
    case 'field':
      return {
        dependencies: {
          ...result.dependencies,
          // dependentFields dependencies
          ...context.ancestors.reduce(
            (acc, a) => ({
              ...acc,
              [a]: [
                ...(result.dependencies[a] || []),
                prefixComplex(context.complex, child.id),
              ],
            }),
            {}
          ),
          // complex dependencies
          ...context.complex.reduce(
            (acc, a) => ({
              ...acc,
              [prefixComplex(context.complex)]: [
                ...(result.dependencies[a] || []),
                prefixComplex(context.complex, child.id),
              ],
            }),
            {}
          ),
          [prefixComplex(context.complex, child.id)]: [],
        },
        fieldTypes: {
          ...result.fieldTypes,
          [child.id]: FIELD_TYPE_FIELD,
        }
      }
    case 'complexField':
      return walkFormDescriptor(
        child.children,
        {
          dependencies: {
            ...result.dependencies,
            [prefixComplex(context.complex, child.id)]: [],
            ...context.ancestors.reduce(
              (acc, a) => ({
                ...acc,
                [a]: [
                  ...(result.dependencies[a] || []),
                  prefixComplex(context.complex, child.id),
                ],
              }),
              {}
            ),
            // complex dependencies
            ...context.complex.reduce(
              (acc, a) => ({
                ...acc,
                [a]: [
                  ...(result.dependencies[a] || []),
                  prefixComplex(context.complex, child.id),
                ],
              }),
              {}
            ),
          },
          fieldTypes: {
            ...result.fieldTypes,
            [child.id]: FIELD_TYPE_COMPLEX_FIELD,
          }
        },
        {
          ...context,
          complex: [...(context.complex || []), child.id],
        }
      )
    case 'section':
      return walkFormDescriptor(child.children, result, context)

    default:
      throw new Error('Unknown fieldtype')
  }
}
