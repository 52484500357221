import axiosInstance from './axios_instance'

export async function getProfileSettings() {
  const axios = axiosInstance.get()
  const resp = await axios.get(`/api/v1/profile-settings`)
  return resp?.data?.results?.[0]
}

export async function postProfileSettings(payload) {
  const axios = axiosInstance.get()
  const resp = await axios.patch(`/api/v1/profile-settings`, payload)
  return resp?.data
}

export async function postMfaRecoveryRegenerate(password) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-recovery/regenerate', {
    password
  })
  return resp?.data?.recoveryKeys
}