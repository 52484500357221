// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getRegistries(options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getAccessibleRegisters() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/accessible-registries`)
  return resp.data.results
}

export async function getPermittedBioSampleRegistries(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${id}/permitted-bio-sample-registries`)
  return resp.data
}

export async function getPublicRegistries() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries/public`)
  return resp.data
}

export async function getRegistryByFormType(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/form-types/${id}/registry`)
  return resp.data.results[0]
}

export async function getRegistry(id: string, verbose=false) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries/${id}`, {
      params: {
        verbose: verbose,
      }
    })
  return resp.data.results[0]
}

export async function getRegistriesDashboard() {
  const resp = await axiosInstance.get()
    .get(`/api/v1/dashboard/registries`)
  return resp.data.results[0]
}