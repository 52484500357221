// @flow
import * as React from 'react'

type Props = {
  name: string,
  children: React.Node,
}

/**
 * FieldGroup renders a group of fields.
 * It prepends its children `name`
 * prop with its own name to maintain proper
 * hierarchy of field names.
 * `name` prop updates: Field, ComplexField,
 * DependentFields
 */
class FieldGroup extends React.Component<Props> {

  render() {
    const { children, name } = this.props
    return (
      <div>
        {React.Children.map(children, (child: React.Element<*>, idx: number) =>
          React.cloneElement(child, {
            key: idx,
            name: child.props.name ? `${name}.${child.props.name}` : name,
          })
        )}
      </div>
    )
  }
}

export default FieldGroup
