import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components/macro'
import Spinner from 'react-spinkit'

const StyledSpinner = styled(Spinner)`
  & {
    ${(props) => (props.size ? `width: ${props.size};` : '')}
    ${(props) => (props.size ? `height: ${props.size};` : '')}
  }

  & > div {
    ${(props) => (props.size ? `width: ${props.size};` : '')}
    ${(props) => (props.size ? `height: ${props.size};` : '')}
  }
`

const ButtonWithStyles = styled.button`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #adadad;
  background-color: #fff;
  color: #333;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #cdcdcd;
    color: #888;
  }

  &:focus {
    outline: none;
  }

  &.default {
    color: #333;
    background-color: #fff;
    border-color: #adadad;

    &:hover:not(:disabled) {
      color: #555;
      background-color: #e6e6e6;
    }
  }

  &.primary {
    color: #fff;
    background-color: #589ceb;
    border-color: #1571dd;

    &:hover:not(:disabled) {
      color: #fff;
      background-color: #2e82e4;
    }
  }

  &.info {
    color: #fff;
    background-color: #7ae2ed;
    border-color: #18cbde;

    &:hover:not(:disabled) {
      color: #fff;
      background-color: #2dd5e7;
    }
  }

  &.success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #189f18;

    &:hover:not(:disabled) {
      color: #fff;
      background-color: #23a123;
    }
  }

  &.warning {
    color: #fff;
    background-color: #dd9c45;
    border-color: #d68a23;

    &:hover:not(:disabled) {
      color: #fff;
      background-color: #d68a23;
    }
  }

  &.danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d61e1a;

    &:hover:not(:disabled) {
      color: #fff;
      background-color: #d62c28;
    }
  }
`

const Button = (props) => {
  const {
    spinnerStart,
    spinnerEnd,
    label,
    icon,
    kind,
    postfixIcon,
    ...rest
  } = props
  return (
    <ButtonWithStyles className={kind} {...rest}>
      {spinnerStart && (
        <StyledSpinner
          size="1em"
          name={spinnerStart === true ? 'circle' : spinnerStart}
          fadeIn={'none'}
        />
      )}
      {icon && <FontAwesome key={1} name={icon}></FontAwesome>}
      {label ? label : props.children}
      {postfixIcon && (
        <FontAwesome key={'postfixIcon'} name={postfixIcon}></FontAwesome>
      )}
      {spinnerEnd && (
        <StyledSpinner
          size="1em"
          name={spinnerEnd === true ? 'circle' : spinnerEnd}
          fadeIn={'none'}
        />
      )}
    </ButtonWithStyles>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf([
    'default',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
  ]),
}

Button.defaultProps = {
  kind: 'default',
  type: 'button',
}

export default Button
