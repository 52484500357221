/**
 * Helper function to return a path in the redux store as an array.
 * The returned array will look like this if path = [p1, p2]:
 *
 * [root, formName, p1, p2]
 * *
 * @param  {string[]}  [path=[]] The redux store path
 * @param  {string} formName  The name of the redux form
 * @param  {string} root      The root of the redux slice
 * @return {string[]}           Concatenated path
 */
export function storePath(path = [], formName, root) {
  return [
    ...(root ? [root] : []),
    ...(formName ? [formName] : []),
    ...path
  ]
}
