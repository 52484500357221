//@flow
import * as Immutable from 'immutable'
import { toString } from 'lodash'
import { convertMapToJs } from '../../utils'
/**
 * It will create a function from the parameters
 * @param {Array<string>} formulaParameters
 * @param {string} formula
 * @returns {Function}
 */
export function createPredicate(formulaParameters: Array<string>, formula: ?string): ?Function {
  if (typeof formula !== 'string') return
  // eslint-disable-next-line
  return new Function(...(formulaParameters || []), formula)
}

/**
 * It will collect the gotten parameters form an Immutable.Map and it will convert values JS.
 * @param {Array<string>} formulaParameters
 * @param {Immutable.Map<*,*>} values
 * @returns {Array<string>}
 */
export function collectPredicateParameters(formulaParameters: Array<string>, values: Immutable.Map<*,*>): Array<string> {
  if (formulaParameters && Array.isArray(formulaParameters)) {
    return formulaParameters
      .reduce((acc, formulaParameter) => [
        ...acc,
        values.get(formulaParameter)
      ], [])
      .map(x => convertMapToJs(x));
  }
  return []
}

/**
 * First call the function with the given params, then converts its return value.
 * @param {Function} calculator
 * @param {Array<string>} parameterValues
 * @returns {string}
 */
export function evaluatePredicate(calculator: Function, parameterValues: Array<string>): string {
  return convertValue(calculator(...parameterValues));
}

export function convertValue(val: any): any {
  if (Number.isNaN(val) || val === null || val === undefined) {
    return
  }
  if (Object(val) === val) {
    return val
  }
  if (Boolean(val) === val) {
    return val
  }
  return toString(val)
}
