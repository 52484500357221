// @flow
import React from 'react'
import FormErrorsPane from './FormErrorsPane'
import FormDescriptor from '../generator/form_descriptor'
import PropTypes from 'prop-types'
import { 
  setSyncErrorsAndWarningsAction,
  formSubmissionFailedAction
} from '../../lib/ecr_form/actions'
import store from '../../services/store'

const HEADER_FIELDS = ['_header.patient', '_header.physicianId']

/**
 * Returns true if validation errors contain an error that references to any
 * indispensable field or indispensable field children.
 * @param  {Object[]} validationErrors    List of validation errors
 * @param  {string[]} indispensableFields List of indispensable field ids
 * @return {boolean}
 */
function validationErrorsContainsIndispensableFields(
  validationErrors: Object[],
  indispensableFields: string[]
) {
  return (validationErrors || []).some(ve =>
    (indispensableFields || []).some(
      inf =>
        ve.field && (
          ve.field === inf ||
          ve.field.startsWith(`${inf}.`) ||
          ve.field.startsWith(`${inf}[`)
        )
    )
  )
}

/**
 * Higher order component to wrap `ecrForm`'s `Form` component
 * to provide form validation services. Implemented using inheritance
 * inversion HOC technique.
 * This component "hijacks" the `submitHandler` of the wrapped
 * `Form` component and performs form validation before submit.
 * It displays an error display pane if the form contains any error.
 * If the submit type is `draft` then it always allows the user to submit
 * the form whether it has errors or not. If the submit type is `final`
 * it does not allow form submit when the form contains any error.
 * The HOC submits the form by calling the wrapped `Form` component's
 * `submitHandler` function with the original (hijacked) arguments.
 *
 * This HOC uses reduxSpy to access form values in the redux store.
 *
 * @param {string} formName Name of the form
 */
const ValidatorHOC = (
  {
    formName,
    formDescriptor,
  }: { formName: string, formDescriptor: FormDescriptor } = {}
) => {
  return WrappedForm =>
    class extends WrappedForm {
      static contextTypes = {
        formDescriptor: PropTypes.instanceOf(FormDescriptor),
      }

      constructor(props) {
        super(props)
        this.state = {
          validatorResult: null,
          submitType: null,
          forwardCall: null,
          visible: false,
        }
        this.formErrorsPaneSubmitButtonHandler = this.formErrorsPaneSubmitButtonHandler.bind(this)
      }

      /**
       * Override Form's submitHandler function
       *
       * @param {SyntheticEvent} evt
       * @param {string} submitType
       */
      submitHandler(evt: SyntheticEvent<*>, submitType: 'draft' | 'final') {
        if (evt) {
          evt.preventDefault()
        }
        const { formValidator } = this.props
        const values = this.props.spy('values').toJS()
        const validatorResult = formValidator.validate(values)
        const { 
          errors = {},
          warnings = {} 
        } = formValidator.flattenValidatorResult(validatorResult) || {}
        if (validatorResult.hasErrors || validatorResult.hasWarnings) {
          store.dispatch(
            setSyncErrorsAndWarningsAction(
              errors,
              warnings,
              formName
            )
          )
          store.dispatch(formSubmissionFailedAction(formName))
          this.setState({
            validatorResult,
            submitType,
            visible: true,
          })
          return
        }
        super.submitHandler(evt, submitType)
      }

      formErrorsPaneCloseHandler = () => {
        this.setState({
          visible: false,
        })
      }

      formErrorsPaneSubmitButtonHandler() {
        super.submitHandler(null, this.state.submitType)
      }

      render() {
        const { validatorResult, submitType } = this.state
        const { formDescriptor } = this.context
        const { indispensableFields, getLabel, fieldSections, fieldOrders } = formDescriptor
        return (
          <div>
            {super.render()}
            {validatorResult && (
              <FormErrorsPane
                visible={this.state.visible}
                errors={validatorResult.errors}
                warnings={validatorResult.warnings}
                onClose={this.formErrorsPaneCloseHandler}
                // only enable submit button if validation result doesn't contain
                // any errors or we are in draft mode and validation errors doesn't
                // contain indispensable fields
                submitButtonEnabled={
                  !validatorResult.hasErrors ||
                  (submitType === 'draft' &&
                    !validationErrorsContainsIndispensableFields(
                      validatorResult.errors,
                      [...(indispensableFields || []), ...HEADER_FIELDS]
                    ))
                }
                onSubmitButtonClick={this.formErrorsPaneSubmitButtonHandler}
                indispensableFields={[...(indispensableFields || []), ...HEADER_FIELDS]}
                labelTranslator={getLabel}
                fieldSections={fieldSections}
                fieldOrders={fieldOrders}
              />
            )}
          </div>
        )
      }
    }
}

export default ValidatorHOC
