export function addSecondsToDate(seconds, date = new Date()) {
  return new Date((Math.round(new Date().getTime() / 1000) + seconds) * 1000)
}

/**
 * Finds the first element in an array
 * for which `pred` returns true.
 * @param  {Function} pred Predicate function.
 * @param  {Array<X>} lst  Input array.
 * @param  {X}        dflt Default value to return.
 * @return {X}        First array element that satisfies `pred`
 */
export const findFirstBy = (pred, lst, dflt) => {
  const filtered = lst.filter(pred)
  if (filtered.length === 0) {
    return dflt
  }
  return filtered[0]
}
