import { refreshToken, refreshTokenAction } from '../auth'
import {
  addSecondsToDate,
} from '../utils'

/**
 * This module stores access token data and makes it available to
 * any part of the application (typically for api module). It automatically
 * saves token and its metadata in sessionStorage. Loading of token
 * from local storage is initialized by dispatching `loadTokenAction`
 * action creator from main file (src/index.js) which in turn calls
 * the module's `loadToken` function.
 * NOTE: This module is implemented using the `revealing module` pattern.
 * NOTE: Remember that each ES6 module caches it's exports so this module is
 * effectively a singleton. (Every importer gets the same instance.)
 */
export default (function accessTokenModule() {
  var currentToken = null
  var currentTokenExpiresAt = null
  var store = null
  var tokenRefreshTimer = null

  function init(reduxStore) {
    store = reduxStore
  }

  function nextRefreshInSeconds(tokenExpiresAt) {
    const currentDate = new Date()
    const remainingSecs = ~~((tokenExpiresAt.getTime() - currentDate.getTime()) / 1000)
    return  Math.max(~~(remainingSecs / 2), 10)
  }

  async function performTokenRefresh() {
    try {
      const response = await refreshToken(currentToken)
      store.dispatch(refreshTokenAction({
        token: response.access_token,
        expiresAt: addSecondsToDate(response.expires_in)
      }))
    }
    catch(err) {
      if (!err.response || err.response.status !== 400) {
        const scheduleSecs = nextRefreshInSeconds(currentTokenExpiresAt)
        scheduleTokenRefresh(scheduleSecs)
        console.error(`Token refresh failed. Retrying in ${scheduleSecs} seconds.`)
      }
    }

  }

  function scheduleTokenRefresh(timeout) {
    // console.log(`Schedule next token refresh in ${timeout} seconds`)
    clearTimeout(tokenRefreshTimer)
    tokenRefreshTimer = setTimeout(performTokenRefresh, timeout * 1000)
  }

  /**
   * Set the current token and its expiration date.
   * Also saves the token to local storage.
   * @param {String} token     Token value
   * @param {Date}   expiresAt Expiration time of the token
   */
  function setToken(token, expiresAt = null) {
    if (token !== currentToken) {
      currentToken = token
      currentTokenExpiresAt = expiresAt
      sessionStorage.setItem('apiToken', JSON.stringify({
        token,
        expiresAt,
      }))
      if (currentTokenExpiresAt) {
        scheduleTokenRefresh(nextRefreshInSeconds(currentTokenExpiresAt))
      }
    }
  }

  /**
   * Clears the token from memory (getToken will return null),
   * and also removes it from local storage.
   * @return {undefined}
   */
  function clearToken() {
    currentToken = null
    currentTokenExpiresAt = null
    sessionStorage.removeItem('apiToken')
  }

  /**
   * Loads the token from local storage and caches it in the module
   * (getToken() will return the loaded token) and returns it along with it's
   * metadata (expirationAt). If it doesn't find the token then it returns null.
   * @return {Object} An object with `token` and `expiresAt` attributes.
   */
  function loadToken() {
    const apiToken = sessionStorage.getItem('apiToken')
    if (!apiToken) {
      return null
    }
    try {
      const { token, expiresAt } = JSON.parse(apiToken)
      if (token) {
        // currentToken = token
        // currentTokenExpiresAt = new Date(expiresAt)
        return {
          token,
          expiresAt: new Date(expiresAt),
        }
      }
      return null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   * Returns the cached token value or null.
   * @return {String} Token value
   */
  function getToken() {
    return currentToken
  }

  /**
   * Returns the cached token's expiration date or null.
   * @return {Date} Cached token's expiration date
   */
  function getExpiresAt() {
    return currentTokenExpiresAt
  }

  // modul's public interface
  // More info: https://addyosmani.com/resources/essentialjsdesignpatterns/book/#revealingmodulepatternjavascript
  return {
    clearToken,
    getToken,
    getExpiresAt,
    init,
    loadToken,
    setToken,
  }

})()
