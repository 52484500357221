// @flow

export type DeferredQueueWorker = (queue: any[]) => void
export type DeferredQueueOptions = {
  idleTime: number,
  absoluteTime: number,
}

/**
 * `DeferredQueue` constructor returns a deferred queue object.
 * Work items can be enqueued by calling the deferred queue object's
 * `enqueue` method. When `options.idleTime` elapses without a 
 * work item being added to the queue, then it calls the provided
 * worker function and passes the whole queue to it. The worker
 * function is always called after `options.absoluteTime` milliseconds
 * elapsed whether `options.idleTime` allows it or not.
 * @param {Function} worker 
 * @param {Object} options
 */
export default function DeferredQueue(worker: DeferredQueueWorker, {
  idleTime = 20,
  absoluteTime = 1000,
}: DeferredQueueOptions = {}) {
  var queue = []
  var lastEnqueueTimestamp = Infinity
  var firstEnqueue = Infinity
  var timer = null
  var context = {}
  
  function callWorker() {
    const now = Date.now()
    if (lastEnqueueTimestamp + idleTime < now
        || firstEnqueue + absoluteTime < now) {
      firstEnqueue = Infinity
      worker.call(context, queue)
      queue = []
      timer = null
    } else {
      timer = setTimeout(callWorker, idleTime)
    }
  }
    
  return {
    
    enqueue(item: any) {
      queue.push(item)
      if (!timer) {
        timer = setTimeout(callWorker, idleTime)
      }
      const now = Date.now()
      if (queue.length === 1) {
        firstEnqueue = now
      }
      lastEnqueueTimestamp = now
    },

    setContext(_context: ?Object = {}) {
      context = Object.assign({}, _context)
    },

    mergeContext(_context: ?Object = {}) {
      context = Object.assign(context || {}, _context)
    }
  }
}




















