import { axiosInstance } from '../api'

export async function postSetupMfaToken(accessToken) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-tokens/setup', {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
  return resp?.data
}

export async function postVerifyMfaToken(accessToken, verifyToken) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-tokens/verify', {
    otp_token: verifyToken
  }, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
  return resp?.data
}

export async function postMfaTokensSkipSetup(accessToken) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-tokens/skip-setup', null, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
  return resp?.data
}