//@flow
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import * as Immutable from 'immutable'
import { createSelectorCreator, defaultMemoize } from 'reselect'

import { fieldPathToImmutablePath } from '../utils'
import { createPredicate, shouldRenderChildren } from './utils'
import { updateDependencyTreeRenderTypeAction } from './actions'
import type { MyFormType } from './types'

const Root = styled.div`
  padding: 5px 20px 5px 30px;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 5px solid transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
`

const Label = styled.div`
  font-style: italic;
  padding: 10px 0 15px 0;
  color: #555;
`

type Props = {
  _myForm: MyFormType,
  children: Node,
  label?: string,
  name?: string,
  refField?: string,
  refValue?: string,
  predicateParameters?: string[],
  predicate?: string,
  triggerFieldValue?: string,
  allFieldValues: Immutable.Map<*, *>,
  updateDependencyTreeRenderTypeAction: (
    fieldName: string[],
    formName: string
  ) => void,
}

/**
 * ConnectedDependentFields renders its children only if a referenced field
 * has a specific value or values.
 * The `refField` prop should contain the referenced field id in the redux store.
 * The referenced field value is compared to `refValue`.
 * `refValue` can only be used if the referenced value is a primitive value
 * (e.g. string or number) or an array.
 * `refValue` can be a simple value (e.g. refValue="5") or a list of values
 * (e.g. refValue="5,6"). In the latter case there is an "OR" relation between
 * the values so `refValue="5,6"` means `refValue="5" OR refValue="6"`.
 * `refValue` can contain "null" and "undefined" strings which are handled as
 * `null` and `undefined` values respectively.
 * If referenced value is an array then when any of the referenced value's
 * element is matched with any of refValue the component will render its
 * children.
 * @extends React
 */
export class ConnectedDependentFields extends React.Component<Props> {
  predicate: ?Function

  constructor(props: Props) {
    super(props)
    this.setPredicate(props.predicate, props.predicateParameters || [])
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.predicate !== nextProps.predicate) {
      this.setPredicate(nextProps.predicate, nextProps.predicateParameters || [])
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.props.updateDependencyTreeRenderTypeAction(
      this.props.refField ? [this.props.refField] : (this.props.predicateParameters || []),
      this.props._myForm.name
    )
  }

  setPredicate(predicate: ?string, predicateParameters: Array<string>) {
    this.predicate =
      predicate && predicateParameters
        ? createPredicate(predicateParameters, predicate)
        : null // eslint-disable-line no-new-func
  }

  render() {
    const { refField, refValue, allFieldValues, predicateParameters = [] } = this.props
    if (
      shouldRenderChildren(
        allFieldValues,
        refField,
        refValue,
        this.predicate,
        predicateParameters,
      )
    ) {
      const children = React.Children.map(this.props.children, child =>
        React.cloneElement(child, {
          name: this.props.name
            ? `${this.props.name}.${child.props.name}`
            : child.props.name,
        })
      )
      if (this.props.label) {
        return (
          <Root>
            <Label>{this.props.label}</Label>
            {children}
          </Root>
        )
      } else {
        return <div>{children}</div>
      }
    }
    return null
  }
}

/*
  To optimize DependentFields rendering we use `reselect` library. `reselect`
  uses **input selector** functions to decide whether it is necessary to
  recalculate component's subscriptions. Because this application uses an
  Immutable redux store and `reselect`'s input selector functions always
  recalculate we need a specialized `createImmutableSelector` which uses
  `Immutable.is` to compare redux store values.
 */
const createImmutableSelector = createSelectorCreator(
  defaultMemoize,
  Immutable.is
)

/**
 * A `reselect` input selector function which returns a subset of redux store's
 * `values`. The subset only contains keys and values determined by either the
 * `predicateParameters` or `refField` prop of the component.
 * @param  {Immutable.Map} state Redux store
 * @param  {Props}         props Component's props
 * @return {Immutable.Map}       Subset of `values` map which only contains keys
 *                               determined by `predicateParameters` or
 *                               `refField` props
 */
const getReferencedFieldsInputSelector = (state, props) => {
  const parameters = Array.prototype.concat(props.predicateParameters || props.refField).filter(p => typeof p === 'string')
  if (parameters.length === 0) {
    throw new Error(`DependentFields (label: "${props.label}") is missing refField or predicateParameters prop`)
  }
  return parameters
    .reduce((acc, parameter) =>
      acc.setIn(
        [...fieldPathToImmutablePath(parameter)],
        state.getIn(['ecrForm', props._myForm.name, 'values', ...fieldPathToImmutablePath(parameter)])
      )
    , Immutable.Map())
}

/**
 * An "input selector creator" creator. This indirection is necessary to share
 * selectors with props across multiple component instances. For more information
 * please check `reselect` library documentation.
 * @return {() => Function} An immutable selector creator
 */
const makeGetReferencedFields = () => {
  return createImmutableSelector(
    [ getReferencedFieldsInputSelector ],
    (referencedFields) => referencedFields
  )
}

/**
 * A function to create a `mapStateToProps` function for redux. This indirection
 * is necessary to share selectors with props across multiple component
 * instances. For more information please check `reselect` library
 * documentation.
 * @return {Function} Redux's `mapStateToProps` function
 */
const makeMapStateToProps = () => {
  const getReferencedFields = makeGetReferencedFields()
  return (state, props) => ({
    allFieldValues: getReferencedFields(state, props)
  })
}

export default connect(
  makeMapStateToProps,
  {
    updateDependencyTreeRenderTypeAction,
  }
)(ConnectedDependentFields)
