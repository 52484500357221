// @flow
import * as Immutable from 'immutable'
import { toPath } from 'lodash'
import { createPredicate, collectPredicateParameters, evaluatePredicate } from "./predicate"
import { convertMapToJs } from '../../utils'

/**
 *
 * @param {*} predicate
 * @param {*} refValue
 * @param {*} triggerFieldValue
 * @param {*} allFieldValues
 */
export function shouldRenderChildren(
  allFieldValues: Immutable.Map<*, *>,
  refField: ?string,
  refValue: ?string,
  predicate: Function | string | void,
  predicateParameters: string[],
) {
  const triggerFieldValue = convertMapToJs(allFieldValues.getIn(toPath(refField)))
  const predicateFn = typeof predicate === 'function' ? predicate : createPredicate(predicateParameters, predicate)
  if (predicateFn) {
    const parameterValues = collectPredicateParameters(predicateParameters, allFieldValues)
    return evaluatePredicate(predicateFn, parameterValues)
  }
  const refValues = (refValue || '')
    .split(',')
    .map(v => v.trim())
    .map(v => {
      if (v === 'undefined') {
        return undefined
      } else if (v === 'null') {
        return null
      } else {
        return v
      }
    })
  if (Array.isArray(triggerFieldValue)) {
    return triggerFieldValue.some(tfv => refValues.includes(tfv))
  }
  return refValues.includes(triggerFieldValue)
}
