import axios_instance from "./axios_instance";

export async function resetFormApproval(id: string) {
  await axios_instance.get()
    .delete(`/api/v1/forms/${id}/approvals`)
}

export async function resetAttendanceApprovals(id: string) {
  await axios_instance.get()
    .delete(`/api/v1/attendances/${id}/approvals`)
}