// @flow

export type SortAttribute = string
export type SortDirection = 'asc' | 'desc'

/*
  SortCondition represents a single sort condition in query params.
  E.g. `-age` becomes:
  {
    attr: 'age',
    dir  : 'desc',
  }
 */
export default class SortCondition {

  _attr: SortAttribute
  _dir: SortDirection

  constructor(attr: SortAttribute, dir: SortDirection) {
    this._attr = attr
    this._dir = dir
  }

  get attr(): SortAttribute {
    return this._attr
  }

  get dir(): SortDirection {
    return this._dir
  }

  toString() {
    return `${this._dir === 'desc' ? '-' : ''}${this._attr}`
  }

  static serialize(sortConditions: SortCondition[]): string {
    return sortConditions.map(sc => sc.toString()).join(',')
  }
}
