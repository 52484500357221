import axiosInstance from './axios_instance'

export async function deleteImpersonating() {
  const resp = await axiosInstance.get()
    .delete(`/api/v1/impersonation`)
  return resp.data
}

export async function postImpersonatedUserId(id) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/impersonation/${id}`)
  return resp.data
}
  