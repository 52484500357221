// @flow
import axios, {
  Axios,
  AxiosXHRConfigBase,
} from 'axios'
import { merge } from 'lodash'

type PublicInterface = {
  get: () => Axios,
  setToken: (string) => void,
  setUnauthorizedCallback: (Function) => void,
}

export default (function axiosInstanceModule(): PublicInterface {
  var axiosInstance: ?Axios = null
  var axiosConfig: AxiosXHRConfigBase<*> = {}
  var unauthorizedCallback: ?(any) => void = null
  var locale: string
  var token: ?string = null

  const getToken = () => token

  function setToken(tkn: string): void {
    if (tkn !== token) {
      token = tkn
      if (typeof tkn === 'string' && tkn.trim() !== '') {
        mergeConfig({
          headers: {
            'Authorization': `Bearer ${tkn}`
          }
        })
      }
      axiosInstance = createInstance()
    }
  }

  const getLocale = () => locale

  function setLocale(loc: string): void {
    if (loc !== locale) {
      locale = loc
      mergeConfig({
        headers: {
          'Accept-Language': loc,
        }
      })
    }
  }

  function get(): Axios {
    if (axiosInstance) {
      return axiosInstance
    }
    axiosInstance = createInstance()
    return axiosInstance
  }

  function mergeConfig(configUpdate: AxiosXHRConfigBase<*>): void {
    axiosConfig = merge({}, axiosConfig, configUpdate)
    axiosInstance = createInstance()
  }

  function createInstance() {
    const instance = axios.create(axiosConfig)
    // TODO: usage of `any` because of possibly bad flow-typed def for axios
    instance.interceptors.response.use(undefined, function(error: any) {
      if (error.response && error.response.status === 401) {
        if (unauthorizedCallback) {
          unauthorizedCallback(error)
        }
      }
      return Promise.reject(error)
    })
    return instance
  }

  function setUnauthorizedCallback(callback) {
    unauthorizedCallback = callback
  }

  return {
    get,
    getLocale,
    getToken,
    setLocale,
    setToken,
    setUnauthorizedCallback,
  }

})()
