export const FIELD_DISPLAY_MODE_DIRTY = 'dirty'
export const FIELD_DISPLAY_MODE_REJECTED = 'rejected'
export const FIELD_DISPLAY_MODE_CORRECTED = 'corrected'

export const FIELD_RENDER_TYPE_READ = 'read'
export const FIELD_RENDER_TYPE_NEW = 'new'
export const FIELD_RENDER_TYPE_EDIT = 'edit'
export const FIELD_RENDER_TYPE_INSPECT = 'inspect'
export const FIELD_RENDER_TYPE_INSPECT_FIX = 'inspect_fix'
export const FIELD_RENDER_TYPE_INSPECT_REJECT = 'inspect_reject'
export const FIELD_RENDER_TYPE_FIX = 'fix'
export const FIELD_RENDER_TYPE_CORRECT = 'correct'
export const FIELD_RENDER_TYPE_CORRECT_FIX = 'correct_fix'
export const FIELD_RENDER_TYPE_CENSORED = 'censored'

export const FORM_VIEW_TYPE_READ = 'read'
export const FORM_VIEW_TYPE_NEW = 'new'
export const FORM_VIEW_TYPE_EDIT = 'edit'
export const FORM_VIEW_TYPE_INSPECT = 'inspect'
export const FORM_VIEW_TYPE_CORRECT = 'correct'

export const FIELD_TYPE_FIELD = 'field'
export const FIELD_TYPE_COMPLEX_FIELD = 'complex_field'
