// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getRegistryFormTypes(registryId: string, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries/${registryId}/form-types`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getAttendanceStartFormTypes(instituteId: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/form-types/attendance-start/${instituteId}`)
  return resp.data.results
}

export async function getFormType(formTypeId: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/form-types/${formTypeId}`)
  return resp.data.results[0]
}

