// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function createInstitutePatient(instituteId: string, payload: Object) {
  const resp = await axiosInstance.get()
    .post(`/api/v1/institutes/${instituteId}/patients`, payload)
  return resp.data.results[0]
}

export async function getInstitutePatients(instituteId: string, options: CommonCollectionQueryParameters) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/patients`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function getInstitutePatient(instituteId: string, patientId: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/institutes/${instituteId}/patients/${patientId}`)
  return resp.data.results[0]
}

export async function getPatient(patientId: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/patients/${patientId}`)
  return resp.data.results[0]
}

export async function updatePatientData(id: string, payload: Object, options: Object) {
  const resp = await axiosInstance.get().put(`/api/v1/patient/${id}`, payload,{params: options})
  return resp.data.results[0]
}

export async function getPatients(options: CommonCollectionQueryParameters) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/patients`,{
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}

export async function deletePatient(id: string) {
  await axiosInstance.get()
    .delete(`/api/v1/patient/${id}`)
}
