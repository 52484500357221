import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import App from './App'
import { getLocaleResources } from '../../i18n'

class AppIntlWrapper extends React.Component {
  render () {
    const { messages } = getLocaleResources(this.props.locale)
    return (
      <IntlProvider
        textComponent="span"
        key={this.props.locale}
        locale={this.props.locale}
        messages={messages}
        >
        <App 
          locale={this.props.locale}
        />
        <div id="modal-root"></div>
      </IntlProvider>
    )
  }
}

export default connect(state => ({
  locale: state.getIn(['app', 'locale'], 'en')
}))(AppIntlWrapper)
