// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

import type { CommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getFormAcl(formId: string, options: CommonCollectionQueryParameters = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/form-acl/${formId}`, {
      params: sanitizeCommonCollectionQueryParameters(options)
    })
  return resp.data
}
