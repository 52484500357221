// @flow
import * as Immutable from 'immutable'
import FormDescriptor from '../../services/generator/form_descriptor'
import { calculateCalculatedFieldValue, shouldRenderChildren } from './utils'

import type {
  FormDescriptorJsonNode,
  FormDescriptorJsonFieldNode,
} from '../../services/generator/form_descriptor'

type FormValues = Immutable.Map<*, *>

/**
 * Sweepers' main function
 * It walks over the entire form descriptor and
 * - recalculates CalculatedFields
 * - removes fields remaining after dependent fields children that was not
 *   able to remove itself because they were not rendered on the screen at
 *   the momment of the dependee changed its value
 * @param  {FormDescriptor} formDescriptor Form descriptor helper object
 * @param  {FormValues}     formValues     Form values from redux store (values attribute)
 * @return {FormValues}     New redux store `values` Immutable.Map
 */
export function formValuesSweeper(
  formDescriptor: FormDescriptor,
  formValues: FormValues
): FormValues {
  if (formDescriptor.form) {
    return walkFormDescriptor(formDescriptor.form, formValues)
  }
  console.error('form_values_sweeper: FormDescriptor missing form JSON. Skipping sweeping.')
  return formValues
}

function walkFormDescriptor(
  nodes: FormDescriptorJsonNode[],
  values: FormValues
): FormValues {
  return nodes.reduce(
    (acc, child) => processFormDescriptorChild(child, acc),
    values
  )
}

function processFormDescriptorChild(
  child: FormDescriptorJsonNode,
  values: FormValues
): FormValues {
  switch (child.type) {
    case 'dependentFields':
      const { predicate, predicateParameters, refField, refValue } = child || {}
      if (
        shouldRenderChildren(
          values,
          refField,
          refValue,
          predicate,
          predicateParameters
        )
      ) {
        return walkFormDescriptor(child.children, values)
      }
      return deleteDependentChildren(child.children, values)
    case 'field':
      if (child.component === 'CalculatedNumberField') {
        return checkCalculated(child, values)
      }
      return values
    case 'complexField':
    case 'section':
      return walkFormDescriptor(child.children, values)
    default:
      throw new Error('Unknown fieldtype')
  }
}

function deleteDependentChildren(
  children: FormDescriptorJsonNode[],
  values: FormValues
) {
  return children.reduce(
    (acc, child) => processDependentChild(child, acc),
    values
  )
}

function processDependentChild(
  child: FormDescriptorJsonNode,
  values: FormValues
): FormValues {
  switch (child.type) {
    case 'dependentFields':
    case 'section':
      return deleteDependentChildren(child.children, values)
    case 'field':
    case 'complexField':
      return values.delete(child.id)

    default:
      throw new Error('Unknown fieldtype')
  }
}

function checkCalculated(
  child: FormDescriptorJsonFieldNode,
  values: Immutable.Map<*, *>
): FormValues {
  const { doNotCheckParameters, formula, formulaParameters } = child.props || {}
  const value = calculateCalculatedFieldValue(
    formula,
    formulaParameters,
    values,
    doNotCheckParameters
  )
  return values.set(child.id, value)
}
