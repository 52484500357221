// @flow
import axiosInstance from './axios_instance'
import { sanitizeCommonCollectionQueryParameters } from '../../lib/common_query_parameters'

export async function getAttendancePeriodsForAttendance(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/attendances/${id}/attendance_periods`)
  return resp.data.results
}

export async function getRegistryAttendancePeriods(registryId: string, { offset, limit, filter, sort } = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/registries/${registryId}/attendance_periods`, {
      params: sanitizeCommonCollectionQueryParameters({ offset, limit, filter, sort }),
    })
  return resp.data
}

export async function getPatientAttendancePeriods(patientId: string, { offset, limit, filter, sort } = {}) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/patients/${patientId}/attendance_periods`, {
      params: sanitizeCommonCollectionQueryParameters({ offset, limit, filter, sort }),
    })
  return resp.data
}

export async function getAttendancePeriod(id: string) {
  const resp = await axiosInstance.get()
    .get(`/api/v1/attendance-periods/${id}`)
  return resp.data.results[0]
}
