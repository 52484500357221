// @flow
/* eslint getter-return: off */
import _ from 'lodash'
import * as Immutable from 'immutable'

import { getFormDescriptor } from '../../services/api'

export type FormDescriptorJsonSectionNode = {
  type: 'section',
  title: string,
  serial: number,
  children: FormDescriptorJsonNode[], // eslint-disable-line no-use-before-define
}

export type FormDescriptorJsonFieldNode = {
  type: 'field',
  id: string,
  label: string,
  props?: {
    [string]: any,
  },
  noData: boolean,
  component: string,
}

export type FormDescriptorJsonDependentFieldsNode = {
  type: 'dependentFields',
  label: string,
  children: FormDescriptorJsonNode[], // eslint-disable-line no-use-before-define
  refField?: string,
  refValue?: any,
  predicate?: string,
  predicateParameters?: string[],
}

export type FormDescriptorJsonComplexFieldNode = {
  type: 'complexField',
  id: string,
  label: string,
  children: FormDescriptorJsonNode[], // eslint-disable-line no-use-before-define
}

export type FormDescriptorJsonNode =
  | FormDescriptorJsonSectionNode
  | FormDescriptorJsonFieldNode
  | FormDescriptorJsonDependentFieldsNode
  | FormDescriptorJsonComplexFieldNode

export type FormDescriptorJsonLabels = {
  [string]: string,
}
export type FormDescriptorJsonSections = string[]
export type FormDescriptorJsonFieldOrders = {
  [string]: number,
}
export type FormDescriptorJsonFieldSections = {
  [string]: number,
}
export type FormDescriptorJsonValueLabelMap = {
  [string]: {
    [string]: string
  }
}
export type FormDescriptorJsonCommonDataFields = string[]
export type FormDescriptorJsonPersonalDataFields = {
  [string]: 'preloadable' | 'not-preloadable'
}
export type FormDescriptorJsonIndispensableFields = string[]

export type FormDescriptorJson = {
  form: FormDescriptorJsonNode[],
  labels: FormDescriptorJsonLabels,
  sections: FormDescriptorJsonSections,
  fieldOrders: FormDescriptorJsonFieldOrders,
  fieldSections: FormDescriptorJsonFieldSections,
  valueLabelMap: FormDescriptorJsonValueLabelMap,
  commonDataFields: FormDescriptorJsonCommonDataFields,
  personalDataFields: FormDescriptorJsonPersonalDataFields,
  indispensableFields: FormDescriptorJsonIndispensableFields,
}

export type FormBehaviorType = 'attendance_start' | 'inpatient_day' | 'any'

type FormDescriptorResult = {
  formTypeId: string,
  version: number,
  formDescriptor: FormDescriptorJson,
  locale: string,
  displayId: string,
  behaviorType: FormBehaviorType
}

function getBuiltinLabels(locale: string) {
  switch (locale) {
    case 'hu':
      return {
        _header: '0. szekció',
        patient: 'Beteg',
        physicianId: 'Kezelőorvos',
      }
    default:
      return {
        _header: '0. section',
        patient: 'Patient',
        physicianId: 'Physician',
      }
  }
}

/**
 * When the provided field is a path it returns
 * the last component of it which is the id of the field.
 * Otherwise it returns field parameter unchanged.
 * @param  {String} field Field name or path (e.g. "medication[2].drugName")
 * @return {String}       Field id (e.g. "drugName")
 */
function getFieldId(field = '') {
  return field.split('.').slice(-1)[0]
}

class FormDescriptor {
  _fd: FormDescriptorJson
  _formTypeId: string
  _formTypeVersion: number
  _locale: string
  _displayId: string
  _behaviorType: FormBehaviorType
  _validatorDisplayId: ?string
  _validatorVersion: number

  get raw(): FormDescriptorJson {
    return this._fd
  }

  get formTypeId(): string {
    return this._formTypeId
  }

  get formTypeVersion(): number {
    return this._formTypeVersion
  }

  get locale(): string {
    return this._locale
  }

  get displayId(): string {
    return this._displayId
  }

  get behaviorType(): FormBehaviorType {
    return this._behaviorType
  }

  get validatorDisplayId(): string | void {
    return this._validatorDisplayId
  }

  get validatorVersion(): number | void {
    return this._validatorVersion
  }

  get sections(): ?FormDescriptorJsonSections {
    if (this._fd) {
      return this._fd.sections
    }
  }

  get commonDataFields(): ?FormDescriptorJsonCommonDataFields {
    if (this._fd) {
      return this._fd.commonDataFields
    }
  }

  get form(): ?FormDescriptorJsonNode[] {
    if (this._fd) {
      return this._fd.form
    }
  }

  get labels(): ?FormDescriptorJsonLabels {
    if (this._fd) {
      return this._fd.labels
    }
  }

  get valueLabelMap(): ?FormDescriptorJsonValueLabelMap {
    if (this._fd) {
      return this._fd.valueLabelMap
    }
  }

  get indispensableFields(): ?FormDescriptorJsonIndispensableFields {
    if (this._fd) {
      return this._fd.indispensableFields
    }
  }

  get personalDataFields(): ?FormDescriptorJsonPersonalDataFields {
    if (this._fd) {
      return this._fd.personalDataFields
    }
  }

  get fieldSections(): ?FormDescriptorJsonFieldSections {
    if (this._fd) {
      return this._fd.fieldSections
    }
  }

  get fieldOrders(): ?FormDescriptorJsonFieldOrders {
    if (this._fd) {
      return this._fd.fieldOrders
    }
  }

  getFieldSection(fieldId: string): ?number {
    if (this._fd && this._fd.fieldSections) {
      return this._fd.fieldSections[fieldId]
    }
  }

  getFieldOrder(fieldId: string): ?number {
    if (this._fd && this._fd.fieldOrders) {
      return this._fd.fieldOrders[fieldId]
    }
  }

  async load(
    formTypeId: string,
    formTypeVersion: number = 1,
    locale: string = 'en'
  ) {
    const fdr = await getFormDescriptor(formTypeId, formTypeVersion, locale)
    return this.setup(fdr)
  }

  setup(fdr: FormDescriptorResult) {
    this._fd = {
      ...fdr.formDescriptor,
      labels: {
        ...fdr.formDescriptor.labels,
        ...getBuiltinLabels(fdr.locale),
      },
    }
    this._formTypeId = fdr.formTypeId
    this._formTypeVersion = fdr.version
    this._locale = fdr.locale
    this._displayId = fdr.displayId
    this._behaviorType = fdr.behaviorType
    this._validatorDisplayId = fdr.formDescriptor.validatorDisplayId
    this._validatorVersion = fdr.formDescriptor.validatorVersion
    return this
  }

  valueToLabel = (field: string, value: any): string => {
    if (this._fd) {
      return _.get(this._fd, ['valueLabelMap', getFieldId(field), value], value)
    }
    return value
  }

  getLabel = (field: string, defaultValue: any): string => {
    if (this._fd) {
      return _.get(this._fd, ['labels', getFieldId(field)], defaultValue)
    }
    return defaultValue
  }

  _transformValue = (field: string, val: any): any => {
    if (Array.isArray(val)) {
      return val.map(v => this._transformValue(field, v))
    }
    if (val === Object(val)) {
      return Object.getOwnPropertyNames(val).reduce((acc, fieldId) => {
        const label = _.get(this._fd, ['labels', getFieldId(fieldId)], fieldId)
        acc[label] = this._transformValue(fieldId, val[fieldId])
        return acc
      }, {})
    }
    return _.get(this._fd, ['valueLabelMap', getFieldId(field), val], val)
  }

  transformValue = (field: string, value: any) => {
    if (this._fd) {
      return this._transformValue(
        field,
        Immutable.Iterable.isIterable(value) ? value.toJS() : value
      )
    }
    return value
  }
}

export default FormDescriptor
