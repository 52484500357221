import qs from 'qs' 
import { axiosInstance } from '../api'

export async function requestAccessToken(username, password) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/tokens', qs.stringify({
    grant_type: 'password',
    username,
    password,
  }))
  return resp.data
}

export async function postOtpToken(otpToken, accessToken) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-tokens', qs.stringify({
    otp_token: otpToken,
  }), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
  return resp.data
}

export async function postMfaRecoveryKey(recoveryKey, accessToken) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/mfa-recovery', qs.stringify({
    recovery_key: recoveryKey,
  }), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
  return resp.data
}

export async function refreshToken(token) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/refresh', qs.stringify({
    token,
  }))
  return resp.data
}

export async function requestPasswordReset(payload) {
  const axios = axiosInstance.get()
  const resp = await axios.post(
    '/api/v1/auth/request-password-reset',
    payload,
    {
      headers: {
        'Accept-Language': axiosInstance.getLocale(),
      }
    }
  )
  return resp.data.results
}

export async function resetPassword(payload) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/reset-password', payload)
  return resp.data.results
}

export async function changePassword(payload) {
  const axios = axiosInstance.get()
  const resp = await axios.post('/api/v1/auth/change-password', payload)
  return resp.data.results
}
