import React from 'react'
import Spinner from 'react-spinkit'
import styled from 'styled-components/macro'

const SpinnerContainer = styled.div`
  width: 100vw;
  height: ${props => props.fullHeight ? '100vh' : 'calc(100vh - 72px)'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const FullPageSpinner = ({ fullHeight = false }) => {
  return (
    <SpinnerContainer fullHeight={fullHeight}>
      <Spinner name="wandering-cubes" />
    </SpinnerContainer>
  )
}

export default FullPageSpinner
