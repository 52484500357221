//@flow
import React from 'react'
import PropTypes from 'prop-types'
import ConnectedFormSubmit from './ConnectedFormSubmit'

type Props = {

}

class FormSubmit extends React.Component<Props> {

  static contextTypes = {
    _myForm: PropTypes.object,
  }

  render () {
    return React.createElement(ConnectedFormSubmit, {
      ...this.props,
      _myForm: this.context._myForm,
    })
  }
}

export default FormSubmit