//@flow
import React from 'react'
import { connect } from 'react-redux'
import { 
  submitAction, 
  setSubmitAttemptedAction,
  closeAllConfirmationModalAction,
} from './actions'

type Props = {
  component: React$ElementType,
  remoteForm: string,
  submitType: string,
  setSubmitAttemptedAction: (formName: string) => void,
  closeAllConfirmationModalAction: (formName: string) => void,
  submitAction: (submitType: string, formName: string) => void,
  _myForm: Object,
  submitInProgress: boolean,
}

class ConnectedFormSubmit extends React.Component<Props> {

  static defaultProps = {
    submitType: 'submit',
  }

  componentClickHandler = (evt: SyntheticEvent<*>) => {
    const {
      remoteForm,
      _myForm,
      setSubmitAttemptedAction,
      closeAllConfirmationModalAction,
      submitAction,
      submitType,
      submitInProgress,
    } = this.props
    const formName = remoteForm || _myForm.name
    if (submitInProgress) return
    setSubmitAttemptedAction(formName)
    closeAllConfirmationModalAction(formName)
      submitAction(submitType, formName)
  }

  render() {
    const { component, submitInProgress } = this.props
    return React.cloneElement(component, {
      disabled: submitInProgress,
      onClick: this.componentClickHandler,
    })
  }
}

export default connect(
  (state, ownProps) => ({
    submitInProgress: state.getIn(['ecrForm', ownProps._myForm.name, 'submitInProgress']) || false
  }),
  {
    setSubmitAttemptedAction,
    submitAction,
    closeAllConfirmationModalAction,
  }
)(ConnectedFormSubmit)
