import localforage from 'localforage'

const preferencesCache = Symbol()
const storage = Symbol()
const loggedInUser = Symbol()

const STORENAME = 'preferences'
const KEY = 'userPreferences'

const defaultPreferences = {
  perPage: 10,
  locale: 'hu',
}

export default class UserPreferences {
  constructor() {
    this[preferencesCache] = null
    this[storage] = null
    this[loggedInUser] = null
  }

  static getPreference(key, defaultValue) {
    if (
      this[preferencesCache] &&
      this[preferencesCache][this[loggedInUser]] &&
      this[preferencesCache][this[loggedInUser]].hasOwnProperty(key)
    ) {
      return this[preferencesCache][this[loggedInUser]][key]
    }
    return defaultValue
  }

  static setPreference(key, value) {
    if (!this[preferencesCache]) {
      throw Error('Not initialized...')
    }
    this[preferencesCache] = {
      ...this[preferencesCache],
      [this[loggedInUser]]: {
        ...this[preferencesCache][this[loggedInUser]],
        [key]: value,
      },
    }
    if (this[storage]) {
      this[storage].setItem(KEY, this[preferencesCache])
    }
    return this[preferencesCache]
  }

  static setUser(userId: srting) {
    this[loggedInUser] = userId
    this[preferencesCache] = {
      ...this[preferencesCache],
      [userId]: this[preferencesCache][userId]
        ? { ...this[preferencesCache][userId] }
        : { ...defaultPreferences },
    }
    if (this[storage]) {
      this[storage].setItem(KEY, this[preferencesCache])
    }
  }

  static clearUser() {
    this[loggedInUser] = null
  }

  static async init() {
    try {
      this[storage] = localforage.createInstance({
        driver: localforage.LOCALSTORAGE,
        name: STORENAME,
      })
      await this[storage].ready()
      const storedPreferences = (await this[storage].getItem(KEY)) || {}
      this[preferencesCache] = storedPreferences
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
