// @flow
import { List, Map } from 'immutable'
import { toPath } from 'lodash'
import { storePath } from './store_path'

const arrayPattern = /\[(\d+)\]/

const undefinedArrayMerge = (previous, next) =>
  next !== undefined
    ? next
    : previous

const mergeLists = (original, value) =>
  original && List.isList(original)
    ? original.mergeDeepWith(undefinedArrayMerge, value)
    : value

/*
 * ImmutableJS' setIn function doesn't support array (List) creation
 * so we must pre-insert all arrays in the path ahead of time.
 *
 * Additionally we must also pre-set a dummy Map at the location
 * of an array index if there's parts that come afterwards because
 * the setIn function uses `{}` to mark an unset value instead of
 * undefined (which is the case for list / arrays).
 */
export function setIn(state: Map<*, *>, field: string | Array<string>, value: any, form: string, root: ?string) {
  const path = storePath(toPath(field), form, root)
  if (!field || typeof field !== 'string' || !arrayPattern.test(field)) {
    return state.setIn(path, value)
  }

  return state.withMutations(mutable => {
    for (let pathIndex = 0; pathIndex < path.length - 1; ++pathIndex) {
      const nextPart = path[pathIndex + 1]
      if (isNaN(nextPart)) {
        continue
      }
      const arr = []
      arr[nextPart] = new Map()
      mutable = mutable.updateIn(
        path.slice(0, pathIndex + 1),
        value => mergeLists(value, new List(arr)))
    }
    return mutable.setIn(path, value)
  })
}

export function getIn(state: Map<*, *>, field: string | Array<string>, form: string, root: ?string) {
  return state.getIn(storePath(toPath(field), form, root))
}

export function deleteIn(state: Map<*, *>, field: string | Array<string>, form: string, root: ?string) {
  return state.deleteIn(storePath(toPath(field), form, root))
}

export function splice(list: List<*>, index: number, removeNum: number, value: any): List<*> {
  list = List.isList(list) ? list : List()

  if (index < list.count()) {
    if (value === undefined && !removeNum) { // inserting undefined
      // first insert null and then re-set it to undefined
      return list.splice(index, 0, null).set(index, undefined)
    }
    if (value != null) {
      return list.splice(index, removeNum, value)  // removing and adding
    } else {
      return list.splice(index, removeNum)  // removing
    }
  }
  if (removeNum) { // trying to remove non-existant item: return original array
    return list
  }
  // trying to add outside of range: just set value
  return list.set(index, value)
}
